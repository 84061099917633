import { ReactElement, useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { http } from 'api/client';
import { deserialize, parseDateTime } from 'api/deserialize';

import Loading from 'components/Loading';

const QUERY_KEY = 'currentUser';

export default function CurrentUserProvider({
  children,
}: {
  children: ReactElement;
}) {
  // Fetch on AuthenticatedApp mount
  // Refetch on window focus after 5 minutes of stale data
  const { isLoading, data: user } = useQuery(
    QUERY_KEY,
    () => fetchCurrentUser(),
    {
      refetchOnWindowFocus: true,
      staleTime: 5 * 60 * 1_000,
      onError: () => {},
    }
  );

  if (isLoading || !user) {
    return <Loading />;
  }

  return children;
}

export function useCurrentUser() {
  // Refetch on hook mount after 30 minutes of stale data
  const { data: user } = useQuery(QUERY_KEY, () => fetchCurrentUser(), {
    staleTime: 30 * 60 * 1_000,
  });

  if (user === undefined) {
    throw new Error(
      'The hook `useCurrentUser` must be used within an `CurrentUserProvider`.'
    );
  }

  return user;
}

export function useRefetchCurrentUser() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(QUERY_KEY);
  }, [queryClient]);
}

export function useResetCurrentUser() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.removeQueries(QUERY_KEY);
  }, [queryClient]);
}

async function fetchCurrentUser() {
  const { data } = await http.get<{ user: UserT }>('current_user');

  return deserialize(data.user, {
    'updatedAt': parseDateTime,
    'createdAt': parseDateTime,
    'identifiers[].updatedAt': parseDateTime,
  });
}
