import { fromJS, Set } from 'immutable';

import {
  CANCEL_AMBULATORY_PRESCRIPTION,
  CLEAR_AMBULATORY_PRESCRIPTION_DRAFT_ITEMS,
  COPY_AMBULATORY_PRESCRIPTION,
  CREATE_AMBULATORY_PRESCRIPTION,
  CREATE_AMBULATORY_PRESCRIPTION_ITEM,
  DELETE_AMBULATORY_PRESCRIPTION,
  DELETE_AMBULATORY_PRESCRIPTION_ITEM,
  GET_AMBULATORY_PRESCRIPTION,
  GET_AMBULATORY_PRESCRIPTIONS,
  GET_PRESCRIPTION_STATUSES_CHECKED,
  REGISTER_AMBULATORY_PRESCRIPTION,
  START_CHECK_PRESCRIPTION_STATUSES,
  UPDATE_AMBULATORY_PRESCRIPTION,
  UPDATE_AMBULATORY_PRESCRIPTION_ITEM,
} from 'store/modules/entities/actions/medication/ambulatoryPrescriptions';

import deindex from 'utils/deindex';
import sortInline from 'utils/sort';

import { DRAFT_ID_PREFIX } from 'views/record/patients/patient/medication/ambulatory/utils';

const BY_ID = ['ambulatoryPrescriptions', 'byId'];
const BY_PATIENT_ID = ['ambulatoryPrescriptions', 'byPatientId'];

const ITEMS = ['ambulatoryPrescriptionItems'];
const ITEMS_BY_ID = [...ITEMS, 'byId'];
const ITEMS_BY_PRESCRIPTION_ID = [...ITEMS, 'byPrescriptionId'];

const DRAFT_ITEMS = ['ambulatoryPrescriptionDraftItems'];

const mergePrescription = (state: any, action: any) => {
  const {
    response: { entities, result },
    patientId,
  } = action.payload;
  const { ambulatoryPrescriptions, ambulatoryPrescriptionItems } = entities;
  const { 'medications/homeMedicationOrder': prescriptionId } = result;

  const prescriptionItemIds = Object.keys(ambulatoryPrescriptionItems);
  const byPrescriptionIdPath = [
    ...ITEMS_BY_PRESCRIPTION_ID,
    patientId,
    prescriptionId,
  ];

  return state
    .mergeIn(BY_ID, fromJS(ambulatoryPrescriptions))
    .setIn(
      byPrescriptionIdPath,
      state.getIn(byPrescriptionIdPath)?.concat(Set(prescriptionItemIds)) ||
        Set(prescriptionItemIds)
    )
    .mergeDeepIn(ITEMS_BY_ID, fromJS(ambulatoryPrescriptionItems));
};

const mergePrescriptionItems = (
  state: any,
  prescriptionId: string | undefined,
  prescriptionItems: any,
  patientId: string
) => {
  let nextState = state;

  if (prescriptionId) {
    const prescriptionItemIds = Object.keys(prescriptionItems);
    const byPrescriptionIdPath = [
      ...ITEMS_BY_PRESCRIPTION_ID,
      patientId,
      prescriptionId,
    ];
    nextState = nextState.setIn(
      byPrescriptionIdPath,
      state.getIn(byPrescriptionIdPath)?.concat(Set(prescriptionItemIds)) ||
        Set(prescriptionItemIds)
    );
  }

  const itemsPath = prescriptionId ? ITEMS_BY_ID : [...DRAFT_ITEMS, patientId];
  nextState = nextState.mergeDeepIn(itemsPath, fromJS(prescriptionItems));

  return nextState;
};

export default function ambulatoryPrescriptionsReducer(
  state: any,
  action: any
) {
  switch (action.type) {
    case GET_AMBULATORY_PRESCRIPTIONS.SUCCESS: {
      const {
        response: {
          entities,
          result: { data: prescriptionIds },
        },
        patientId,
      } = action.payload;

      const { ambulatoryPrescriptions = {} } = entities;

      return state
        .mergeIn(BY_ID, fromJS(ambulatoryPrescriptions))
        .setIn([...BY_PATIENT_ID, patientId], Set(prescriptionIds));
    }

    case GET_AMBULATORY_PRESCRIPTION.SUCCESS: {
      const { response, patientId } = action.payload;
      const { entities, result } = response;
      const { ambulatoryPrescriptions = {}, ambulatoryPrescriptionItems = {} } =
        entities;
      const { 'medications/homeMedicationOrder': prescriptionId } = result;

      let nextState = state;

      nextState = mergePrescriptionItems(
        state,
        prescriptionId,
        ambulatoryPrescriptionItems,
        patientId
      );

      return nextState
        .mergeDeepIn(BY_ID, fromJS(ambulatoryPrescriptions))
        .setIn(
          [...BY_PATIENT_ID, patientId],
          Set(nextState.getIn([...BY_PATIENT_ID, patientId]) || []).add(
            prescriptionId
          )
        );
    }

    case CREATE_AMBULATORY_PRESCRIPTION_ITEM: {
      const { prescriptionId, prescriptionItem, patientId } = action.payload;

      const ambulatoryPrescriptionItems = {
        [prescriptionItem.id]: prescriptionItem,
      };

      let nextState = state;

      nextState = mergePrescriptionItems(
        nextState,
        undefined,
        ambulatoryPrescriptionItems,
        patientId
      );

      if (prescriptionId) {
        const byIdPath = [...BY_ID, prescriptionId];
        nextState = nextState.mergeIn(byIdPath, fromJS({ isDirty: true }));
      }

      return nextState;
    }

    case UPDATE_AMBULATORY_PRESCRIPTION_ITEM: {
      const { prescriptionId, partialPrescriptionItem, patientId } =
        action.payload;
      const prescriptionItemId = partialPrescriptionItem.id;
      const isDraft = prescriptionItemId.startsWith(DRAFT_ID_PREFIX);

      const prescriptionItem = {
        ...state
          .getIn(
            !isDraft
              ? [...ITEMS_BY_ID, prescriptionItemId]
              : [...DRAFT_ITEMS, patientId, prescriptionItemId]
          )
          .toJS(),
        ...partialPrescriptionItem,
      };

      let nextState = state;

      nextState = nextState.mergeDeepIn(
        !isDraft
          ? [...ITEMS_BY_ID, prescriptionItemId]
          : [...DRAFT_ITEMS, patientId, prescriptionItemId],
        prescriptionItem
      );

      if (prescriptionId) {
        const byIdPath = [...BY_ID, prescriptionId];
        nextState = nextState.mergeDeepIn(byIdPath, fromJS({ isDirty: true }));
      }

      return nextState;
    }

    case DELETE_AMBULATORY_PRESCRIPTION_ITEM: {
      const { prescriptionId, prescriptionItemId, patientId } = action.payload;
      const isDraft = prescriptionItemId.startsWith(DRAFT_ID_PREFIX);

      let nextState = state;

      if (!isDraft) {
        const byPrescriptionIdPath = [
          ...ITEMS_BY_PRESCRIPTION_ID,
          patientId,
          prescriptionId,
        ];

        nextState = nextState
          .removeIn([...ITEMS_BY_ID, prescriptionItemId])
          .updateIn(byPrescriptionIdPath, (set = Set()) =>
            set.filter((id) => id !== prescriptionItemId)
          );
      } else {
        nextState = nextState.removeIn([
          ...DRAFT_ITEMS,
          patientId,
          prescriptionItemId,
        ]);
      }

      if (prescriptionId) {
        const byIdPath = [...BY_ID, prescriptionId];
        nextState = nextState.mergeDeepIn(byIdPath, fromJS({ isDirty: true }));
      }

      return nextState;
    }

    case CLEAR_AMBULATORY_PRESCRIPTION_DRAFT_ITEMS: {
      const { patientId } = action.payload;

      return state.setIn([...DRAFT_ITEMS, patientId], fromJS({}));
    }

    case COPY_AMBULATORY_PRESCRIPTION.SUCCESS: {
      const {
        response: { entities, result },
        patientId,
      } = action.payload;
      const { ambulatoryPrescriptions } = entities;
      const prescriptionId = result['medications/homeMedicationOrder'];

      return state
        .updateIn([...BY_PATIENT_ID, patientId], (set) =>
          set.add(prescriptionId)
        )
        .mergeDeepIn(BY_ID, fromJS(ambulatoryPrescriptions));
    }

    case CREATE_AMBULATORY_PRESCRIPTION.SUCCESS:
    case UPDATE_AMBULATORY_PRESCRIPTION.SUCCESS:
    case REGISTER_AMBULATORY_PRESCRIPTION.SUCCESS:
    case CANCEL_AMBULATORY_PRESCRIPTION.SUCCESS: {
      return mergePrescription(state, action);
    }

    case DELETE_AMBULATORY_PRESCRIPTION.SUCCESS: {
      const { patientId, prescriptionId } = action.payload;

      return state
        .deleteIn([...BY_ID, prescriptionId])
        .updateIn([...BY_PATIENT_ID, patientId], (set) =>
          set.filter((id) => id !== prescriptionId)
        );
    }

    case START_CHECK_PRESCRIPTION_STATUSES.SUCCESS:
    case GET_PRESCRIPTION_STATUSES_CHECKED.SUCCESS: {
      return state;
    }

    default: {
      return state;
    }
  }
}

export const ambulatoryPrescriptionSelector = (
  state: any,
  prescriptionId: string | undefined
): AmbulatoryPrescriptionT | undefined => {
  const {
    ambulatoryPrescriptions: { byId },
  } = state.entities.toJS();

  if (!byId) return undefined;

  const prescription = prescriptionId ? byId[prescriptionId] : undefined;

  return prescription;
};

export const ambulatoryPrescriptionsSelector = (
  state: any,
  patientId: string
): AmbulatoryPrescriptionT[] | undefined => {
  const { byId, byPatientId } = state.entities
    .getIn(['ambulatoryPrescriptions'])
    .toJS();

  const prescriptionIds: string[] | undefined = byPatientId[patientId];

  return prescriptionIds
    ?.map((id) => byId[id])
    .filter(Boolean)
    .sort(sortInline('updatedAt', 'desc'));
};

export const ambulatoryPrescriptionsByStatusSelector = (
  state: any,
  patientId: string,
  targetStatus: AmbulatoryPrescriptionStatusT
) => {
  return ambulatoryPrescriptionsSelector(state, patientId)
    ?.sort(sortInline('updatedAt', 'desc'))
    .filter(({ status }) => status === targetStatus);
};

export const ambulatoryPrescriptionItemsSelector = (
  state: any,
  patientId: string,
  prescriptionId: string | undefined
): AmbulatoryPrescriptionItemT[] => {
  const { byId, byPrescriptionId } = state.entities.getIn(ITEMS).toJS();

  if (!prescriptionId) return [];

  const prescriptionItemIds =
    byPrescriptionId[patientId]?.[prescriptionId] || [];

  return prescriptionItemIds.map((id: string) => byId[id]);
};

export const ambulatoryPrescriptionDraftItemsSelector = (
  state: any,
  patientId: string
): AmbulatoryPrescriptionFormItemT[] => {
  const draftItems = state.entities.getIn(DRAFT_ITEMS).toJS();

  if (!draftItems[patientId]) return [];

  return deindex(draftItems[patientId]);
};
