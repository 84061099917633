import { Navigate, useLocation } from 'react-router-dom';

import { useCurrentUser } from 'providers';

import { featureCheck } from 'components/FeatureFlag';

import { RightClaimT } from 'rights';

export interface Props {
  rights: RightClaimT[];
  children: JSX.Element;
}

export default function RequireAuth({ rights, children }: Props) {
  const location = useLocation();
  const currentUser = useCurrentUser();

  const authorized = featureCheck({ currentUser, rights });

  if (!authorized) {
    return <Navigate to="/forbidden" state={{ from: location }} replace />;
  }

  return children;
}
