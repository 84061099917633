import { lazy, Suspense, useEffect } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import configureStore from 'store';

import {
  AuthProvider,
  ConfigurationProvider,
  CurrentUserProvider,
  DefaultQueryClientProvider,
  ErrorBoundary,
  LocaleProvider,
  PerspectiveProvider,
  RouteWatcher,
  ToastProvider,
  useAuth,
} from 'providers';

import Loading from 'components/Loading';

import { locale } from 'locale';

const AuthenticatedApp = lazy(
  () => import(/* webpackPrefetch: true */ './components/Authenticated')
);
const UnauthenticatedApp = lazy(() => import('./components/Unauthenticated'));

const store = configureStore();

export default function Root() {
  useEffect(() => {
    if (document.documentElement) {
      document.documentElement.lang = locale;
    }
  }, []);

  return (
    <StoreProvider store={store}>
      <DefaultQueryClientProvider>
        <LocaleProvider>
          <ErrorBoundary>
            <Router>
              <RouteWatcher />

              <AuthProvider>
                <App />
              </AuthProvider>

              <ToastProvider />
            </Router>
          </ErrorBoundary>
        </LocaleProvider>

        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </DefaultQueryClientProvider>
    </StoreProvider>
  );
}

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <Suspense fallback={<Loading />}>
      {isAuthenticated ? (
        <CurrentUserProvider>
          <ConfigurationProvider>
            <PerspectiveProvider>
              <AuthenticatedApp />
            </PerspectiveProvider>
          </ConfigurationProvider>
        </CurrentUserProvider>
      ) : (
        <ConfigurationProvider>
          <UnauthenticatedApp />
        </ConfigurationProvider>
      )}
    </Suspense>
  );
}
