import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const consumptionSetItemSchema = new schema.Entity(
  'consumptionSetItems',
  {},
  {
    processStrategy: schemaDeserializers({
      updatedAt: parseDateTime,
    }),
  }
);

export const consumptionSetSchema = new schema.Entity(
  'consumptionSets',
  {
    consumptionSetItems: [consumptionSetItemSchema],
  },
  {
    processStrategy: schemaDeserializers({
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

export const GET_PRODUCT_CONSUMPTION_SETS = networkActionTypes(
  'GET_PRODUCT_CONSUMPTION_SETS'
);

export const getProductConsumptionSets = (
  productId: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_PRODUCT_CONSUMPTION_SETS,
    url: `medications/products/${productId}/consumption_sets`,
    actionPayload: { productId },
    method: 'GET',
    normalizeSchema: {
      consumptionSets: [consumptionSetSchema],
    },
  },
});

export const CREATE_PRODUCT_CONSUMPTION_SET = networkActionTypes(
  'CREATE_PRODUCT_CONSUMPTION_SET'
);

interface CreateUpdateConsumptionSetArgT {
  name: string;
  items: { productId: string; quantity: number }[];
}

export const createConsumptionSet = (
  productId: string,
  consumptionSet: CreateUpdateConsumptionSetArgT
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_PRODUCT_CONSUMPTION_SET,
    url: `medications/products/${productId}/consumption_sets`,
    actionPayload: { productId },
    method: 'POST',
    payload: { consumptionSet },
    normalizeSchema: {
      consumptionSet: consumptionSetSchema,
    },
  },
});
export const UPDATE_PRODUCT_CONSUMPTION_SET = networkActionTypes(
  'UPDATE_PRODUCT_CONSUMPTION_SET'
);

interface UpdateUpdateConsumptionSetArgT
  extends Partial<CreateUpdateConsumptionSetArgT> {
  id: string;
}

export const updateConsumptionSet = (
  productId: string,
  consumptionSet: UpdateUpdateConsumptionSetArgT
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PRODUCT_CONSUMPTION_SET,
    url: `medications/products/${productId}/consumption_sets/${consumptionSet.id}`,
    method: 'PUT',
    payload: { consumptionSet },
    normalizeSchema: {
      consumptionSet: consumptionSetSchema,
    },
  },
});

export const DELETE_PRODUCT_CONSUMPTION_SET = networkActionTypes(
  'DELETE_PRODUCT_CONSUMPTION_SET'
);

export const deleteConsumptionSet = (
  productId: string,
  consumptionSetId: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_PRODUCT_CONSUMPTION_SET,
    actionPayload: { productId, consumptionSetId },
    url: `medications/products/${productId}/consumption_sets/${consumptionSetId}`,
    method: 'DELETE',
  },
});
