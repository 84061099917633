import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const patientInsuranceSchema = new schema.Entity(
  'patientInsurances',
  {},
  {
    processStrategy: schemaDeserializers({
      planEffectiveDate: parseDateTime,
      planExpirationDate: parseDateTime,
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

export const GET_PATIENT_INSURANCES = networkActionTypes(
  'GET_PATIENT_INSURANCES'
);

export const getPatientInsurances = (patientId: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_PATIENT_INSURANCES,
    url: `patients/${patientId}/insurances`,
    method: 'GET',
    actionPayload: { patientId },
    normalizeSchema: { patientInsurances: [patientInsuranceSchema] },
  },
});

export const UPDATE_PATIENT_INSURANCE = networkActionTypes(
  'UPDATE_PATIENT_INSURANCE'
);

type UpdatePatientInsuranceArgsT = {
  id: string;
  companyId: string;
  companyName: string;
  companyPhoneNumber: string;
  planType: string;
  insuredsIdNumber: string;
  planEffectiveDate: Date;
  planExpirationDate?: Date;
};

export const updatePatientInsurance = ({
  id,
  companyId,
  companyName,
  companyPhoneNumber,
  planType,
  planEffectiveDate,
  planExpirationDate,
  insuredsIdNumber,
}: UpdatePatientInsuranceArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PATIENT_INSURANCE,
    url: `insurances/${id}`,
    method: 'PUT',
    payload: {
      patientInsurance: {
        companyId,
        companyName,
        companyPhoneNumber,
        planType,
        insuredsIdNumber,
        planEffectiveDate,
        planExpirationDate: planExpirationDate || null,
      },
    },
    normalizeSchema: { patientInsurance: patientInsuranceSchema },
  },
});

export const CREATE_PATIENT_INSURANCE = networkActionTypes(
  'CREATE_PATIENT_INSURANCE'
);

type CreatePatientInsuranceArgsT = {
  id: string;
  patientId: string;
  companyId: string;
  companyName: string;
  companyPhoneNumber: string;
  planType: string;
  insuredsIdNumber: string;
  planEffectiveDate: Date;
  planExpirationDate?: Date;
};

export const createPatientInsurance = ({
  patientId,
  companyName,
  companyId,
  companyPhoneNumber,
  planType,
  planEffectiveDate,
  planExpirationDate,
  insuredsIdNumber,
}: CreatePatientInsuranceArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_PATIENT_INSURANCE,
    url: `patients/${patientId}/insurances`,
    method: 'POST',
    payload: {
      patientInsurance: {
        companyName,
        companyId,
        companyPhoneNumber,
        planType,
        planEffectiveDate,
        planExpirationDate,
        insuredsIdNumber,
      },
    },
    normalizeSchema: { patientInsurance: patientInsuranceSchema },
    actionPayload: { patientId },
  },
});

export const DELETE_PATIENT_INSURANCE = networkActionTypes(
  'DELETE_PATIENT_INSURANCE'
);

type DeletePatientInsuranceArgsT = {
  id: string;
  patientId: string;
};

export const deletePatientInsurance = ({
  id,
  patientId,
}: DeletePatientInsuranceArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_PATIENT_INSURANCE,
    url: `insurances/${id}`,
    method: 'DELETE',
    payload: { id },
    actionPayload: { patientId },
  },
});
