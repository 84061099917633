import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { patientSchema } from 'store/modules/entities/actions/patients';
import { networkActionTypes } from 'store/utils';

const woundSchema = new schema.Entity(
  'wounds',
  {
    patient: patientSchema,
  },
  {
    processStrategy: schemaDeserializers({
      lastEvaluatedAt: parseDateTime,
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

const woundTypeSchema = new schema.Entity('woundTypes');

export const GET_WOUND = networkActionTypes('GET_WOUND');

type GetWoundArgsT = {
  woundId: string;
};

export const getWound = ({ woundId }: GetWoundArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_WOUND,
    url: `wounds/${woundId}`,
    method: 'GET',
    normalizeSchema: { 'wounds/wound': woundSchema },
  },
});

export const GET_WOUNDS = networkActionTypes('GET_WOUNDS');

type GetWoundsArgsT = {
  patientId: string;
  status: string;
  page: number;
};

export const getWounds = ({
  patientId,
  status,
  page = 0,
}: GetWoundsArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_WOUNDS,
    url: `patients/${patientId}/wounds`,
    method: 'GET',
    params: {
      patientId,
      status,
      page,
      pageSize: 30,
    },
    normalizeSchema: { data: [woundSchema] },
  },
});

export const GET_WOUND_TYPES = networkActionTypes('GET_WOUND_TYPES');

export const getWoundTypes = (): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_WOUND_TYPES,
    url: 'wound_types',
    method: 'GET',
    normalizeSchema: { 'wounds/woundTypes': [woundTypeSchema] },
  },
});

export const CREATE_WOUND = networkActionTypes('CREATE_WOUND');

export type CreateWoundArgsT = {
  patientId: string;
  type: string;
  location: string;
  comment: string;
};

export const createWound = ({
  patientId,
  type,
  location,
  comment,
}: CreateWoundArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_WOUND,
    url: `patients/${patientId}/wounds`,
    method: 'POST',
    payload: {
      wound: {
        type,
        location,
        comment,
      },
    },
    actionPayload: { patientId },
    normalizeSchema: { 'wounds/wound': woundSchema },
  },
});

export const UPDATE_WOUND = networkActionTypes('UPDATE_WOUND');

export const updateWound = (
  patient: PatientT,
  wound: {
    id: string;
    type: string;
    location: string;
    comment?: string;
  }
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_WOUND,
    url: `wounds/${wound.id}`,
    method: 'PUT',
    payload: {
      wound: {
        type: wound.type,
        location: wound.location,
        comment: wound.comment || null,
      },
    },
    actionPayload: { patientId: patient.id, woundId: wound.id },
    normalizeSchema: { 'wounds/wound': woundSchema },
  },
});

export const ARCHIVE_WOUND = networkActionTypes('ARCHIVE_WOUND');

export const archiveWound = (
  patient: PatientT,
  wound: WoundT
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: ARCHIVE_WOUND,
    url: `wounds/${wound.id}`,
    method: 'DELETE',
    actionPayload: { patientId: patient.id, woundId: wound.id },
  },
});
