import { Component, ReactChild } from 'react';
import * as Sentry from '@sentry/browser';

import ErrorPage from 'views/error';

type Props = {
  children: ReactChild | ReactChild[];
};

type State = {
  error: Error | null;
};

class ErrorBoundary extends Component<Props, State> {
  state = { error: null };

  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({ error });

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <ErrorPage onClickCallBack={() => Sentry.showReportDialog()} />;
    }

    return children;
  }
}

export default ErrorBoundary;
