import { startActivity } from 'store/modules/activity';

import { BillingCode, BillingTreatmentSetting } from './fetchers';

export const EDIT_BILLING_CODE = 'EDIT_BILLING_CODE';
export const EDIT_BILLING_TREATMENT_SETTINGS =
  'EDIT_BILLING_TREATMENT_SETTINGS';

export const UPDATE_BILLING_CODE_FAILURE = 'UPDATE_BILLING_CODE_FAILURE';
export const UPDATE_BILLING_TREATMENT_SETTINGS_FAILURE =
  'UPDATE_BILLING_TREATMENT_SETTINGS_FAILURE';

export const editBillingCodeActivity = (billingCode: BillingCode) =>
  startActivity(EDIT_BILLING_CODE, { billingCode });

export const editBillingTreatmentSettings = (
  billingTreatmentSetting: BillingTreatmentSetting
) =>
  startActivity(EDIT_BILLING_TREATMENT_SETTINGS, { billingTreatmentSetting });
