import { startActivity } from 'store/modules/activity';

import { WeightT } from './fetchers';

export const ADD_WEIGHT = 'ADD_WEIGHT';
export const EDIT_WEIGHT = 'EDIT_WEIGHT';
export const REMOVE_WEIGHT = 'REMOVE_WEIGHT';

export const CREATE_WEIGHT_FAILURE = 'CREATE_WEIGHT_FAILURE';
export const UPDATE_WEIGHT_FAILURE = 'UPDATE_WEIGHT_FAILURE';

export const addWeightActivity = () => startActivity(ADD_WEIGHT);

export const editWeightActivity = (weight: WeightT) =>
  startActivity(EDIT_WEIGHT, { weight });

export const removeWeightActivity = (weight: WeightT) =>
  startActivity(REMOVE_WEIGHT, { weight });
