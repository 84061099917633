import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const PAGE_SIZE = 30;
const permissionsSchema = new schema.Entity('userPermissions');

export const GET_GLOBAL_PERMISSIONS = networkActionTypes(
  'GET_GLOBAL_PERMISSIONS'
);

export const getGlobalPermissions = (page: number): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_GLOBAL_PERMISSIONS,
    url: 'users/global_permissions',
    method: 'GET',
    params: { pageSize: PAGE_SIZE, page },
    normalizeSchema: { data: [permissionsSchema] },
  },
});

export const GET_USER_PERMISSIONS = networkActionTypes('GET_USER_PERMISSIONS');

export const getUserPermissions = (
  centerId: string,
  page: number
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_USER_PERMISSIONS,
    url: `centers/${centerId}/user_permissions`,
    method: 'GET',
    params: { pageSize: PAGE_SIZE, page },
    normalizeSchema: { data: [permissionsSchema] },
  },
});

// We only have to re-fetch centers and currentUser here.
// This is the only endpoint in the acl where the user can adjust his own centers!

const userSchema = new schema.Entity(
  'users',
  {},
  {
    processStrategy: schemaDeserializers({
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

export const CREATE_GLOBAL_PERMISSION = networkActionTypes(
  'CREATE_GLOBAL_PERMISSION'
);

export const createGlobalPermission = ({
  userId,
}: {
  userId: string;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_GLOBAL_PERMISSION,
    url: `users/${userId}`,
    method: 'PUT',
    payload: { user: { permission: 'global' } },
    normalizeSchema: { user: userSchema },
  },
});

export const CREATE_USER_PERMISSION = networkActionTypes(
  'CREATE_USER_PERMISSION'
);

export const createUserPermission = ({
  centerId,
  userIds,
}: {
  centerId: string;
  userIds: string[];
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_USER_PERMISSION,
    url: `centers/${centerId}/user_permissions`,
    method: 'POST',
    payload: { centerPermissions: { userIds } },
    normalizeSchema: { data: [permissionsSchema] },
  },
});

export const DELETE_GLOBAL_PERMISSION = networkActionTypes(
  'DELETE_GLOBAL_PERMISSION'
);

export const deleteGlobalPermission = ({
  userId,
}: {
  userId: string;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_GLOBAL_PERMISSION,
    url: `users/${userId}`,
    method: 'PUT',
    payload: { user: { permission: 'center_based' } },
    actionPayload: { userId },
  },
});

export const DELETE_USER_PERMISSION = networkActionTypes(
  'DELETE_USER_PERMISSION'
);

export const deleteUserPermission = ({
  centerId,
  userId,
}: {
  centerId: string;
  userId: string;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_USER_PERMISSION,
    url: `centers/${centerId}/user_permissions`,
    method: 'DELETE',
    payload: { centerPermissions: { userIds: [userId] } },
    actionPayload: { userId },
  },
});
