import { schema } from 'normalizr';
import { serialize as objectToFormData } from 'object-to-formdata';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

import networkResponseHandler from 'utils/networkResponseHandler';

const evaluationSchema = new schema.Entity(
  'woundEvaluations',
  {},
  {
    processStrategy: (evaluation) => ({
      ...evaluation,
      ...schemaDeserializers({
        createdAt: parseDateTime,
        updatedAt: parseDateTime,
      })(evaluation),
      user: evaluation.user
        ? {
            ...evaluation.user,
            ...schemaDeserializers({
              createdAt: parseDateTime,
              deletedAt: parseDateTime,
              updatedAt: parseDateTime,
            })(evaluation.user),
          }
        : null,
    }),
  }
);

const woundBedOptions = new schema.Entity('woundBedOptions');
const woundMoistureOptions = new schema.Entity('woundMoistureOptions');
const woundEdgeOptions = new schema.Entity('woundEdgeOptions');

export const GET_WOUND_EVALUATION_OPTIONS = networkActionTypes(
  'GET_WOUND_EVALUATION_OPTIONS'
);

export const getWoundEvaluationOptions = (): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_WOUND_EVALUATION_OPTIONS,
    url: 'wound_evaluation_options',
    method: 'GET',
    normalizeSchema: {
      woundBedOptions: [woundBedOptions],
      woundMoistureOptions: [woundMoistureOptions],
      woundEdgeOptions: [woundEdgeOptions],
    },
  },
});

export const GET_WOUND_EVALUATIONS = networkActionTypes(
  'GET_WOUND_EVALUATIONS'
);

type GetWoundEvaluationsArgsT = {
  woundId: string;
  page?: number;
};

export const getWoundEvaluations =
  ({ woundId, page }: GetWoundEvaluationsArgsT): ThunkNetworkActionT =>
  (dispatch) =>
    networkResponseHandler(dispatch(getWoundEvaluationOptions()))
      .then(() =>
        dispatch({
          type: 'CALL_API',
          payload: {
            types: GET_WOUND_EVALUATIONS,
            url: `wounds/${woundId}/evaluations`,
            method: 'GET',
            params: {
              woundId,
              page,
              pageSize: 30,
            },
            normalizeSchema: { data: [evaluationSchema] },
          },
        })
      )
      .catch((action) => action);

export const CREATE_WOUND_EVALUATION = networkActionTypes(
  'CREATE_WOUND_EVALUATION'
);

export type CreateWoundEvaluationArgsT = {
  woundId: string;
  bed?: string;
  infected: boolean;
  culture: boolean;
  antibiotics: boolean;
  scent: boolean;
  moisture?: string;
  edge?: string;
  size?: string;
  followUpBySurgeon: boolean;
  note: string;
  pictures: File[];
};

export const createWoundEvaluation = ({
  woundId,
  bed,
  infected,
  culture,
  antibiotics,
  scent,
  moisture,
  edge,
  size,
  followUpBySurgeon,
  note,
  pictures = [],
}: CreateWoundEvaluationArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_WOUND_EVALUATION,
    url: `wounds/${woundId}/evaluations`,
    method: 'POST',
    payload: objectToFormData({
      evaluation: {
        bed,
        infected,
        culture,
        antibiotics,
        scent,
        moisture,
        edge,
        size,
        follow_up_by_surgeon: followUpBySurgeon,
        note,
        pictures: pictures.length > 0 ? pictures : null,
      },
    }),
    actionPayload: {
      woundId,
    },
    normalizeSchema: { 'wounds/woundEvaluation': evaluationSchema },
  },
});
