import { serialize as objectToFormData } from 'object-to-formdata';

import { networkActionTypes } from 'store/utils';

export const IMPORT_PATIENTS = networkActionTypes('IMPORT_PATIENTS');

export const importPatients = (file: File): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: IMPORT_PATIENTS,
    url: 'patients/import',
    method: 'PUT',
    payload: objectToFormData({ file }),
  },
});

export const IMPORT_PATIENTS_STATUS_REPORT = networkActionTypes(
  'IMPORT_PATIENTS_STATUS_REPORT'
);

export const importPatientsStatusReport = (): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: IMPORT_PATIENTS_STATUS_REPORT,
    url: 'patients/import/feedback_report_available',
    method: 'GET',
  },
});

export const IMPORT_PATIENTS_STATUS_UPLOAD = networkActionTypes(
  'IMPORT_PATIENTS_STATUS_UPLOAD'
);

export const importPatientsStatusUpload = (): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: IMPORT_PATIENTS_STATUS_UPLOAD,
    url: 'patients/import/processing_status',
    method: 'GET',
  },
});
