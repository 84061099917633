// The following code imitates how `humps` loops over objects with eg `camelizeKeys`.
// https://raw.githubusercontent.com/domchristie/humps/master/humps.js

export default function walkObject(
  source: any,
  callback: (path: string, value: any) => void,
  path: (string | number)[] = []
) {
  if (isIterableObject(source)) {
    for (let key in source) {
      const value = source[key];
      const currentPath = [...path, key];

      callback(stringifyPath(currentPath), value);
      walkObject(value, callback, currentPath);
    }
  } else if (isArray(source)) {
    source.forEach((value, index) => {
      const currentPath = [...path, index];

      callback(stringifyPath(currentPath), value);
      walkObject(value, callback, currentPath);
    });
  }
}

function stringifyPath(path: (string | number)[]) {
  return path.reduce<string>(
    (acc, segment, index) =>
      index === 0
        ? `${segment}`
        : isNumber(segment)
        ? `${acc}[${segment}]`
        : `${acc}.${segment}`,
    ''
  );
}

function isIterableObject(arg: any) {
  return (
    isObject(arg) &&
    !isArray(arg) &&
    !isDate(arg) &&
    !isRegExp(arg) &&
    !isBoolean(arg) &&
    !isFunction(arg)
  );
}

const toString = Object.prototype.toString;

function isNumber(arg: any): arg is number {
  return typeof arg === 'number';
}

function isObject(arg: any): arg is {} {
  return arg === Object(arg);
}

function isArray(arg: any): arg is any[] {
  return toString.call(arg) === '[object Array]';
}

function isDate(arg: any): arg is Date {
  return toString.call(arg) === '[object Date]';
}

function isRegExp(arg: any): arg is RegExp {
  return toString.call(arg) === '[object RegExp]';
}

function isBoolean(arg: any): arg is boolean {
  return toString.call(arg) === '[object Boolean]';
}

function isFunction(arg: any): arg is Function {
  return typeof arg === 'function';
}
