import { startActivity } from 'store/modules/activity';

export const ADD_CONSUMABLE_ITEM = 'ADD_CONSUMABLE_ITEM';
export const EDIT_CONSUMABLE_ITEM = 'EDIT_CONSUMABLE_ITEM';

export const CREATE_CONSUMABLE_ITEM_FAILURE = 'CREATE_CONSUMABLE_ITEM_FAILURE';
export const UPDATE_CONSUMABLE_ITEM_FAILURE = 'UPDATE_CONSUMABLE_ITEM_FAILURE';

export const addConsumableItemActivity = () =>
  startActivity(ADD_CONSUMABLE_ITEM);

export const editConsumableItemActivity = (consumableItem: ConsumableItemT) =>
  startActivity(EDIT_CONSUMABLE_ITEM, { consumableItem });
