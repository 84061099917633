import { startActivity } from 'store/modules/activity';

import { BriefingT } from './fetchers';

export const ADD_BRIEFING = 'ADD_BRIEFING';
export const EDIT_BRIEFING = 'EDIT_BRIEFING';
export const START_CLOSE_BRIEFING = 'START_CLOSE_BRIEFING';

export const CREATE_BRIEFING_FAILURE = 'CREATE_BRIEFING_FAILURE';
export const UPDATE_BRIEFING_FAILURE = 'UPDATE_BRIEFING_FAILURE';
export const CLOSE_BRIEFING_FAILURE = 'CLOSE_BRIEFING_FAILURE';

export const CREATE_BRIEFING_SELECTION_FAILURE =
  'CREATE_BRIEFING_SELECTION_FAILURE';
export const DELETE_BRIEFING_SELECTION_FAILURE =
  'DELETE_BRIEFING_SELECTION_FAILURE';

export const CREATE_DISCUSSION_FAILURE = 'CREATE_DISCUSSION_FAILURE';
export const DELETE_DISCUSSION_FAILURE = 'DELETE_DISCUSSION_FAILURE';

export const startAddBriefingActivity = () => startActivity(ADD_BRIEFING);

export const startEditBriefingActivity = (briefing: BriefingT) =>
  startActivity(EDIT_BRIEFING, { briefing });

export const startCloseBriefingActivity = () =>
  startActivity(START_CLOSE_BRIEFING);
