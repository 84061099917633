import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { useStoreErrorHandler } from 'api/storeErrorHandler';

export default function DefaultQueryClientProvider({
  children,
}: {
  children: ReactNode;
}) {
  const errorHandler = useStoreErrorHandler();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error: any) => {
          const { method, url } = error?.config || {};

          const failureType =
            method && url
              ? `${method.toUpperCase()}${url.startsWith('/') ? '' : '_'}${url
                  .replace(/\//g, '_')
                  .toUpperCase()}_FAILURE`
              : 'GENERIC_FETCH_FAILURE';

          errorHandler({ error, failureType });
        },
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
