import { fromJS } from 'immutable';

import {
  GET_INVALID_AMBULATORY_PRESCRIPTIONS,
  GET_INVALID_INTRADIALYTIC_PRESCRIPTIONS,
} from 'store/modules/entities/actions/medication';

export const mergePrescriptions = (
  state: any,
  action: any,
  type: string
): any => {
  const {
    response: {
      entities,
      result: { data },
    },
  } = action.payload;

  const medications = entities[`${type}Medication`];

  return state
    .mergeDeepIn(
      ['invalidPrescriptionsOverview', 'byId'],
      fromJS(medications || {})
    )
    .setIn(['invalidPrescriptionsOverview', type], fromJS(data || []));
};

export default function invalidPrescriptionsReducer(state: any, action: any) {
  switch (action.type) {
    case GET_INVALID_INTRADIALYTIC_PRESCRIPTIONS.SUCCESS: {
      return mergePrescriptions(state, action, 'intradialytic');
    }

    case GET_INVALID_AMBULATORY_PRESCRIPTIONS.SUCCESS: {
      return mergePrescriptions(state, action, 'ambulatory');
    }

    default: {
      return state;
    }
  }
}

export const invalidPrescriptionsSelector = (
  state: any,
  type: MedicationTypesT
): any[] | null | undefined => {
  const isFetching = !!(
    state.network[`GET_INVALID_${type.toUpperCase()}_PRESCRIPTIONS`] ||
    state.network[`GET_INVALID_${type.toUpperCase()}_PRESCRIPTIONS`] ===
      undefined
  );

  if (isFetching) return undefined;

  const { invalidPrescriptionsOverview } = state.entities.toJS();

  const { byId } = invalidPrescriptionsOverview;
  const byResult = invalidPrescriptionsOverview[type];

  if (!byResult) return undefined;

  return byResult.map((id: string) => byId[id]);
};
