import { ReactElement } from 'react';

import { useConfigurations } from 'hooks/useConfig';

import Loading from 'components/Loading';

export default function ConfigurationProvider({
  children,
}: {
  children: ReactElement;
}) {
  const { isLoading, configurations } = useConfigurations();

  return isLoading || !configurations ? <Loading /> : children;
}
