import get from 'lodash/get';

type Property = string | ((obj: any) => any);
type Order = 'asc' | 'desc';

// raw value to comparable value
const preprocessors = {
  array: (val) => val.length,
  string: (val) => val.toLowerCase(),
};

const getDataType = (data: any): string => {
  if (Array.isArray(data)) return 'array';
  if (data instanceof Date) return 'date';
  return typeof data;
};

const getValue = (obj: any, property: Property) =>
  typeof property === 'function'
    ? property(obj)
    : get(obj, property, undefined);

const getCompareValue = (value: any) => {
  const type = getDataType(value);
  const preprocessor = preprocessors[type];

  return preprocessor?.(value) || value;
};

const genericSort =
  (property: Property, order: Order) => (objA: any, objB: any) => {
    const valueA = getValue(objA, property);
    const valueB = getValue(objB, property);

    const compareValA = getCompareValue(valueA);
    const compareValB = getCompareValue(valueB);

    if (compareValA < compareValB) return order === 'asc' ? -1 : 1;
    if (compareValA > compareValB) return order === 'asc' ? 1 : -1;
    return 0;
  };

export const combineSortFunctions =
  (...sortFns: ((a: any, b: any) => number)[]) =>
  (a: any, b: any) => {
    for (let i = 0; i < sortFns.length; i += 1) {
      const sortFnResult = sortFns[i](a, b);
      if (sortFnResult !== 0) return sortFnResult;
    }
    return 0;
  };

export default function sortInline(
  properties: Property[] | Property = 'id',
  orders: Order[] | Order = 'asc'
): (a: any, b: any) => number {
  const propertiesArray = Array.isArray(properties) ? properties : [properties];

  const sortFns = propertiesArray.map((property, index) =>
    genericSort(
      property,
      (Array.isArray(orders) ? orders[index] : orders) || 'asc'
    )
  );

  return combineSortFunctions(...sortFns);
}
