import { startActivity } from 'store/modules/activity';

import { PatientImmunizationDetail, Vaccination } from './fetchers';

export const EDIT_PATIENT_IMMUNIZATION = 'EDIT_PATIENT_IMMUNIZATION';
export const ADD_HISTORIC_VACCINATION = 'ADD_HISTORIC_VACCINATION';
export const EDIT_HISTORIC_VACCINATION = 'EDIT_HISTORIC_VACCINATION';
export const REMOVE_HISTORIC_VACCINATION = 'REMOVE_HISTORIC_VACCINATION';
export const ADD_PLANNED_VACCINATION = 'ADD_PLANNED_VACCINATION';

export const UPDATE_PATIENT_IMMUNIZATION_FAILURE =
  'UPDATE_PATIENT_IMMUNIZATION_FAILURE';
export const CREATE_HISTORIC_VACCINATION_FAILURE =
  'CREATE_HISTORIC_VACCINATION_FAILURE';
export const UPDATE_HISTORIC_VACCINATION_FAILURE =
  'UPDATE_HISTORIC_VACCINATION_FAILURE';
export const DELETE_HISTORIC_VACCINATION_FAILURE =
  'DELETE_HISTORIC_VACCINATION_FAILURE';
export const CREATE_PLANNED_VACCINATION_FAILURE =
  'CREATE_PLANNED_VACCINATION_FAILURE';

export const editImmunizationActivity = (
  immunization: PatientImmunizationDetail
) => startActivity(EDIT_PATIENT_IMMUNIZATION, { immunization });

export const addHistoricVaccinationActivity = () =>
  startActivity(ADD_HISTORIC_VACCINATION);

export const editHistoricVaccinationActivity = (vaccination: Vaccination) =>
  startActivity(EDIT_HISTORIC_VACCINATION, { vaccination });

export const removeHistoricVaccinationActivity = (
  historicVaccination: Vaccination
) => startActivity(REMOVE_HISTORIC_VACCINATION, { historicVaccination });

export const addPlannedVaccinationActivity = () =>
  startActivity(ADD_PLANNED_VACCINATION);
