import { schema } from 'normalizr';

import { parseForm, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const woundPrescriptionFormSchema = new schema.Entity(
  'forms',
  {},
  {
    processStrategy: (data) => parseForm(data),
    idAttribute: () => 'form',
  }
);

const woundPrescriptionSchema = new schema.Entity(
  'prescriptions',
  {
    prescription: woundPrescriptionFormSchema,
  },
  {
    processStrategy: schemaDeserializers({}),
    idAttribute: () => 'prescription',
  }
);

export const GET_WOUND_PRESCRIPTION = networkActionTypes(
  'GET_WOUND_PRESCRIPTION'
);

export const getWoundPrescription = (woundId: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_WOUND_PRESCRIPTION,
    url: `wounds/${woundId}/prescription`,
    method: 'GET',
    normalizeSchema: {
      'wounds/prescription': woundPrescriptionSchema,
    },
  },
});

export const UPDATE_PRESCRIPTION_FOR_WOUND = networkActionTypes(
  'UPDATE_PRESCRIPTION_FOR_WOUND'
);

export type UpdateWoundPrescriptionArgsT = {
  woundId: string;
  parameterValues: any;
};

export const updateWoundPrescription = ({
  woundId,
  ...parameterValues
}: UpdateWoundPrescriptionArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PRESCRIPTION_FOR_WOUND,
    url: `wounds/${woundId}/prescription`,
    method: 'PUT',
    payload: {
      parameterValues,
    },
    normalizeSchema: {
      'wounds/prescription': woundPrescriptionSchema,
    },
  },
});
