import { defineMessages, useIntl } from 'react-intl';

import uniqueId from 'utils/uniqueId';

export const DRAFT_ID_PREFIX = 'ambulatory_prescription_draft_item_';

export function getUniquePrescriptionItemId() {
  return uniqueId(DRAFT_ID_PREFIX);
}

export const PRESCRIPTION_STATUS = {
  requiresAttention: 'requires_attention' as AmbulatoryPrescriptionStatusT,
  draft: 'draft' as AmbulatoryPrescriptionStatusT,
  sending: 'sending' as AmbulatoryPrescriptionStatusT,
  sendFailed: 'send_failed' as AmbulatoryPrescriptionStatusT,
  issued: 'issued' as AmbulatoryPrescriptionStatusT,
  delivered: 'delivered' as AmbulatoryPrescriptionStatusT,
  expired: 'expired' as AmbulatoryPrescriptionStatusT,
  revoked: 'revoked' as AmbulatoryPrescriptionStatusT,
  revokeFailed: 'revoke_failed' as AmbulatoryPrescriptionStatusT,
};

export const POSOLOGY_TYPE = {
  STRUCTURED: 'structured',
  FREE_TEXT: 'free_text',
};

interface PosologyT {
  posology?: string;
  administrationRoute?: string | null;
  dose?: string;
  amount?: number | null;
  unit?: string | null;
  frequency?: string;
  frequencyText?: string | null;
  quantity?: number | null;
  periodicity?: string | null;
  moment?: string | null;
}

interface ConstructPosologyPayloadT {
  values: PosologyT & { posologyType: string };
  momentOptions: OptionT[];
}

export function constructAmbulatoryPosologyPayload({
  momentOptions,
  values: {
    posologyType,
    posology,
    administrationRoute,
    dose,
    amount,
    unit,
    frequency,
    frequencyText,
    quantity,
    periodicity,
    moment,
  },
}: ConstructPosologyPayloadT) {
  if (posologyType === POSOLOGY_TYPE.FREE_TEXT) {
    return { freeText: posology };
  }

  const isDoseVariable = dose === 'variable_dose';

  const isFrequencyStructured = frequency === 'quantity_in_time';
  const isFrequencyFreeText = frequency === 'free_text';

  const momentLabel =
    momentOptions.find(({ value }) => value === moment)?.label || null;

  return {
    structured: {
      administrationRoute,
      dose: isDoseVariable ? { variable: true } : { amount, unit },
      frequency: isFrequencyStructured
        ? { structured: { quantity, periodicity, moment: momentLabel } }
        : isFrequencyFreeText
        ? { freeText: frequencyText }
        : { oneTime: true },
    },
  };
}

export function usePosologyAsText(
  posology: AmbulatoryMedicationPosologyT | null | undefined
) {
  const { formatMessage } = useIntl();

  if (!posology) return posology;

  const { freeText, structured } = posology;

  if (freeText) return freeText;

  const constructedDose = structured?.dose?.variable
    ? formatMessage(t.variableDose)
    : `${structured?.dose?.amount} x ${structured?.dose?.unit}`;

  const frequency = structured?.frequency;

  const constructedFrequency = (() => {
    if (frequency?.oneTime) {
      return formatMessage(t.oneTime);
    }

    if (frequency?.freeText) {
      return frequency?.freeText;
    }

    if (frequency?.structured) {
      const baseFrequency = `${frequency?.structured.quantity} / ${frequency?.structured.periodicity}`;

      return frequency?.structured.moment
        ? `${baseFrequency} / ${frequency?.structured.moment}`
        : baseFrequency;
    }
  })();

  const basePosology = `${constructedFrequency} - ${constructedDose}`;

  return structured?.administrationRoute
    ? `${basePosology} - ${structured?.administrationRoute}`
    : basePosology;
}

const t = defineMessages({
  oneTime: {
    id: 'ambulatory_medication_posology_label_one_time',
    defaultMessage: 'One-time',
  },
  variableDose: {
    id: 'ambulatory_medication_posology_abel_variable_dose',
    defaultMessage: 'Variable dose',
  },
});
