import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { medicinalProductProviderSchema } from 'store/modules/entities/actions/medicationManagement/providers';
import { networkActionTypes } from 'store/utils';

export const administrationRouteSchema = new schema.Entity(
  'administrationRoutes',
  { source: medicinalProductProviderSchema },
  { processStrategy: schemaDeserializers({ updatedAt: parseDateTime }) }
);

export const GET_ADMINISTRATION_ROUTES = networkActionTypes(
  'GET_ADMINISTRATION_ROUTES'
);

const PAGE_SIZE = 30;

export const getAdministrationRoutes = ({
  page = 0,
  sourceId,
  name,
  ambulatory,
  intradialytic,
}: {
  page?: number;
  sourceId?: string | null;
  name?: string | null;
  ambulatory?: string | null;
  intradialytic?: string | null;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_ADMINISTRATION_ROUTES,
    url: 'medications/administration_routes',
    method: 'GET',
    params: {
      page,
      sourceId,
      name,
      ambulatory,
      intradialytic,
      pageSize: PAGE_SIZE,
      codesAsTables: true,
    },
    normalizeSchema: { data: [administrationRouteSchema] },
  },
});

export const CREATE_ADMINISTRATION_ROUTE = networkActionTypes(
  'CREATE_ADMINISTRATION_ROUTE'
);

export const createAdministrationRoute = (administrationRoute: {
  name: string;
  sourceId: string;
  codes: CodesT;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_ADMINISTRATION_ROUTE,
    url: 'medications/administration_routes',
    method: 'POST',
    payload: { codesAsTables: true, administrationRoute },
    normalizeSchema: { administrationRoute: administrationRouteSchema },
  },
});

export const UPDATE_ADMINISTRATION_ROUTE = networkActionTypes(
  'UPDATE_ADMINISTRATION_ROUTE'
);

export const updateAdministrationRoute = (administrationRoute: {
  id: string;
  name: string;
  codes: CodesT;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_ADMINISTRATION_ROUTE,
    url: `medications/administration_routes/${administrationRoute.id}`,
    method: 'PUT',
    payload: { codesAsTables: true, administrationRoute },
    normalizeSchema: { administrationRoute: administrationRouteSchema },
  },
});

export const DELETE_ADMINISTRATION_ROUTE = networkActionTypes(
  'DELETE_ADMINISTRATION_ROUTE'
);

export const deleteAdministrationRoute = (id: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_ADMINISTRATION_ROUTE,
    url: `medications/administration_routes/${id}`,
    method: 'DELETE',
    actionPayload: { id },
  },
});
