import { PlanningAbsenceT, PlanningDeviationT } from 'api/fetchers/types';

import { startActivity } from 'store/modules/activity';

import { PlanningSchemaT } from './fetchers';

export const ADD_PLANNING_SCHEMA = 'ADD_PLANNING_SCHEMA';
export const EDIT_PLANNING_SCHEMA = 'EDIT_PLANNING_SCHEMA';
export const REMOVE_PLANNING_SCHEMA = 'REMOVE_PLANNING_SCHEMA';

export const CREATE_PLANNING_SCHEMA_FAILURE = 'CREATE_PLANNING_SCHEMA_FAILURE';
export const UPDATE_PLANNING_SCHEMA_FAILURE = 'UPDATE_PLANNING_SCHEMA_FAILURE';
export const DELETE_PLANNING_SCHEMA_FAILURE = 'DELETE_PLANNING_SCHEMA_FAILURE';

export const startAddPlanningSchemaActivity = () =>
  startActivity(ADD_PLANNING_SCHEMA);

export const startEditPlanningSchemaActivity = (
  planningSchema: PlanningSchemaT
) => startActivity(EDIT_PLANNING_SCHEMA, { planningSchema });

export const startRemovePlanningSchemaActivity = (
  planningSchema: PlanningSchemaT
) => startActivity(REMOVE_PLANNING_SCHEMA, { planningSchema });

export const ADD_ABSENCE = 'ADD_ABSENCE';
export const REMOVE_ABSENCE = 'REMOVE_ABSENCE';

export const CREATE_ABSENCE_FAILURE = 'CREATE_ABSENCE_FAILURE';
export const DELETE_ABSENCE_FAILURE = 'DELETE_ABSENCE_FAILURE';

export const startAddAbsenceActivity = () => startActivity(ADD_ABSENCE);

export const startRemoveAbsenceActivity = (absence: PlanningAbsenceT) =>
  startActivity(REMOVE_ABSENCE, { absence });

export const EDIT_DEVIATION = 'EDIT_DEVIATION';
export const REMOVE_DEVIATION = 'REMOVE_DEVIATION';

export const UPDATE_DEVIATION_FAILURE = 'UPDATE_DEVIATION_FAILURE';
export const DELETE_DEVIATION_FAILURE = 'DELETE_DEVIATION_FAILURE';

export const startEditDeviationActivity = (deviation: PlanningDeviationT) =>
  startActivity(EDIT_DEVIATION, { deviation });

export const startRemoveDeviationActivity = (deviation: PlanningDeviationT) =>
  startActivity(REMOVE_DEVIATION, { deviation });
