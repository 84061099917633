import { startActivity } from 'store/modules/activity';

import { InterventionT } from './fetchers';

export const ADD_INTERVENTION = 'ADD_INTERVENTION';
export const EDIT_INTERVENTION = 'EDIT_INTERVENTION';
export const REMOVE_INTERVENTION = 'REMOVE_INTERVENTION';

export const CREATE_INTERVENTION_FAILURE = 'CREATE_INTERVENTION_FAILURE';
export const UPDATE_INTERVENTION_FAILURE = 'UPDATE_INTERVENTION_FAILURE';

export const startAddInterventionActivity = () =>
  startActivity(ADD_INTERVENTION);

export const startEditInterventionActivity = (intervention: InterventionT) =>
  startActivity(EDIT_INTERVENTION, { intervention });

export const startRemoveInterventionActivity = (intervention: InterventionT) =>
  startActivity(REMOVE_INTERVENTION, { intervention });
