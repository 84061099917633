import { fromJS, Set } from 'immutable';

import {
  CREATE_ALLERGY,
  DELETE_ALLERGY,
  GET_ALLERGIES,
  GET_PATIENT_ALLERGY_INFO,
  UPDATE_ALLERGY,
} from 'store/modules/entities/actions/allergies';

import sortInline from 'utils/sort';

const BY_ID = ['allergies', 'byId'];
const BY_PATIENT_ID = ['allergies', 'byPatientId'];
const REACTIONS_PATH = ['allergyReactions'];

export default function allergiesReducer(state: any, action: any) {
  switch (action.type) {
    case GET_ALLERGIES.SUCCESS: {
      const {
        response: { entities, result },
        patientId,
      } = action.payload;

      return state
        .mergeIn(BY_ID, fromJS(entities.allergies || {}))
        .mergeIn(REACTIONS_PATH, fromJS(entities.allergyReactions || {}))
        .setIn(
          [...BY_PATIENT_ID, patientId],
          Set(result['patients/patientAllergies'])
        );
    }

    case CREATE_ALLERGY.SUCCESS: {
      const {
        response: { entities, result },
        patientId,
      } = action.payload;

      return state
        .mergeIn(BY_ID, fromJS(entities.allergies))
        .mergeIn(REACTIONS_PATH, fromJS(entities.allergyReactions || {}))
        .mergeIn(
          [...BY_PATIENT_ID, patientId],
          Set([result['patients/patientAllergy']])
        );
    }

    case UPDATE_ALLERGY.SUCCESS: {
      const {
        response: { entities },
      } = action.payload;

      return state
        .mergeIn(BY_ID, fromJS(entities.allergies))
        .mergeIn(REACTIONS_PATH, fromJS(entities.allergyReactions || {}));
    }

    case DELETE_ALLERGY.SUCCESS: {
      const { id } = action.payload;

      const byPatientId = state.getIn(BY_PATIENT_ID).mapEntries((e) => {
        const [key, set] = e;
        return [key, set.filter((x) => x !== id)];
      });

      return state.removeIn([...BY_ID, id]).setIn(BY_PATIENT_ID, byPatientId);
    }

    case GET_PATIENT_ALLERGY_INFO.SUCCESS: {
      const { response, patientId } = action.payload;

      return state.mergeDeepIn(['patients', patientId], fromJS(response || {}));
    }

    default: {
      return state;
    }
  }
}

type StoreAllergyT = Omit<AllergyT, 'allergyReactions'> & {
  allergyReactions: string[];
};

export const allergiesSelector = (
  state: any,
  patientId: string,
  conditionType?: 'allergy' | 'intolerance'
): AllergyT[] | undefined => {
  const isFetching = !!state.network.GET_ALLERGIES;

  if (isFetching) return undefined;

  const {
    allergies: { byId, byPatientId },
    allergyReactions,
  } = state.entities.toJS();

  const allergyIdsForPatient = byPatientId[patientId];

  if (!allergyIdsForPatient) return [];

  return allergyIdsForPatient
    .map((id: string) => byId[id])
    .filter((allergy: AllergyT) =>
      conditionType ? allergy.conditionType === conditionType : true
    )
    .map((allergy: StoreAllergyT) => ({
      ...allergy,
      allergyReactions: allergy.allergyReactions
        .map((reactionId) => allergyReactions?.[reactionId])
        .filter(Boolean),
    }))
    .sort(sortInline('createdAt', 'desc'));
};

export const allergyStatusSelector = (
  state: any,
  patientId?: string
): AllergyStatusT | undefined => {
  if (!patientId) return;

  const isFetching = !!state.network.GET_PATIENT;

  if (isFetching) return;

  const { patients } = state.entities.toJS();

  const patient = patients[patientId];

  if (!patient) return;

  return patient.allergiesInfo?.status;
};
