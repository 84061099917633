import { TransportTypeT } from 'api/fetchers/types';

import { startActivity } from 'store/modules/activity';

export const ADD_TRANSPORT_TYPE = 'ADD_TRANSPORT_TYPE';
export const EDIT_TRANSPORT_TYPE = 'EDIT_TRANSPORT_TYPE';
export const REMOVE_TRANSPORT_TYPE = 'REMOVE_TRANSPORT_TYPE';

export const CREATE_TRANSPORT_TYPE_FAILURE = 'CREATE_TRANSPORT_TYPE_FAILURE';
export const UPDATE_TRANSPORT_TYPE_FAILURE = 'UPDATE_TRANSPORT_TYPE_FAILURE';
export const DELETE_TRANSPORT_TYPE_FAILURE = 'DELETE_TRANSPORT_TYPE_FAILURE';

export const startAddTransportActivity = () =>
  startActivity(ADD_TRANSPORT_TYPE);

export const startEditTransportActivity = (transportType: TransportTypeT) =>
  startActivity(EDIT_TRANSPORT_TYPE, { transportType });

export const startRemoveTransportTypeActivity = (
  transportType: TransportTypeT
) => startActivity(REMOVE_TRANSPORT_TYPE, { transportType });
