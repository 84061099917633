import { startActivity } from 'store/modules/activity';

import { OrderType } from './fetchers';

export const ADD_ORDER_TYPE = 'ADD_ORDER_TYPE';
export const EDIT_ORDER_TYPE = 'EDIT_ORDER_TYPE';
export const REMOVE_ORDER_TYPE = 'REMOVE_ORDER_TYPE';

export const CREATE_ORDER_TYPE_FAILURE = 'CREATE_ORDER_TYPE_FAILURE';
export const UPDATE_ORDER_TYPE_FAILURE = 'UPDATE_ORDER_TYPE_FAILURE';
export const DELETE_ORDER_TYPE_FAILURE = 'DELETE_ORDER_TYPE_FAILURE';

export const startAddOrderTypeActivity = () => startActivity(ADD_ORDER_TYPE);

export const startEditOrderTypeActivity = (orderType: OrderType) =>
  startActivity(EDIT_ORDER_TYPE, { orderType });

export const startRemoveOrderTypeActivity = (orderType: OrderType) =>
  startActivity(REMOVE_ORDER_TYPE, { orderType });
