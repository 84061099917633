import { VaccinationGroupT } from 'api/fetchers/types';

import { startActivity } from 'store/modules/activity';

export const ADD_VACCINATION_GROUP = 'ADD_VACCINATION_GROUP';
export const EDIT_VACCINATION_GROUP = 'EDIT_VACCINATION_GROUP';
export const REMOVE_VACCINATION_GROUP = 'REMOVE_VACCINATION_GROUP';

export const CREATE_VACCINATION_GROUP_FAILURE =
  'CREATE_VACCINATION_GROUP_FAILURE';
export const UPDATE_VACCINATION_GROUP_FAILURE =
  'UPDATE_VACCINATION_GROUP_FAILURE';

export const addVaccinationGroupActivity = () =>
  startActivity(ADD_VACCINATION_GROUP);
export const editVaccinationGroupActivity = (
  vaccinationGroup: VaccinationGroupT
) => startActivity(EDIT_VACCINATION_GROUP, { vaccinationGroup });
export const removeVaccinationGroupActivity = (
  vaccinationGroup: VaccinationGroupT
) => startActivity(REMOVE_VACCINATION_GROUP, { vaccinationGroup });
