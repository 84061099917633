import { CenterT, PositionT, RoomT } from 'api/fetchers/types';

import { startActivity } from 'store/modules/activity';

export const ADD_CENTER = 'ADD_CENTER';
export const EDIT_CENTER = 'EDIT_CENTER';
export const REMOVE_CENTER = 'REMOVE_CENTER';

export const CREATE_CENTER_FAILURE = 'CREATE_CENTER_FAILURE';
export const UPDATE_CENTER_FAILURE = 'UPDATE_CENTER_FAILURE';
export const DELETE_CENTER_FAILURE = 'DELETE_CENTER_FAILURE';
export const REACTIVATE_CENTER_FAILURE = 'REACTIVATE_CENTER_FAILURE';

export const startAddCenterActivity = () => startActivity(ADD_CENTER);

export const startEditCenterActivity = (center: CenterT) =>
  startActivity(EDIT_CENTER, { center });

export const startRemoveCenterActivity = (center: CenterT) =>
  startActivity(REMOVE_CENTER, { center });

export const EDIT_SHIFTS = 'EDIT_SHIFTS';

export const startEditShiftsActivity = (center: CenterT) =>
  startActivity(EDIT_SHIFTS, { center });

export const ADD_ROOM = 'ADD_ROOM';
export const EDIT_ROOM = 'EDIT_ROOM';
export const REMOVE_ROOM = 'REMOVE_ROOM';

export const CREATE_ROOM_FAILURE = 'CREATE_ROOM_FAILURE';
export const UPDATE_ROOM_FAILURE = 'UPDATE_ROOM_FAILURE';
export const DELETE_ROOM_FAILURE = 'DELETE_ROOM_FAILURE';

export const startAddRoomActivity = () => startActivity(ADD_ROOM);

export const startEditRoomActivity = (room: RoomT) =>
  startActivity(EDIT_ROOM, { room });

export const startRemoveRoomActivity = (room: RoomT) =>
  startActivity(REMOVE_ROOM, { room });

export const ADD_POSITION = 'ADD_POSITION';
export const EDIT_POSITION = 'EDIT_POSITION';

export const startAddPositionActivity = (room: RoomT) =>
  startActivity(ADD_POSITION, { room });

export const startEditPositionActivity = (position: PositionT) =>
  startActivity(EDIT_POSITION, { position });

export const CREATE_POSITION_FAILURE = 'CREATE_POSITION_FAILURE';
export const UPDATE_POSITION_FAILURE = 'UPDATE_POSITION_FAILURE';
export const UPDATE_POSITION_ORDER_FAILURE = 'UPDATE_POSITION_ORDER_FAILURE';
