import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

import networkResponseHandler from 'utils/networkResponseHandler';

const allergyReactionSchema = new schema.Entity(
  'allergyReactions',
  {},
  {
    processStrategy: schemaDeserializers({
      onsetAt: parseDateTime,
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

const allerySchema = new schema.Entity(
  'allergies',
  { allergyReactions: [allergyReactionSchema] },
  {
    processStrategy: schemaDeserializers({
      onsetAt: parseDateTime,
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

export const GET_ALLERGIES = networkActionTypes('GET_ALLERGIES');

export const getAllergies = (patientId: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_ALLERGIES,
    url: `patients/${patientId}/allergies`,
    method: 'GET',
    actionPayload: { patientId },
    normalizeSchema: { 'patients/patientAllergies': [allerySchema] },
  },
});

export const createAllergyAndUpdateAllergyInfo =
  (args: CreateAllergyArgsT): ThunkNetworkActionT =>
  (dispatch) =>
    networkResponseHandler(dispatch(createAllergy(args)))
      .then(() => dispatch(getPatientAllergyInfo(args.patientId)))
      .catch((action) => action);

export const CREATE_ALLERGY = networkActionTypes('CREATE_ALLERGY');

export type CreateAllergyArgsT = {
  patientId: string;
  name: string;
  description: string;
  conditionType: string;
  conditionId?: string;
  criticality: string;
  clinicalStatus: string;
  verificationStatus?: string;
  onsetAt?: Date;
  note?: ConditionNoteT | null;
  allergyReactions: AllergyReactionT[];
  codes?: string[];
  createCondition?: boolean;
};

export const createAllergy = ({
  patientId,
  name,
  description,
  conditionType,
  conditionId,
  criticality,
  clinicalStatus,
  verificationStatus,
  onsetAt,
  note,
  allergyReactions,
  codes,
  createCondition,
}: CreateAllergyArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_ALLERGY,
    url: `patients/${patientId}/allergies`,
    method: 'POST',
    payload: {
      'patients/patientAllergy': {
        name,
        description,
        conditionType,
        conditionId,
        criticality,
        clinicalStatus,
        verificationStatus,
        onsetAt,
        note,
        allergyReactions,
        codes,
      },
      createCondition,
    },
    actionPayload: { patientId },
    normalizeSchema: { 'patients/patientAllergy': allerySchema },
  },
});

export const updateAllergyAndAllergyInfo =
  (args: UpdateAllergyArgsT, patientId: string): ThunkNetworkActionT =>
  (dispatch) =>
    networkResponseHandler(dispatch(updateAllergy(args)))
      .then(() => dispatch(getPatientAllergyInfo(patientId)))
      .catch((action) => action);

export const UPDATE_ALLERGY = networkActionTypes('UPDATE_ALLERGY');

export type UpdateAllergyArgsT = {
  id: string;
  name: string;
  description: string;
  conditionType: string;
  conditionId?: string;
  criticality: string;
  clinicalStatus: string;
  verificationStatus?: string;
  onsetAt?: Date;
  note?: ConditionNoteT;
  allergyReactions: AllergyReactionT[];
  codes?: string[];
};

export const updateAllergy = ({
  id,
  name,
  description,
  conditionType,
  conditionId,
  criticality,
  clinicalStatus,
  verificationStatus,
  onsetAt,
  note,
  allergyReactions,
  codes,
}: UpdateAllergyArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_ALLERGY,
    url: `patient_allergies/${id}`,
    method: 'PUT',
    payload: {
      'patients/patientAllergy': {
        name,
        description,
        conditionType,
        conditionId,
        criticality,
        clinicalStatus,
        verificationStatus,
        onsetAt: onsetAt ?? null,
        note,
        allergyReactions,
        codes,
      },
    },
    normalizeSchema: { 'patients/patientAllergy': allerySchema },
  },
});

export const deleteAllergyAndUpdateAllergyInfo =
  (id: string, patientId: string): ThunkNetworkActionT =>
  (dispatch) =>
    networkResponseHandler(dispatch(deleteAllergy(id)))
      .then(() => dispatch(getPatientAllergyInfo(patientId)))
      .catch((action) => action);

export const DELETE_ALLERGY = networkActionTypes('DELETE_ALLERGY');

export const deleteAllergy = (id: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_ALLERGY,
    url: `patient_allergies/${id}`,
    method: 'DELETE',
    actionPayload: { id },
  },
});

export const GET_PATIENT_ALLERGY_INFO = networkActionTypes(
  'GET_PATIENT_ALLERGY_INFO'
);

export const getPatientAllergyInfo = (patientId: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_PATIENT_ALLERGY_INFO,
    url: `patients/${patientId}/allergies_info`,
    method: 'GET',
    actionPayload: { patientId },
  },
});
