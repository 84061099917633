import { fromJS } from 'immutable';

import {
  CREATE_MEDICATION_ADMINISTRATION,
  DELETE_MEDICATION_ADMINISTRATION,
  GET_MEDICATION_ADMINISTRATIONS,
  UPDATE_MEDICATION_ADMINISTRATION,
  VALIDATE_MEDICATION_ADMINISTRATION,
} from 'store/modules/entities/actions/medicationAdministrations';

const PATH = ['medicationAdministrations'];
const BY_ID = [...PATH, 'byId'];
const BY_PATIENT_ID = [...PATH, 'byPatientId'];
const MEDICATION_VALIDATION_PATH = ['medicationValidations'];

export default function medicationAdministrationsReducer(
  state: any,
  action: any
) {
  switch (action.type) {
    case GET_MEDICATION_ADMINISTRATIONS.SUCCESS: {
      const {
        response: { entities, result },
        patientId,
      } = action.payload;

      const { administrations, medicationValidations } = entities;

      return state
        .mergeIn(BY_ID, fromJS(administrations))
        .mergeIn(
          MEDICATION_VALIDATION_PATH,
          fromJS(medicationValidations || {})
        )
        .setIn([...BY_PATIENT_ID, patientId], fromJS(result.data));
    }

    case CREATE_MEDICATION_ADMINISTRATION.SUCCESS: {
      const {
        response: {
          entities,
          result: { medicationAdministration },
        },
        patientId,
      } = action.payload;

      const { administrations, medicationValidations } = entities;

      const byPatientId = state
        .getIn([...BY_PATIENT_ID, patientId])
        .insert(0, medicationAdministration);

      return state
        .mergeDeepIn(BY_ID, fromJS(administrations))
        .mergeIn(MEDICATION_VALIDATION_PATH, fromJS(medicationValidations))
        .setIn([...BY_PATIENT_ID, patientId], byPatientId);
    }

    case UPDATE_MEDICATION_ADMINISTRATION.SUCCESS: {
      const {
        response: { entities },
      } = action.payload;

      const { administrations, medicationValidations } = entities;

      return state
        .mergeIn(BY_ID, fromJS(administrations))
        .mergeIn(MEDICATION_VALIDATION_PATH, fromJS(medicationValidations));
    }

    case DELETE_MEDICATION_ADMINISTRATION.SUCCESS: {
      const { administrationId, patientId, validationId } = action.payload;

      const administrationIdsByPatientId = state
        .getIn([...BY_PATIENT_ID, patientId])
        .filter((id: string) => id !== administrationId);

      return state
        .removeIn([...BY_ID, administrationId])
        .removeIn([...MEDICATION_VALIDATION_PATH, validationId])
        .setIn([...BY_PATIENT_ID, patientId], administrationIdsByPatientId);
    }

    case VALIDATE_MEDICATION_ADMINISTRATION.SUCCESS: {
      const {
        response: { entities },
      } = action.payload;

      return state.mergeIn(
        MEDICATION_VALIDATION_PATH,
        fromJS(entities.medicationValidations)
      );
    }

    default: {
      return state;
    }
  }
}

const denormalize =
  (medicationValidations: MedicationValidationT) => (administration) => ({
    ...administration,
    medicationValidation:
      medicationValidations[administration.medicationValidation],
  });

export const administrationsSelector = (
  state: any,
  patientId?: string
): MedicationAdministrationT[] | undefined => {
  if (!patientId) return [];

  const isFetching =
    !!state.network.GET_MEDICATION_ADMINISTRATIONS ||
    state.network.GET_MEDICATION_ADMINISTRATIONS === undefined;

  if (isFetching) return undefined;

  const {
    medicationAdministrations: { byId, byPatientId },
    medicationValidations,
  } = state.entities.toJS();

  const administrationIds = byPatientId[patientId];

  if (!administrationIds) return [];

  return administrationIds
    .map((id: string) => byId[id])
    .map(denormalize(medicationValidations));
};
