import { startActivity } from 'store/modules/activity';

import { TreatmentAccessAttachmentT } from './fetchers';

export const ADD_TREATMENT_ACCESS_SITE_ATTACHMENT =
  'ADD_TREATMENT_ACCESS_SITE_ATTACHMENT';
export const EDIT_TREATMENT_ACCESS_SITE_ATTACHMENT =
  'EDIT_TREATMENT_ACCESS_SITE_ATTACHMENT';
export const REMOVE_TREATMENT_ACCESS_SITE_ATTACHMENT =
  'REMOVE_TREATMENT_ACCESS_SITE_ATTACHMENT';

export const CREATE_TREATMENT_ACCESS_SITE_ATTACHMENT_FAILURE =
  'CREATE_TREATMENT_ACCESS_SITE_ATTACHMENT_FAILURE';
export const UPDATE_TREATMENT_ACCESS_SITE_ATTACHMENT_FAILURE =
  'UPDATE_TREATMENT_ACCESS_SITE_ATTACHMENT_FAILURE';

export const startAddAttachmentActivity = () =>
  startActivity(ADD_TREATMENT_ACCESS_SITE_ATTACHMENT);

export const startEditAttachmentActivity = (
  attachment: TreatmentAccessAttachmentT
) => startActivity(EDIT_TREATMENT_ACCESS_SITE_ATTACHMENT, { attachment });

export const startRemoveTreatmentAccessSiteAttachmentActivity = (
  treatmentAccessSiteAttachment: TreatmentAccessAttachmentT
) =>
  startActivity(REMOVE_TREATMENT_ACCESS_SITE_ATTACHMENT, {
    treatmentAccessSiteAttachment,
  });
