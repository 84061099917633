import { startActivity } from 'store/modules/activity';

export const ADD_PATIENT_COMPLICATION = 'ADD_PATIENT_COMPLICATION';
export const EDIT_PATIENT_COMPLICATION = 'EDIT_PATIENT_COMPLICATION';
export const REMOVE_COMPLICATION = 'REMOVE_COMPLICATION';

export const CREATE_PATIENT_COMPLICATION_FAILURE =
  'CREATE_PATIENT_COMPLICATION_FAILURE';
export const UPDATE_PATIENT_COMPLICATION_FAILURE =
  'UPDATE_PATIENT_COMPLICATION_FAILURE';
export const DELETE_PATIENT_COMPLICATION_FAILURE =
  'DELETE_PATIENT_COMPLICATION_FAILURE';

export const startAddPatientComplicationActivity = () =>
  startActivity(ADD_PATIENT_COMPLICATION);

export const startEditPatientComplicationActivity = (complication) =>
  startActivity(EDIT_PATIENT_COMPLICATION, { complication });

export const startRemovePatientComplicationActivity = (complication) =>
  startActivity(REMOVE_COMPLICATION, { complication });
