import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const PAGE_SIZE = 30;

const codeSystemSchema = new schema.Entity(
  'codeSystems',
  {},
  {
    processStrategy: schemaDeserializers({
      publishedAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

export const codeSchema = new schema.Entity(
  'codes',
  {},
  {
    processStrategy: schemaDeserializers({
      updatedAt: parseDateTime,
    }),
  }
);

export const GET_CODE_SYSTEMS = networkActionTypes('GET_CODE_SYSTEMS');

export const getCodeSystems = (): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_CODE_SYSTEMS,
    url: 'code_systems',
    method: 'GET',
    normalizeSchema: { codeSystems: [codeSystemSchema] },
  },
});

export const GET_CODES_FOR_CODE_SYSTEM = networkActionTypes(
  'GET_CODES_FOR_CODE_SYSTEM'
);

export const getCodesByCodeSystem = (
  page: number,
  codeSystemSlug: string,
  query: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_CODES_FOR_CODE_SYSTEM,
    url: `code_systems/${codeSystemSlug}/codes`,
    method: 'GET',
    params: {
      page,
      pageSize: PAGE_SIZE,
      query,
    },
    normalizeSchema: { data: [codeSchema] },
    actionPayload: { codeSystemSlug },
  },
});

export const CREATE_CODE_SYSTEM = networkActionTypes('CREATE_CODE_SYSTEM');

export const createCodeSystem = (codeSystem: {
  name: string;
  title: string;
  uri: string;
  version: string;
  enforceUniqueUsage: boolean;
  allowMultiple: boolean;
  publishedAt: Date;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_CODE_SYSTEM,
    url: 'code_systems',
    method: 'POST',
    payload: { codeSystem },
    normalizeSchema: { codeSystem: codeSystemSchema },
  },
});

export const CREATE_CODE_FOR_CODE_SYSTEM = networkActionTypes(
  'CREATE_CODE_FOR_CODE_SYSTEM'
);

export const createCodeByCodeSystem = (
  code: {
    code: string;
    description: string;
  },
  codeSystemSlug: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_CODE_FOR_CODE_SYSTEM,
    url: `code_systems/${codeSystemSlug}/codes`,
    method: 'POST',
    payload: { code },
    normalizeSchema: { code: codeSchema },
    actionPayload: { codeSystemSlug },
  },
});

export const UPDATE_CODE_SYSTEM = networkActionTypes('UPDATE_CODE_SYSTEM');

export const updateCodeSystem = (codeSystem: {
  id: string;
  slug: string;
  name: string;
  title: string;
  uri: string;
  version: string;
  enforceUniqueUsage: boolean;
  allowMultiple: boolean;
  publishedAt: Date;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_CODE_SYSTEM,
    url: `code_systems/${codeSystem.slug}`,
    method: 'PUT',
    payload: { codeSystem },
    normalizeSchema: { codeSystem: codeSystemSchema },
  },
});

export const SEARCH_CODE_SYSTEM_CODES = networkActionTypes(
  'SEARCH_CODE_SYSTEM_CODES'
);

export type SearchCodeSystemCodesArgsT = {
  codeSystemSlug: string;
  query: string;
};

export const searchCodeSystemCodes = ({
  codeSystemSlug,
  query,
}: SearchCodeSystemCodesArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: SEARCH_CODE_SYSTEM_CODES,
    url: `code_systems/${codeSystemSlug}/codes/search`,
    method: 'GET',
    params: {
      query,
    },
    normalizeSchema: { codes: [codeSchema] },
  },
});

export const UPDATE_CODE = networkActionTypes('UPDATE_CODE');

export const updateCode = (
  code: {
    code: string;
    description: string;
  },
  id: string,
  codeSystemSlug: string | null | undefined
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_CODE,
    url: `codes/${id}`,
    method: 'PUT',
    actionPayload: { codeSystemSlug },
    payload: { code },
    normalizeSchema: { code: codeSchema },
  },
});

export const DELETE_CODE_SYSTEM = networkActionTypes('DELETE_CODE_SYSTEM');

export const deleteCodeSystem = (slug: string, id: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_CODE_SYSTEM,
    url: `code_systems/${slug}`,
    method: 'DELETE',
    actionPayload: { id },
  },
});

export const DELETE_CODE = networkActionTypes('DELETE_CODE');

export const deleteCode = (
  id: string,
  codeSystemSlug?: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_CODE,
    url: `codes/${id}`,
    method: 'DELETE',
    actionPayload: { id, codeSystemSlug },
  },
});
