import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { patientConditionSchema } from 'store/modules/entities/actions/conditions/patient';
import { tagSchema } from 'store/modules/entities/actions/tags';
import { networkActionTypes } from 'store/utils';

export const journalCommentSchema = new schema.Entity(
  'comments',
  {},
  {
    processStrategy: (comment) => ({
      ...comment,
      ...schemaDeserializers({
        createdAt: parseDateTime,
        updatedAt: parseDateTime,
      })(comment),
      author: {
        ...comment.author,
        ...schemaDeserializers({
          createdAt: parseDateTime,
          updatedAt: parseDateTime,
        })(comment.author),
      },
    }),
  }
);

const journalEntrySchema = new schema.Entity(
  'journalEntries',
  {
    comments: [journalCommentSchema],
    tags: [tagSchema],
    conditions: [patientConditionSchema],
  },
  {
    processStrategy: (entry) => ({
      ...entry,
      ...schemaDeserializers({
        promotedAt: parseDateTime,
        createdAt: parseDateTime,
      })(entry),
      author: {
        ...entry.author,
        ...schemaDeserializers({
          createdAt: parseDateTime,
          updatedAt: parseDateTime,
        })(entry.author),
      },
    }),
  }
);

export const GET_JOURNAL_ENTRIES = networkActionTypes('GET_JOURNAL_ENTRIES');

const PAGE_SIZE = 30;

type GetJournalEntriesArgsT = {
  patientId: string;
  journal: string;
  period: string;
  categories: string[];
  tags: string[];
  conditions: string[];
  sortOrder: string;
  page: number;
  loadMore: boolean;
};

export const getJournalEntries = ({
  patientId,
  journal,
  period,
  categories,
  tags,
  conditions,
  sortOrder,
  page = 0,
  loadMore,
}: GetJournalEntriesArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_JOURNAL_ENTRIES,
    url: `patients/${patientId}/journal_entries`,
    method: 'GET',
    params: {
      patientId,
      journal,
      period,
      categories,
      conditions,
      tags,
      page,
      pageSize: PAGE_SIZE,
      sortOrder,
    },
    actionPayload: { patientId, loadMore },
    normalizeSchema: {
      data: [journalEntrySchema],
    },
  },
});

export const CREATE_JOURNAL_ENTRY = networkActionTypes('CREATE_JOURNAL_ENTRY');

interface CreateJournalEntryArgsT {
  patientId: string;
  types: string[];
  title: string;
  note: string;
  tags: string[];
  conditions: string[];
}

export const createJournalEntry = ({
  patientId,
  title,
  note,
  types,
  tags,
  conditions,
}: CreateJournalEntryArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_JOURNAL_ENTRY,
    url: `patients/${patientId}/journal_threads`,
    method: 'POST',
    payload: {
      body: note,
      journals: types,
      title,
      tags,
      conditions,
    },
    actionPayload: { patientId },
    normalizeSchema: { 'journal/entry': journalEntrySchema },
  },
});

export const CREATE_JOURNAL_COMMENT = networkActionTypes(
  'CREATE_JOURNAL_COMMENT'
);

type CreateJournalCommentArgsT = {
  journalEntryId: string;
  content: string;
};

export const createJournalComment = ({
  journalEntryId,
  content,
}: CreateJournalCommentArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_JOURNAL_COMMENT,
    url: `journal_entries/${journalEntryId}/comments`,
    method: 'POST',
    payload: { comment: { content }, journalEntryId },
    normalizeSchema: {
      'journal/comment': journalCommentSchema,
    },
  },
});

export const UPDATE_JOURNAL_COMMENT = networkActionTypes(
  'UPDATE_JOURNAL_COMMENT'
);

export const updateJournalComment = (
  comment: JournalCommentT
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_JOURNAL_COMMENT,
    url: `comments/${comment.id}`,
    method: 'PUT',
    payload: { comment: { content: comment.content } },
    normalizeSchema: {
      comment: journalCommentSchema,
    },
  },
});

export const UPDATE_JOURNAL_ENTRY = networkActionTypes('UPDATE_JOURNAL_ENTRY');

type UpdateJournalEntryArgsT = {
  title?: string;
  tags?: string[];
  conditions?: string[];
  types: string[];
};

export const updateJournalEntry = (
  journalEntryId: string,
  entry: UpdateJournalEntryArgsT
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_JOURNAL_ENTRY,
    url: `journal_entries/${journalEntryId}`,
    method: 'PUT',
    payload: { entry: { ...entry, journals: entry.types } },
    normalizeSchema: { 'journal/entry': journalEntrySchema },
  },
});

export const PUBLISH_EHR = networkActionTypes('PUBLISH_EHR');

export const publishEhr = (journalEntryId: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: PUBLISH_EHR,
    url: `journal_entries/${journalEntryId}/publish_ehr`,
    method: 'POST',
  },
});

export const DELETE_JOURNAL_ENTRY = networkActionTypes('DELETE_JOURNAL_ENTRY');

export const deleteJournalEntry = (journalEntryId: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_JOURNAL_ENTRY,
    url: `journal_entries/${journalEntryId}`,
    method: 'DELETE',
    payload: { id: journalEntryId },
  },
});
