import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const logbookCategorySchema = new schema.Entity(
  'logbookCategories',
  {},
  { idAttribute: 'value' }
);

const logbookEntrySchema = new schema.Entity(
  'logbookEntries',
  {},
  {
    processStrategy: schemaDeserializers({
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

export const GET_LOGBOOK_CATEGORIES = networkActionTypes(
  'GET_LOGBOOK_CATEGORIES'
);

export const getLogbookCategories = (): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_LOGBOOK_CATEGORIES,
    url: 'logbook_categories',
    method: 'GET',
    normalizeSchema: { 'logbook/categories': [logbookCategorySchema] },
  },
});

export const GET_LOGBOOK_ENTRIES = networkActionTypes('GET_LOGBOOK_ENTRIES');

export type GetLogbookEntriesArgsT = {
  sessionId: string;
  categories: string[];
  page: number;
};

const PAGE_SIZE = 30;

export const getLogbookEntries = ({
  sessionId,
  categories,
  page = 0,
}: GetLogbookEntriesArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_LOGBOOK_ENTRIES,
    url: `sessions/${sessionId}/logbook_entries`,
    method: 'GET',
    params: { sessionId, categories, page, pageSize: PAGE_SIZE },
    normalizeSchema: { data: [logbookEntrySchema] },
  },
});

export const PROMOTE_LOGBOOK_ENTRY = networkActionTypes(
  'PROMOTE_LOGBOOK_ENTRY'
);

type PromoteLogbookEntryArgsT = {
  patientId: string;
  logbookEntryId: string;
  journalTypes: string[];
  note: string | null;
  tags: string[] | null;
};

export const promoteLogbookEntry = ({
  patientId,
  logbookEntryId,
  journalTypes,
  note,
  tags,
}: PromoteLogbookEntryArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: PROMOTE_LOGBOOK_ENTRY,
    url: `patients/${patientId}/logbook_promotions`,
    method: 'POST',
    payload: { logbookEntryId, journals: journalTypes, note, tags },
  },
});
