import { DeviceT } from 'api/fetchers/types';

import { startActivity } from 'store/modules/activity';

export const ADD_DEVICE = 'ADD_DEVICE';
export const EDIT_DEVICE = 'EDIT_DEVICE';
export const REMOVE_DEVICE = 'REMOVE_DEVICE';

export const CREATE_DEVICE_FAILURE = 'CREATE_DEVICE_FAILURE';
export const UPDATE_DEVICE_FAILURE = 'UPDATE_DEVICE_FAILURE';

export const addDeviceActivity = () => startActivity(ADD_DEVICE);

export const editDeviceActivity = (device: DeviceT) =>
  startActivity(EDIT_DEVICE, { device });

export const removeDeviceActivity = (device: DeviceT) =>
  startActivity(REMOVE_DEVICE, { device });
