import { startActivity } from 'store/modules/activity';

import { UltrafiltrationVolumeAdjustmentT } from './fetchers';

export const ADD_ULTRAFILTRATION_VOLUME_ADJUSTMENT =
  'ADD_ULTRAFILTRATION_VOLUME_ADJUSTMENT';
export const EDIT_ULTRAFILTRATION_VOLUME_ADJUSTMENT =
  'EDIT_ULTRAFILTRATION_VOLUME_ADJUSTMENT';
export const REMOVE_ULTRAFILTRATION_VOLUME_ADJUSTMENT =
  'REMOVE_ULTRAFILTRATION_VOLUME_ADJUSTMENT';

export const CREATE_ULTRAFILTRATION_VOLUME_ADJUSTMENT_FAILURE =
  'CREATE_ULTRAFILTRATION_VOLUME_ADJUSTMENT_FAILURE';
export const UPDATE_ULTRAFILTRATION_VOLUME_ADJUSTMENT_FAILURE =
  'UPDATE_ULTRAFILTRATION_VOLUME_ADJUSTMENT_FAILURE';

export const addUltrafiltrationVolumeAdjustmentActivity = () =>
  startActivity(ADD_ULTRAFILTRATION_VOLUME_ADJUSTMENT);

export const editUltrafiltrationVolumeAdjustmentActivity = (
  ultrafiltrationVolumeAdjustment: UltrafiltrationVolumeAdjustmentT
) =>
  startActivity(EDIT_ULTRAFILTRATION_VOLUME_ADJUSTMENT, {
    ultrafiltrationVolumeAdjustment,
  });

export const removeUltrafiltrationVolumeAdjustmentActivity = (
  ultrafiltrationVolumeAdjustment: UltrafiltrationVolumeAdjustmentT
) =>
  startActivity(REMOVE_ULTRAFILTRATION_VOLUME_ADJUSTMENT, {
    ultrafiltrationVolumeAdjustment,
  });
