import { fromJS } from 'immutable';

import {
  CREATE_PATIENT_ADDRESS,
  DELETE_PATIENT_ADDRESS,
  UPDATE_PATIENT_ADDRESS,
} from 'store/modules/entities/actions/patients';

export default function patientAddressesReducer(state: any, action: any) {
  switch (action.type) {
    case CREATE_PATIENT_ADDRESS.SUCCESS: {
      const {
        payload: { request, response },
      } = action;

      const { patientId } = request;

      const address = fromJS(response);

      const addresses = state
        .getIn(['patients', patientId, 'addresses'])
        .unshift(address);

      return state.setIn(['patients', patientId, 'addresses'], addresses);
    }

    case UPDATE_PATIENT_ADDRESS.SUCCESS: {
      const {
        payload: { request, response },
      } = action;

      const { patientId } = request;

      const address = fromJS(response);

      const addresses = state
        .getIn(['patients', patientId, 'addresses'])
        .map((e) => (e.get('id') === address.get('id') ? address : e));

      return state.setIn(['patients', patientId, 'addresses'], addresses);
    }

    case DELETE_PATIENT_ADDRESS.SUCCESS: {
      const {
        payload: { request },
      } = action;

      const { id, patientId } = request;

      const addresses = state
        .getIn(['patients', patientId, 'addresses'])
        .filter((address) => address.get('id') !== id);

      return state.setIn(['patients', patientId, 'addresses'], addresses);
    }

    default: {
      return state;
    }
  }
}
