import { startActivity } from 'store/modules/activity';

export const ADD_TREATMENT_ACCESS_SITE_EXAMINATION =
  'ADD_TREATMENT_ACCESS_SITE_EXAMINATION';

export const CREATE_TREATMENT_ACCESS_EXAMINATION_FAILURE =
  'CREATE_TREATMENT_ACCESS_EXAMINATION_FAILURE';
export const CREATE_TREATMENT_ACCESS_CATHETER_EXAMINATION_FAILURE =
  'CREATE_TREATMENT_ACCESS_CATHETER_EXAMINATION_FAILURE';

export const startAddSiteExaminationActivity = () =>
  startActivity(ADD_TREATMENT_ACCESS_SITE_EXAMINATION);
