import { createLogger } from 'redux-logger';

import { stateSerializer } from 'store/utils';

export default function createLoggerMiddleware() {
  const logger = createLogger({
    level: 'info',
    collapsed: true,
    stateTransformer: stateSerializer,
  });

  return logger;
}
