import { startActivity } from 'store/modules/activity';

export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';
export const REMOVE_USER = 'REMOVE_USER';

export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const REACTIVATE_USER_FAILURE = 'REACTIVATE_USER_FAILURE';

export const UPDATE_USER_PASSWORD_FAILURE = 'UPDATE_USER_PASSWORD_FAILURE';

export const EDIT_USER_PASSWORD = 'EDIT_USER_PASSWORD';

export const startAddUserActivity = () => startActivity(ADD_USER);

export const startEditUserActivity = (userId: string) =>
  startActivity(EDIT_USER, { userId });

export const startRemoveUserActivity = (user: UserT) =>
  startActivity(REMOVE_USER, { user });

export const startEditUserPasswordActivity = (userId: string) =>
  startActivity(EDIT_USER_PASSWORD, { userId });
