import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const patientIdentifierSchema = new schema.Entity(
  'patientIdentifiers',
  {},
  {
    idAttribute: 'identifierTypeAssignmentId',
    processStrategy: schemaDeserializers({
      updatedAt: parseDateTime,
    }),
  }
);

export const GET_PATIENT_IDENTIFIERS = networkActionTypes(
  'GET_PATIENT_IDENTIFIERS'
);

export const getPatientIdentifiers = (patientId: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_PATIENT_IDENTIFIERS,
    url: `patients/${patientId}/person_identifiers`,
    method: 'GET',
    normalizeSchema: { personIdentifiers: [patientIdentifierSchema] },
    actionPayload: { patientId },
  },
});

export const UPDATE_PATIENT_IDENTIFIER = networkActionTypes(
  'UPDATE_PATIENT_IDENTIFIER'
);

type UpdatePatientIdentifierArgsT = {
  id: string;
  patientId: string;
  value: string | null | undefined;
};

export const updatePatientIdentifier =
  ({
    id,
    patientId,
    value,
  }: UpdatePatientIdentifierArgsT): ThunkNetworkActionT =>
  (dispatch) =>
    dispatch({
      type: 'CALL_API',
      payload: {
        types: UPDATE_PATIENT_IDENTIFIER,
        url: `patients/${patientId}/person_identifiers/${id}`,
        method: 'PUT',
        payload: {
          personIdentifier: {
            value: value || null,
          },
        },
        actionPayload: { patientId },
        normalizeSchema: { personIdentifier: patientIdentifierSchema },
      },
    });

export const CREATE_PATIENT_IDENTIFIER = networkActionTypes(
  'CREATE_PATIENT_IDENTIFIER'
);

type CreatePatientIdentifierArgsT = {
  patientId: string;
  value: string | null | undefined;
  identifierTypeAssignmentId: string;
};

export const createPatientIdentifier =
  ({
    patientId,
    value,
    identifierTypeAssignmentId,
  }: CreatePatientIdentifierArgsT): ThunkNetworkActionT =>
  (dispatch) =>
    dispatch({
      type: 'CALL_API',
      payload: {
        types: CREATE_PATIENT_IDENTIFIER,
        url: `patients/${patientId}/person_identifiers`,
        method: 'POST',
        payload: {
          personIdentifier: {
            value: value || null,
            identifierTypeAssignmentId,
          },
        },
        actionPayload: { patientId },
        normalizeSchema: { personIdentifier: patientIdentifierSchema },
      },
    });
