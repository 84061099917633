import { normalize } from 'normalizr';
import { DefaultRootState } from 'react-redux';
import { Action } from 'redux';

import { medicinalProductItemSchema } from 'store/modules/entities/actions/medicationManagement/products';
import {
  notEnrolledPatientSchema,
  patientSchema,
} from 'store/modules/entities/actions/patients';
import { networkActionTypes } from 'store/utils';

// Actions
//==============================================================================

export const RESET_SEARCH_TERM = 'RESET_SEARCH_TERM';
export const SEARCH_NOT_ENROLLED_PATIENTS = networkActionTypes(
  'SEARCH_NOT_ENROLLED_PATIENTS'
);
export const SEARCH_ENROLLED_PATIENTS = networkActionTypes(
  'SEARCH_ENROLLED_PATIENTS'
);
export const SEARCH_PRODUCTS = networkActionTypes('SEARCH_PRODUCTS');

// Action Creators
//==============================================================================

export function resetSearchTerm(resource: any) {
  return {
    type: RESET_SEARCH_TERM,
    payload: { resource },
  };
}

export function searchNotEnrolledPatients({
  query,
  searchScope = 'not_enrolled',
}: {
  query: string;
  searchScope: string;
}): NetworkActionT {
  return {
    type: 'CALL_API',
    payload: {
      types: SEARCH_NOT_ENROLLED_PATIENTS,
      url: 'patients/search',
      method: 'GET',
      params: {
        query,
        searchScope,
      },
      normalizeSchema: { data: [notEnrolledPatientSchema] },
    },
  };
}

export function searchEnrolledPatients(
  query: string,
  searchScope?: string,
  page?: number | null | undefined
): NetworkActionT {
  return {
    type: 'CALL_API',
    payload: {
      types: SEARCH_ENROLLED_PATIENTS,
      url: 'patients/search',
      method: 'GET',
      params: {
        query,
        searchScope,
        page,
      },
      normalizeSchema: { data: [patientSchema] },
    },
  };
}

export function searchProducts({
  name,
  ambulatory,
  ambulatoryPrescribable,
  intradialytic,
  intradialyticPrescribable,
  virtual,
}: {
  name?: string;
  ambulatory?: boolean;
  ambulatoryPrescribable?: boolean;
  intradialytic?: boolean;
  intradialyticPrescribable?: boolean;
  virtual?: boolean;
}): NetworkActionT {
  return {
    type: 'CALL_API',
    payload: {
      types: SEARCH_PRODUCTS,
      url: 'medications/products/search',
      method: 'GET',
      params: {
        name,
        intradialytic,
        intradialyticPrescribable,
        ambulatory,
        ambulatoryPrescribable,
        virtual,
      },
      normalizeSchema: { products: [medicinalProductItemSchema] },
    },
  };
}

export const SEARCH_IMPORTED_PRODUCT = 'SEARCH_IMPORTED_PRODUCT';

export const searchImportedProduct = (
  name: string,
  product: any,
  path: string
) => ({
  type: SEARCH_IMPORTED_PRODUCT,
  payload: {
    path,
    request: { name },
    response: normalize(
      { products: [product] },
      { products: [medicinalProductItemSchema] }
    ),
  },
});

// Reducer
//==============================================================================

interface SearchState {
  [key: string]: {
    lastSearchTerm: string;
    results: Record<string, string[]>;
  };
}

interface SearchAction extends Action<string> {
  payload: {
    resource?: string;
    searchIdentifier?: string;
    request?: any;
    response?: any;
  };
}

export const initialState = {};

export default function searchReducer(
  state: SearchState = initialState,
  action: SearchAction
): SearchState {
  switch (action.type) {
    case RESET_SEARCH_TERM: {
      if (!action.payload.resource) return state;

      return {
        ...state,
        [action.payload.resource]: { lastSearchTerm: '', results: {} },
      };
    }

    case SEARCH_NOT_ENROLLED_PATIENTS.SUCCESS: {
      const { request, response } = action.payload;
      const { query } = request;
      const result = response.result.data || response.result;
      const results = {
        ...state.notEnrolledPatients?.results,
        [query]: result,
      };

      return {
        ...state,
        notEnrolledPatients: { lastSearchTerm: query, results },
      };
    }

    case SEARCH_ENROLLED_PATIENTS.SUCCESS: {
      const { request, response } = action.payload;
      const { query } = request;
      const result = response.result.data || response.result;
      const results = { ...state.patients?.results, [query]: result };

      return {
        ...state,
        patients: { lastSearchTerm: query, results },
      };
    }

    case SEARCH_IMPORTED_PRODUCT:
    case SEARCH_PRODUCTS.SUCCESS: {
      const {
        payload: {
          request: { name },
          response,
        },
      } = action;

      const result = response.result.products;
      const results = { ...state.products?.results, [name]: result };

      return { ...state, products: { lastSearchTerm: name, results } };
    }

    default: {
      return state;
    }
  }
}

// Selectors
//==============================================================================

export function searchIsFetchingSelector(state: DefaultRootState) {
  return (
    Object.keys(state.network).filter(
      (k) => k.includes('SEARCH') && state.network[k]
    ).length > 0
  );
}
