export const sortInlineMedications = (medications: any[]): any[] =>
  medications.sort((a, b) => {
    const nameA = a.productName
      ? a?.productName?.toUpperCase()
      : a?.formula?.toUpperCase();
    const nameB = b.productName
      ? b?.productName?.toUpperCase()
      : b?.formula?.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
