import { startActivity } from 'store/modules/activity';

import { ObservationRegistry } from './fetchers';

export const ADD_LAB_PARAMETER = 'ADD_LAB_PARAMETER';
export const EDIT_LAB_PARAMETER = 'EDIT_LAB_PARAMETER';

export const CREATE_LAB_PARAMETER_FAILURE = 'CREATE_LAB_PARAMETER_FAILURE';
export const UPDATE_LAB_PARAMETER_FAILURE = 'UPDATE_LAB_PARAMETER_FAILURE';
export const DELETE_LAB_PARAMETER_FAILURE = 'DELETE_LAB_PARAMETER_FAILURE';

export const startAddLabParameterActivity = () =>
  startActivity(ADD_LAB_PARAMETER);

export const startEditLabParameterActivity = () =>
  startActivity(EDIT_LAB_PARAMETER);

export const LINK_OBSERVATION_REGISTRY = 'LINK_OBSERVATION_REGISTRY';
export const UNLINK_OBSERVATION_REGISTRY = 'UNLINK_OBSERVATION_REGISTRY';

export const LINK_OBSERVATION_REGISTRY_FAILURE =
  'LINK_OBSERVATION_REGISTRY_FAILURE';
export const SUGGEST_OBSERVATION_REGISTRY_FAILURE =
  'SUGGEST_OBSERVATION_REGISTRY_FAILURE';

export const startLinkObservationRegistryActivity = () =>
  startActivity(LINK_OBSERVATION_REGISTRY);

export const startUnlinkObservationRegistryActivity = (
  observationRegistry: ObservationRegistry
) => startActivity(UNLINK_OBSERVATION_REGISTRY, { observationRegistry });
