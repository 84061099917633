import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const interruptionSchema = new schema.Entity(
  'medicationInterruptions',
  {},
  {
    processStrategy: schemaDeserializers({
      startDate: parseDateTime,
      endDate: parseDateTime,
      createdAt: parseDateTime,
      updatedAT: parseDateTime,
    }),
  }
);

export const GET_MEDICATION_INTERRUPTIONS = networkActionTypes(
  'GET_MEDICATION_INTERRUPTIONS'
);

export const getMedicationInterruptions = ({
  patientId,
  status,
}: {
  patientId: string;
  status: string[];
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_MEDICATION_INTERRUPTIONS,
    url: `patients/${patientId}/medication_schedule_interruptions`,
    method: 'GET',
    actionPayload: { patientId },
    params: { status },
    normalizeSchema: {
      medicationScheduleInterruptions: [interruptionSchema],
    },
  },
});

export const CREATE_MEDICATION_INTERRUPTION = networkActionTypes(
  'CREATE_MEDICATION_INTERRUPTION'
);

export const createMedicationInterruption = ({
  patientId,
  medicationPrescriptionIds,
  startDate,
  endDate,
  note,
}: {
  patientId: string;
  medicationPrescriptionIds: string[];
  startDate: string;
  endDate: string;
  note?: string;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_MEDICATION_INTERRUPTION,
    url: `patients/${patientId}/medication_schedule_interruptions`,
    method: 'POST',
    payload: {
      medicationScheduleInterruption: {
        medicationPrescriptionIds,
        startDate,
        endDate,
        note,
      },
    },
    actionPayload: { patientId },
    normalizeSchema: {
      medicationScheduleInterruption: interruptionSchema,
    },
  },
});

export const DELETE_MEDICATION_INTERRUPTION = networkActionTypes(
  'DELETE_MEDICATION_INTERRUPTION'
);

export const deleteMedicationInterruption = (
  id: string,
  patientId: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_MEDICATION_INTERRUPTION,
    url: `patients/${patientId}/medication_schedule_interruptions/${id}`,
    method: 'DELETE',
    actionPayload: { id, patientId },
  },
});

export const UPDATE_MEDICATION_INTERRUPTION = networkActionTypes(
  'UPDATE_MEDICATION_INTERRUPTION'
);

export const updateMedicationInterruption = ({
  id,
  patientId,
  medicationPrescriptionIds,
  startDate,
  endDate,
  note,
}: {
  id: string;
  patientId: string;
  medicationPrescriptionIds: IntradialyticMedicationT[];
  startDate: string;
  endDate: string;
  note?: string;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_MEDICATION_INTERRUPTION,
    url: `patients/${patientId}/medication_schedule_interruptions/${id}`,
    method: 'PUT',
    payload: {
      medication_schedule_interruption: {
        medicationPrescriptionIds,
        startDate,
        endDate,
        note,
      },
    },
    normalizeSchema: {
      medicationScheduleInterruption: interruptionSchema,
    },
  },
});

export const GET_MEDICATION_INTERRUPTION_HISTORY = networkActionTypes(
  'GET_MEDICATION_INTERRUPTION_HISTORY'
);

export const getMedicationInterruptionHistory = (
  id: string,
  patientId: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_MEDICATION_INTERRUPTION_HISTORY,
    url: `patients/${patientId}/medication_schedule_interruptions/${id}/history`,
    method: 'GET',
    actionPayload: { interruptionId: id },
    normalizeSchema: {
      medicationScheduleInterruptions: [interruptionSchema],
    },
  },
});
