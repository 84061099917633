import { Action } from 'redux';

import {
  CANCEL_ACTION_BAR_ACTIVITY,
  START_ACTIVITY,
} from 'store/modules/activity';
import { LOCATION_CHANGE } from 'store/modules/router';

import getType from 'utils/getType';

// Actions
//==============================================================================

export const RESET_SUCCESS = 'RESET_SUCCES';

// Action Creators
//==============================================================================

export function resetSuccess() {
  return { type: RESET_SUCCESS };
}

// Reducer
//==============================================================================

interface SuccessState {
  type?: string;
  [key: string]: any;
}

interface SuccessAction extends Action<string> {
  payload: any;
}

export const initialState = {};

function setResourceSuccess(resource: string, newId: string) {
  return (state: SuccessState, action: SuccessAction) => {
    const {
      type,
      payload: { request, response },
    } = action;

    if (!request && !response) return state;

    let id;

    if (!id && request) id = request.id;
    if (!id && response) id = response[newId];

    return { type: getType(type), [resource]: { id } };
  };
}

function setGenericSuccess(state: SuccessState, { type }: SuccessAction) {
  return { type: getType(type) };
}

export default function successReducer(
  state: SuccessState = initialState,
  action: SuccessAction
): SuccessState {
  const { type } = action;

  switch (true) {
    case type === 'CREATE_AMBULATORY_PRESCRIPTION_SUCCESS' ||
      type === 'COPY_AMBULATORY_PRESCRIPTION_SUCCESS': {
      const {
        type,
        payload: { response },
      } = action;

      const prescriptionId = response.result['medications/homeMedicationOrder'];

      return { type: getType(type), prescription: { id: prescriptionId } };
    }

    case type.includes('PRESCRIPTION') && type.endsWith('SUCCESS'): {
      return setGenericSuccess(state, action);
    }

    case type.includes('ALLERGY') && type.endsWith('SUCCESS'): {
      const reducer = setResourceSuccess('allergy', 'result');
      return reducer(state, action);
    }

    case type.includes('REMARK') && type.endsWith('SUCCESS'): {
      const reducer = setResourceSuccess('remark', 'result');
      return reducer(state, action);
    }

    case type.includes('WEIGHT') && type.endsWith('SUCCESS'): {
      const reducer = setResourceSuccess('weight', 'result');
      return reducer(state, action);
    }

    case type.includes('ADDRESS') && type.endsWith('SUCCESS'): {
      const reducer = setResourceSuccess('address', 'id');
      return reducer(state, action);
    }

    case type.includes('CONTACT_INFO') && type.endsWith('SUCCESS'): {
      return setGenericSuccess(state, action);
    }

    case type.includes('CONTACT') && type.endsWith('SUCCESS'): {
      const {
        type,
        payload: { response },
      } = action;

      const id = response?.result?.contact;

      return { type: getType(type), contact: { id } };
    }

    case type === 'CREATE_PATIENT_SUCCESS' ||
      type === 'MERGE_PATIENTS_SUCCESS': {
      const reducer = setResourceSuccess('patient', 'result');
      return reducer(state, action);
    }

    case type === 'ENROLL_EMERGENCY_PATIENT_SUCCESS': {
      const { type, payload } = action;

      return { type: getType(type), payload };
    }

    case type.includes('PATIENT') && type.endsWith('SUCCESS'): {
      return setGenericSuccess(state, action);
    }

    case type.includes('DIET') && type.endsWith('SUCCESS'): {
      return setGenericSuccess(state, action);
    }

    case type.includes('TRANSPLANT') && type.endsWith('SUCCESS'): {
      return setGenericSuccess(state, action);
    }

    case type.includes('JOURNAL_COMMENT_SUCCESS'): {
      return setGenericSuccess(state, action);
    }

    case type.includes('JOURNAL') && type.endsWith('SUCCESS'): {
      const reducer = setResourceSuccess('journalEntry', 'result');
      return reducer(state, action);
    }

    case type.includes('LOGBOOK') && type.endsWith('SUCCESS'): {
      const reducer = setResourceSuccess('logbookEntry', 'result');
      return reducer(state, action);
    }

    case type === 'CREATE_WOUND_SUCCESS': {
      const {
        type,
        payload: { response },
      } = action;

      const woundId = response.result['wounds/wound'];

      return { type: getType(type), woundId };
    }

    case type === 'CREATE_WOUND_EVALUATION_SUCCESS': {
      const {
        type,
        payload: { response },
      } = action;

      const woundEvaluationId = response.result['wounds/woundEvaluation'];

      return { type: getType(type), woundEvaluationId };
    }

    case type.includes('WOUND_PRESCRIPTION') && type.endsWith('SUCCESS'): {
      return setGenericSuccess(state, action);
    }

    case type === 'CREATE_AMBULATORY_PRESCRIPTION_ITEM_SUCCESS': {
      return setGenericSuccess(state, action);
    }

    case type === 'SYNC_BILLING_ITEM_SUCCESS': {
      const {
        type,
        payload: { response },
      } = action;

      const itemId = response.result.billingItem;

      return { type: getType(type), id: itemId };
    }

    case type === 'UPDATE_BILLING_ITEM_SUCCESS': {
      const {
        type,
        payload: { response },
      } = action;

      const itemId = response.result.billingItem;
      const itemIds = response.result.billingItems;

      return {
        type: getType(type),
        id: itemIds?.[0] || itemId,
        counterpartId: itemIds?.[1],
      };
    }

    case type === 'CREATE_MEDICINAL_PRODUCT_SUCCESS': {
      const {
        type,
        payload: { response },
      } = action;

      const id = response?.result?.product;

      return { type: getType(type), product: { id } };
    }

    case type === 'UPDATE_DEVICE_SUCCESS': {
      const {
        type,
        payload: { centerChanged, request },
      } = action;

      return {
        type: getType(type),
        payload: { centerChanged, centerId: request?.device?.centerId },
      };
    }

    case type === LOCATION_CHANGE:
    case type === RESET_SUCCESS:
    case type === CANCEL_ACTION_BAR_ACTIVITY:
    case type === START_ACTIVITY:
    case type.endsWith('FAILURE'): {
      return initialState;
    }

    // Save entire payload for success without handler
    case type.endsWith('SUCCESS'): {
      const { type, payload } = action;

      return { type: getType(type), payload };
    }

    default: {
      return state;
    }
  }
}
