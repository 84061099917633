import {
  CREATE_TAG,
  DELETE_TAG,
  GET_TAGS,
} from 'store/modules/entities/actions/tags';

import deindex from 'utils/deindex';
import sortInline from 'utils/sort';

export default function tagsReducer(state: any, action: any) {
  switch (action.type) {
    case GET_TAGS.SUCCESS: {
      const { response } = action.payload;
      const tags = response.entities['journal/tags'];

      return state.mergeIn(['tags'], tags);
    }

    case CREATE_TAG.SUCCESS: {
      const { response } = action.payload;
      const tags = response.entities['journal/tags'];

      return state.mergeIn(['tags'], tags);
    }

    case DELETE_TAG.SUCCESS: {
      const { name } = action.payload;

      return state.removeIn(['tags', name]);
    }

    default: {
      return state;
    }
  }
}

export const tagsSelector = (state: any): TagT[] => {
  const { tags } = state.entities.toJS();
  return deindex<TagT>(tags).sort(sortInline('title'));
};

export const tagOptionsSelector = (state: any): OptionT[] => {
  const tags = tagsSelector(state);

  return tags
    .map(({ name, title }) => ({ value: name, label: title }))
    .sort(sortInline('label'));
};
