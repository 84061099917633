import { startActivity } from 'store/modules/activity';

import { Configuration } from 'hooks/useConfig';

export const EDIT_CONFIGURATION = 'EDIT_CONFIGURATION';

export const UPDATE_CONFIGURATION_FAILURE = 'UPDATE_CONFIGURATION_FAILURE';

export function startEditConfigurationActivity(configuration: Configuration) {
  return startActivity(EDIT_CONFIGURATION, { configuration });
}
