import { startActivity } from 'store/modules/activity';

import { DeviationT } from './fetchers';

export const ADD_PATIENT_TO_PLANNING = 'ADD_PATIENT_TO_PLANNING';
export const REMOVE_PATIENT_FROM_PLANNING = 'REMOVE_PATIENT_FROM_PLANNING';

export const RESCHEDULE_PATIENT = 'RESCHEDULE_PATIENT';

export const CREATE_PLANNING_ENTRY_WITH_PATIENT_FAILURE =
  'CREATE_PLANNING_ENTRY_WITH_PATIENT_FAILURE';

export const startAddPatientToPlanningActivity = ({
  date,
  positionId,
  shiftId,
  roomId,
}: {
  date: Date;
  positionId: string | undefined;
  shiftId: string;
  roomId: string;
}) =>
  startActivity(ADD_PATIENT_TO_PLANNING, {
    date,
    positionId,
    shiftId,
    roomId,
  });

export const startRemovePatientFromPlanningActivity = ({
  patient,
  positionNumber,
  roomName,
  centerName,
  date,
  positionId,
  shiftId,
}: {
  patient: PatientT;
  positionNumber: number;
  roomName: string;
  centerName: string;
  date: Date;
  positionId: string;
  shiftId: string;
}) =>
  startActivity(REMOVE_PATIENT_FROM_PLANNING, {
    patient,
    positionNumber,
    roomName,
    centerName,
    date,
    positionId,
    shiftId,
  });

export function reschedulePatientInPlanning(deviation: DeviationT) {
  return startActivity(RESCHEDULE_PATIENT, { deviation });
}
