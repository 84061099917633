import 'core-js/stable';
import 'regenerator-runtime/runtime';

// eslint-disable-next-line local/sort-imports
import { StrictMode } from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';

import Root from 'views';

import { version } from '../package.json';
import loadFonts from './assets/fonts/fontLoader';
import './styles/application.css';

loadFonts();

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: version,
    environment: process.env.NODE_ENV,
  });
}

ReactDOM.render(
  <StrictMode>
    <Root />
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
