import { schema } from 'normalizr';

import { networkActionTypes } from 'store/utils';

const medicinalImportItemSchema = new schema.Entity(
  'medicinalImportItems',
  {},
  { idAttribute: 'identifier' }
);

// Medication store import
// =======================================================

export const SEARCH_MEDICINAL_PRODUCT_PROVIDERS = networkActionTypes(
  'SEARCH_MEDICINAL_PRODUCT_PROVIDERS'
);

export const searchMedicinalProductProviders = (
  query: string,
  queryType: string,
  virtual: boolean | null | undefined,
  sources: string[] | null | undefined
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: SEARCH_MEDICINAL_PRODUCT_PROVIDERS,
    url: 'executions',
    method: 'POST',
    payload: {
      execution: {
        type: 'medications/query',
        payload: {
          query,
          queryType,
          virtual,
          sources,
        },
      },
    },
  },
});

export const POLL_MEDICINAL_PRODUCT_PROVIDERS = networkActionTypes(
  'POLL_MEDICINAL_PRODUCT_PROVIDERS'
);

export const pollMedicinalProductProviders = (id: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: POLL_MEDICINAL_PRODUCT_PROVIDERS,
    url: `executions/${id}`,
    method: 'GET',
    normalizeSchema: { execution: { results: [medicinalImportItemSchema] } },
  },
});

export const IMPORT_MEDICINAL_PRODUCT_ITEMS = networkActionTypes(
  'IMPORT_MEDICINAL_PRODUCT_ITEMS'
);

export const importMedicinalProductItems = (
  data: { identifier: string; sourceId: string }[]
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: IMPORT_MEDICINAL_PRODUCT_ITEMS,
    url: 'executions',
    method: 'POST',
    payload: {
      execution: {
        type: 'medications/import',
        payload: {
          identifiers: data,
        },
      },
    },
    actionPayload: { data },
  },
});

export const POLL_MEDICINAL_PRODUCT_IMPORT = networkActionTypes(
  'POLL_MEDICINAL_PRODUCT_IMPORT'
);

export const pollMedicinalProductItems = (id: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: POLL_MEDICINAL_PRODUCT_IMPORT,
    url: `executions/${id}`,
    method: 'GET',
  },
});

export const CLEAR_MEDICINAL_PRODUCT_ITEMS = 'CLEAR_MEDICINAL_PRODUCT_ITEMS';

export const clearMedicinalProductItems = () => ({
  type: CLEAR_MEDICINAL_PRODUCT_ITEMS,
});

export const RESET_MEDICATION_IMPORT_ERRORS = 'RESET_MEDICATION_IMPORT_ERRORS';

export const resetErrors = () => ({
  type: RESET_MEDICATION_IMPORT_ERRORS,
});

// Ambulatory search import
// =======================================================

export const SEARCH_AMBULATORY_MEDICINAL_PRODUCT_PROVIDERS = networkActionTypes(
  'SEARCH_AMBULATORY_MEDICINAL_PRODUCT_PROVIDERS'
);

export const searchAmbulatoryMedicinalProductProviders = ({
  query,
  virtual,
}: {
  query: string;
  virtual: boolean | null;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: SEARCH_AMBULATORY_MEDICINAL_PRODUCT_PROVIDERS,
    url: 'medications/ambulatory_products/search/external',
    method: 'POST',
    payload: {
      execution: {
        type: 'medications/query',
        payload: {
          query,
          queryType: 'product',
          virtual,
        },
      },
    },
  },
});

export const POLL_AMBULATORY_PRODUCT_PROVIDERS = networkActionTypes(
  'POLL_AMBULATORY_PRODUCT_PROVIDERS'
);

export const pollAmbulatoryMedicinalProductProviders = (
  executionId: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: POLL_AMBULATORY_PRODUCT_PROVIDERS,
    url: 'medications/ambulatory_products/search/external',
    method: 'GET',
    params: { executionId },
    normalizeSchema: { execution: { results: [medicinalImportItemSchema] } },
  },
});

export const IMPORT_AMBULATORY_MEDICINAL_PRODUCT_FROM_PROVIDER =
  networkActionTypes('IMPORT_AMBULATORY_MEDICINAL_PRODUCT_FROM_PROVIDER');

export type FormatType = 'product' | 'prescription';

export const importAmbulatoryMedicinalProductFromProvider = (
  product: {
    identifier: string;
    sourceId: string;
  },
  format: FormatType
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: IMPORT_AMBULATORY_MEDICINAL_PRODUCT_FROM_PROVIDER,
    url: 'medications/ambulatory_products/import/external',
    method: 'POST',
    payload: {
      execution: product,
      format,
    },
  },
});
