import { startActivity } from 'store/modules/activity';

import { CatheterExtensionT } from './fetchers';

export const ADD_CATHETER_EXTENSION = 'ADD_CATHETER_EXTENSION';
export const EDIT_CATHETER_EXTENSION = 'EDIT_CATHETER_EXTENSION';
export const REMOVE_CATHETER_EXTENSION = 'REMOVE_CATHETER_EXTENSION';

export const CREATE_CATHETER_EXTENSION_FAILURE =
  'CREATE_CATHETER_EXTENSION_FAILURE';
export const UPDATE_CATHETER_EXTENSION_FAILURE =
  'UPDATE_CATHETER_EXTENSION_FAILURE';

export const startAddCatheterExtensionActivity = () =>
  startActivity(ADD_CATHETER_EXTENSION);

export const startEditCatheterExtensionActivity = (
  catheterExtension: CatheterExtensionT
) => startActivity(EDIT_CATHETER_EXTENSION, { catheterExtension });
