import { fromJS } from 'immutable';

import {
  CREATE_CODE_FOR_CODE_SYSTEM,
  CREATE_CODE_SYSTEM,
  DELETE_CODE,
  DELETE_CODE_SYSTEM,
  GET_CODE_SYSTEMS,
  GET_CODES_FOR_CODE_SYSTEM,
  UPDATE_CODE,
  UPDATE_CODE_SYSTEM,
} from 'store/modules/entities/actions/codeSystems';

import deindex from 'utils/deindex';
import sortInline from 'utils/sort';

const CODE_SYSTEMS_PATH = ['codeSystems'];
const CODES_PATH = ['codes'];
const CODES_BY_CODE_SYSTEM_PATH = [...CODES_PATH, 'byCodeSystem'];
const CODES_BY_ID_PATH = [...CODES_PATH, 'byId'];

function mergeCodeSystem(state: any, action: any): any {
  const { response } = action.payload;
  const { codeSystems } = response.entities;

  return state.mergeIn(CODE_SYSTEMS_PATH, fromJS(codeSystems));
}

export default function codeSystemsReducer(state: any, action: any) {
  switch (action.type) {
    case GET_CODE_SYSTEMS.SUCCESS: {
      const {
        response: { entities: codeSystems },
      } = action.payload;

      return state.mergeIn(
        CODE_SYSTEMS_PATH,
        fromJS(codeSystems.codeSystems || {})
      );
    }

    case CREATE_CODE_SYSTEM.SUCCESS: {
      return mergeCodeSystem(state, action);
    }

    case UPDATE_CODE_SYSTEM.SUCCESS: {
      return mergeCodeSystem(state, action);
    }

    case DELETE_CODE_SYSTEM.SUCCESS: {
      const { id } = action.payload;

      return state.deleteIn([...CODE_SYSTEMS_PATH, id]);
    }

    case GET_CODES_FOR_CODE_SYSTEM.SUCCESS: {
      const { codeSystemSlug, response } = action.payload;
      const { codes } = response.entities;
      const { data: codeIds } = response.result;

      return state
        .mergeIn(CODES_BY_ID_PATH, fromJS(codes))
        .setIn([...CODES_BY_CODE_SYSTEM_PATH, codeSystemSlug], codeIds);
    }

    case CREATE_CODE_FOR_CODE_SYSTEM.SUCCESS: {
      const { codeSystemSlug, response } = action.payload;
      const { codes } = response.entities;
      const { code: codeId } = response.result;

      const byCodeSystem = state
        .getIn([...CODES_BY_CODE_SYSTEM_PATH, codeSystemSlug])
        ?.concat([codeId]) || [codeId];

      return state
        .setIn([...CODES_BY_ID_PATH, codeId], fromJS(codes[codeId]))
        .setIn([...CODES_BY_CODE_SYSTEM_PATH, codeSystemSlug], byCodeSystem);
    }

    case UPDATE_CODE.SUCCESS: {
      const { response } = action.payload;
      const { codes } = response.entities;
      const { code: codeId } = response.result;

      return state.setIn([...CODES_BY_ID_PATH, codeId], fromJS(codes[codeId]));
    }

    case DELETE_CODE.SUCCESS: {
      const { id, codeSystemSlug } = action.payload;

      const byCodeSystem =
        state
          .getIn([...CODES_BY_CODE_SYSTEM_PATH, codeSystemSlug])
          ?.filter((codeId) => codeId !== id) || [];

      return state
        .deleteIn([...CODES_BY_ID_PATH, id])
        .setIn([...CODES_BY_CODE_SYSTEM_PATH, codeSystemSlug], byCodeSystem);
    }

    default: {
      return state;
    }
  }
}

export const codeSystemsSelector = (
  state: any
): CodeSystemT[] | null | undefined => {
  const { codeSystems } = state.entities.toJS();

  if (codeSystems === undefined) return undefined;

  return deindex<CodeSystemT>(codeSystems).sort(sortInline('name', 'asc'));
};

export const codesByCodeSystemSelector = (
  state: any,
  codeSystemSlug: string
): CodeT[] | null | undefined => {
  const { byId, byCodeSystem } = state.entities.getIn(CODES_PATH).toJS();

  return byCodeSystem[codeSystemSlug]
    ?.map((id) => byId[id])
    .sort(sortInline('code', 'asc'));
};
