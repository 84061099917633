/**
 * Pass a dispatched NetworkActionT object to this function
 * and it will take care of throwing the action if it is an error response.
 * You can then catch it with `.catch()`.
 * This ensures you get a success response object (NetworkSuccessResponse) passed
 * to you in `.then()` so you don't need to do the error check first.
 *
 * Don't forget tot catch so no unhandled promise rejection error is logged to the console.
 *
 * @template T The type of the success response. Eg: `NormalizedSchema<any, any>` where
 * <any, any> represents `response.entities` and `response.result` respectively.
 */
export default function networkResponseHandler<
  T extends ApiSuccessResponseT<any> = ApiSuccessResponseT<any>
>(action: Promise<ApiResponseT>) {
  return action.then((action) => {
    if (action.error) throw action;
    return action as T;
  });
}
