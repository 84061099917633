import { startActivity } from 'store/modules/activity';

import { LabSystem, ObservationRegistry } from './fetchers';

export const ADD_LAB_SYSTEM = 'ADD_LAB_SYSTEM';
export const REMOVE_LAB_SYSTEM = 'REMOVE_LAB_SYSTEM';
export const LINK_LAB_PARAMETER = 'LINK_LAB_PARAMETER';
export const UNLINK_LAB_PARAMETER = 'UNLINK_LAB_PARAMETER';

export const CREATE_LAB_SYSTEM_FAILURE = 'CREATE_LAB_SYSTEM_FAILURE';
export const DELETE_LAB_SYSTEM_FAILURE = 'DELETE_LAB_SYSTEM_FAILURE';
export const UPDATE_OBSERVATION_REGISTRY_FAILURE =
  'UPDATE_OBSERVATION_REGISTRY_FAILURE';

export const startAddLabSystemActivity = () => startActivity(ADD_LAB_SYSTEM);

export const startRemoveLabSystemActivity = (labSystem: LabSystem) =>
  startActivity(REMOVE_LAB_SYSTEM, { labSystem });

export const startLinkLabParameterActivity = (registry: ObservationRegistry) =>
  startActivity(LINK_LAB_PARAMETER, { registry });

export const startUnlinkLabParameterActivity = (
  registry: ObservationRegistry
) => startActivity(UNLINK_LAB_PARAMETER, { registry });
