import { fromJS, List } from 'immutable';

import {
  CREATE_GLOBAL_PERMISSION,
  CREATE_USER_PERMISSION,
  DELETE_GLOBAL_PERMISSION,
  DELETE_USER_PERMISSION,
  GET_GLOBAL_PERMISSIONS,
  GET_USER_PERMISSIONS,
} from 'store/modules/entities/actions/acls';

const USER_PERMISSION_BY_ID = ['userPermissions', 'byId'];
const USER_PERMISSION_BY_RESULT = ['userPermissions', 'byResult'];

export default function aclsReducer(state: any, action: any) {
  switch (action.type) {
    case GET_GLOBAL_PERMISSIONS.SUCCESS:
    case GET_USER_PERMISSIONS.SUCCESS: {
      const {
        response: {
          entities: { userPermissions },
          result: { data },
        },
      } = action.payload;

      return state
        .setIn(USER_PERMISSION_BY_ID, fromJS(userPermissions || {}))
        .setIn(USER_PERMISSION_BY_RESULT, fromJS(data || []));
    }

    case CREATE_GLOBAL_PERMISSION.SUCCESS: {
      const {
        response: {
          entities: { users },
          result: { user: userId },
        },
      } = action.payload;

      return state
        .mergeIn(USER_PERMISSION_BY_ID, fromJS(users || {}))
        .updateIn(USER_PERMISSION_BY_RESULT, (list = List()) =>
          list.unshift(userId)
        );
    }

    case CREATE_USER_PERMISSION.SUCCESS: {
      return state;
    }

    case DELETE_GLOBAL_PERMISSION.SUCCESS:
    case DELETE_USER_PERMISSION.SUCCESS: {
      const { userId } = action.payload;

      return state
        .removeIn([...USER_PERMISSION_BY_ID, userId])
        .updateIn(USER_PERMISSION_BY_RESULT, (list = List()) =>
          list.filter((id) => id !== userId)
        );
    }

    default: {
      return state;
    }
  }
}

export const userPermissionsSelector = (state: any): UserAclT[] | undefined => {
  const {
    userPermissions: { byId, byResult },
  } = state.entities.toJS();

  const isFetching = !!(
    state.network.GET_GLOBAL_PERMISSIONS || state.network.GET_USER_PERMISSIONS
  );

  if (isFetching || !byId || !byResult) return;

  return byResult.map((userId: string) => byId[userId]).filter(Boolean);
};
