import { startActivity } from 'store/modules/activity';

import { ContextLinkT } from './fetchers';

export const ADD_CONTEXT_LINK = 'ADD_CONTEXT_LINK';
export const EDIT_CONTEXT_LINK = 'EDIT_CONTEXT_LINK';
export const REMOVE_CONTEXT_LINK = 'REMOVE_CONTEXT_LINK';

export const CREATE_CONTEXT_LINK_FAILURE = 'CREATE_CONTEXT_LINK_FAILURE';
export const UPDATE_CONTEXT_LINK_FAILURE = 'UPDATE_CONTEXT_LINK_FAILURE';

export const addContextLinkActivity = () => startActivity(ADD_CONTEXT_LINK);

export const editContextLinkActivity = (contextLink: ContextLinkT) =>
  startActivity(EDIT_CONTEXT_LINK, { contextLink });

export const removeContextLinkActivity = (id: string) =>
  startActivity(REMOVE_CONTEXT_LINK, { contextLink: { id } });
