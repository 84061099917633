import { networkActionTypes } from 'store/utils';

export const REQUEST_ACCOUNT_UNLOCK = networkActionTypes(
  'REQUEST_ACCOUNT_UNLOCK'
);

type RequestAccountUnlockArgsT = {
  email: string;
};

export const requestAccountUnlock = ({
  email,
}: RequestAccountUnlockArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: REQUEST_ACCOUNT_UNLOCK,
    url: 'users/unlock',
    payload: { user: { username: email } },
    method: 'POST',
  },
});

export const UNLOCK_ACCOUNT = networkActionTypes('UNLOCK_ACCOUNT');

type UnlockAccountArgsT = {
  token: string;
};

export const unlockAccount = ({
  token,
}: UnlockAccountArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UNLOCK_ACCOUNT,
    url: 'users/unlock',
    payload: {
      user: { unlockToken: token },
    },
    method: 'PUT',
  },
});

export const REQUEST_PASSWORD_RESET = networkActionTypes(
  'REQUEST_PASSWORD_RESET'
);

type RequestPasswordResetArgsT = {
  email: string;
};

export const requestPasswordReset = ({
  email,
}: RequestPasswordResetArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: REQUEST_PASSWORD_RESET,
    url: 'users/password',
    payload: { user: { username: email } },
    method: 'POST',
  },
});

export const RESET_PASSWORD = networkActionTypes('RESET_PASSWORD');

type ResetPasswordArgsT = {
  token: string;
  password: string;
  passwordConfirmation: string;
};

export const resetPassword = ({
  token,
  password,
  passwordConfirmation,
}: ResetPasswordArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: RESET_PASSWORD,
    url: 'users/password',
    payload: {
      user: { resetPasswordToken: token, password, passwordConfirmation },
    },
    method: 'PUT',
  },
});
