import { startActivity } from 'store/modules/activity';

import { PatientAttachment } from './fetchers';

export const ADD_PATIENT_ATTACHMENT = 'ADD_PATIENT_ATTACHMENT';
export const EDIT_PATIENT_ATTACHMENT = 'EDIT_PATIENT_ATTACHMENT';
export const REMOVE_PATIENT_ATTACHMENT = 'REMOVE_PATIENT_ATTACHMENT';

export const CREATE_PATIENT_ATTACHMENT = 'CREATE_PATIENT_ATTACHMENT';
export const CREATE_PATIENT_ATTACHMENT_FAILURE =
  'CREATE_PATIENT_ATTACHMENT_FAILURE';

export const UPDATE_PATIENT_ATTACHMENT = 'UPDATE_PATIENT_ATTACHMENT';
export const UPDATE_PATIENT_ATTACHMENT_FAILURE =
  'UPDATE_PATIENT_ATTACHMENT_FAILURE';

export function startAddPatientAttachmentActivity() {
  return startActivity(ADD_PATIENT_ATTACHMENT);
}

export function startEditPatientAttachmentActivity(
  patientAttachment: PatientAttachment
) {
  return startActivity(EDIT_PATIENT_ATTACHMENT, { patientAttachment });
}

export function startRemovePatientAttachmentActivity(
  patientAttachment: PatientAttachment
) {
  return startActivity(REMOVE_PATIENT_ATTACHMENT, { patientAttachment });
}
