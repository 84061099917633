import { ENROLL_PATIENT } from 'store/modules/entities/actions/patients';

export default function notEnrolledPatientsReducer(state: any, action: any) {
  switch (action.type) {
    case ENROLL_PATIENT.SUCCESS: {
      const {
        request: {
          enrollment: { patientId },
        },
      } = action.payload;

      return state.deleteIn(['notEnrolledPatients', patientId]);
    }

    default: {
      return state;
    }
  }
}
