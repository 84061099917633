import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const notGivenReasonSchema = new schema.Entity(
  'notGivenReasons',
  {},
  {
    processStrategy: schemaDeserializers({
      updatedAt: parseDateTime,
    }),
  }
);

export const GET_MEDICINAL_NOT_GIVEN_REASONS = networkActionTypes(
  'GET_MEDICINAL_NOT_GIVEN_REASONS'
);

export const getMedicinalNotGivenReasons = (): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_MEDICINAL_NOT_GIVEN_REASONS,
    url: 'medications/not_given_reasons',
    method: 'GET',
    normalizeSchema: { notGivenReasons: [notGivenReasonSchema] },
  },
});

export const CREATE_MEDICINAL_NOT_GIVEN_REASON = networkActionTypes(
  'CREATE_MEDICINAL_NOT_GIVEN_REASON'
);

export const createMedicinalNotGivenReason = (notGivenReason: {
  name: string;
  description: string;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_MEDICINAL_NOT_GIVEN_REASON,
    url: 'medications/not_given_reasons',
    method: 'POST',
    payload: { notGivenReason },
    normalizeSchema: { notGivenReason: notGivenReasonSchema },
  },
});

export const UPDATE_MEDICINAL_NOT_GIVEN_REASON = networkActionTypes(
  'UPDATE_MEDICINAL_NOT_GIVEN_REASON'
);

export const updateMedicinalNotGivenReason = (notGivenReason: {
  id: string;
  name: string;
  description: string;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_MEDICINAL_NOT_GIVEN_REASON,
    url: `medications/not_given_reasons/${notGivenReason.id}`,
    method: 'PUT',
    payload: { notGivenReason },
    normalizeSchema: { notGivenReason: notGivenReasonSchema },
  },
});

export const DELETE_MEDICINAL_NOT_GIVEN_REASON = networkActionTypes(
  'DELETE_MEDICINAL_NOT_GIVEN_REASON'
);

export const deleteMedicinalNotGivenReason = (id: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_MEDICINAL_NOT_GIVEN_REASON,
    url: `medications/not_given_reasons/${id}`,
    method: 'DELETE',
    actionPayload: { id },
  },
});
