import { fromJS } from 'immutable';

import {
  CREATE_PATIENT_IDENTIFIER,
  GET_PATIENT_IDENTIFIERS,
  UPDATE_PATIENT_IDENTIFIER,
} from 'store/modules/entities/actions/patients/identifiers';

import deindex from 'utils/deindex';
import sortInline from 'utils/sort';

const PATH = ['patientIdentifiers'];

export default function patientIdentifiersReducer(state: any, action: any) {
  switch (action.type) {
    case GET_PATIENT_IDENTIFIERS.SUCCESS: {
      const {
        response: {
          entities: { patientIdentifiers },
        },
        patientId,
      } = action.payload;

      return state.setIn([...PATH, patientId], fromJS(patientIdentifiers));
    }

    case CREATE_PATIENT_IDENTIFIER.SUCCESS: {
      const {
        response: {
          entities: { patientIdentifiers },
        },
        patientId,
      } = action.payload;

      return state.mergeIn([...PATH, patientId], fromJS(patientIdentifiers));
    }

    case UPDATE_PATIENT_IDENTIFIER.SUCCESS: {
      const {
        response: {
          entities: { patientIdentifiers },
        },
        patientId,
      } = action.payload;

      return state.mergeIn([...PATH, patientId], fromJS(patientIdentifiers));
    }

    default: {
      return state;
    }
  }
}

export const patientIdentifiersSelector = (
  state: any,
  patientId: string
): PatientIdentifierT[] | null | undefined => {
  const { patientIdentifiers } = state.entities.toJS();

  const isFetching = !!state.network.GET_PATIENT_IDENTIFIERS;

  if (!patientIdentifiers[patientId] || isFetching) return undefined;

  return deindex<PatientIdentifierT>(patientIdentifiers[patientId])
    .filter(Boolean)
    .sort(sortInline(['primary', 'title'], ['desc', 'asc']));
};
