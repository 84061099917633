export const MEDICATION_VALIDATION_TYPE = {
  suggestion: 'suggestion',
  validationRequired: 'validation_required',
  approved: 'approved',
  rejected: 'rejected',
  validationTimeLimitExceeded: 'validation_time_limit_exceeded',
};

export const isSuggestion = (medication: MedicationT): boolean =>
  medication?.medicationValidation?.status ===
  MEDICATION_VALIDATION_TYPE.suggestion;

export const isUnvalidated = (medication: MedicationT): boolean =>
  medication?.medicationValidation?.status ===
    MEDICATION_VALIDATION_TYPE.suggestion ||
  medication?.medicationValidation?.status ===
    MEDICATION_VALIDATION_TYPE.validationRequired;
