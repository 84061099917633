import { startActivity } from 'store/modules/activity';

export const EDIT_PROFILE_DATA = 'EDIT_PROFILE_DATA';
export const EDIT_PROFILE_PASSWORD = 'EDIT_PROFILE_PASSWORD';

export const UPDATE_CURRENT_USER_FAILURE = 'UPDATE_CURRENT_USER_FAILURE';
export const UPDATE_CURRENT_USER_PASSWORD_FAILURE =
  'UPDATE_CURRENT_USER_PASSWORD_FAILURE';

export const startEditProfileDataActivity = () =>
  startActivity(EDIT_PROFILE_DATA);

export const startEditProfilePasswordActivity = () =>
  startActivity(EDIT_PROFILE_PASSWORD);
