import { startActivity } from 'store/modules/activity';

import { ObservationT } from './fetchers';

export const ADD_PATIENT_OBSERVATION = 'ADD_PATIENT_OBSERVATION';
export const EDIT_PATIENT_OBSERVATION = 'EDIT_PATIENT_OBSERVATION';
export const REMOVE_PATIENT_OBSERVATION = 'REMOVE_PATIENT_OBSERVATION';

export const CREATE_PATIENT_OBSERVATION_FAILURE =
  'CREATE_PATIENT_OBSERVATION_FAILURE';
export const UPDATE_PATIENT_OBSERVATION_FAILURE =
  'UPDATE_PATIENT_OBSERVATION_FAILURE';
export const DELETE_PATIENT_OBSERVATION_FAILURE =
  'DELETE_PATIENT_OBSERVATION_FAILURE';

export function startAddObservationActivity() {
  return startActivity(ADD_PATIENT_OBSERVATION);
}

export function startEditObservationActivity(observation: ObservationT) {
  return startActivity(EDIT_PATIENT_OBSERVATION, { observation });
}

export function startRemoveObservationActivity(observation: ObservationT) {
  return startActivity(REMOVE_PATIENT_OBSERVATION, { observation });
}
