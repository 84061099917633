import { startActivity } from 'store/modules/activity';

import { UltrafiltrationProfileT } from 'views/record/settings/clinicalSettings/ultrafiltrationProfiles/fetchers';

export const ADD_ULTRAFILTRATION_PROFILE = 'ADD_ULTRAFILTRATION_PROFILE';
export const EDIT_ULTRAFILTRATION_PROFILE = 'EDIT_ULTRAFILTRATION_PROFILE';
export const REMOVE_ULTRAFILTRATION_PROFILE = 'REMOVE_ULTRAFILTRATION_PROFILE';

export const CREATE_ULTRAFILTRATION_PROFILE_FAILURE =
  'CREATE_ULTRAFILTRATION_PROFILE_FAILURE';
export const UPDATE_ULTRAFILTRATION_PROFILE_FAILURE =
  'UPDATE_ULTRAFILTRATION_PROFILE_FAILURE';
export const DELETE_ULTRAFILTRATION_PROFILE_FAILURE =
  'DELETE_ULTRAFILTRATION_PROFILE_FAILURE';
export const REACTIVATE_ULTRAFILTRATION_PROFILE_FAILURE =
  'REACTIVATE_ULTRAFILTRATION_PROFILE_FAILURE';

export function startAddUltrafiltrationProfileActivity() {
  return startActivity(ADD_ULTRAFILTRATION_PROFILE);
}

export function startEditUltrafiltrationProfileActivity(
  ultrafiltrationProfile: UltrafiltrationProfileT
) {
  return startActivity(EDIT_ULTRAFILTRATION_PROFILE, {
    ultrafiltrationProfile,
  });
}

export function startRemoveUltrafiltrationProfileActivity(
  ultrafiltrationProfile: UltrafiltrationProfileT
) {
  return startActivity(REMOVE_ULTRAFILTRATION_PROFILE, {
    ultrafiltrationProfile,
  });
}
