import boldEOT from './opensans-bold-webfont.eot';
import boldTTF from './opensans-bold-webfont.ttf';
import boldWOFF from './opensans-bold-webfont.woff';
import extraBoldEOT from './opensans-extrabold-webfont.eot';
import extraBoldTTF from './opensans-extrabold-webfont.ttf';
import extraBoldWOFF from './opensans-extrabold-webfont.woff';
import lightEOT from './opensans-light-webfont.eot';
import lightTTF from './opensans-light-webfont.ttf';
import lightWOFF from './opensans-light-webfont.woff';
import regularEOT from './opensans-regular-webfont.eot';
import regularTTF from './opensans-regular-webfont.ttf';
import regularWOFF from './opensans-regular-webfont.woff';
import semiBoldEOT from './opensans-semibold-webfont.eot';
import semiBoldTTF from './opensans-semibold-webfont.ttf';
import semiBoldWOFF from './opensans-semibold-webfont.woff';

const createFontFace = (style, weights, eot, woff, ttf) => {
  const fontWeight = weights
    .map((weight) => `font-weight: ${weight};`)
    .join('\n    ');

  return `
  @font-face {
    font-family: "Open Sans";
    font-style: ${style};
    ${fontWeight}
    src: url("${eot}");
    src: url("${eot}?#iefix") format("embedded-opentype"),
    url("${woff}") format("woff"),
    url("${ttf}") format("truetype")
  }`;
};

export default function openSansLoader() {
  const fonts = [
    createFontFace(
      'normal',
      ['normal', 400],
      regularEOT,
      regularWOFF,
      regularTTF
    ),
    createFontFace('normal', [200], lightEOT, lightWOFF, lightTTF),
    createFontFace('normal', [500], semiBoldEOT, semiBoldWOFF, semiBoldTTF),
    createFontFace('normal', ['bold', 700], boldEOT, boldWOFF, boldTTF),
    createFontFace('normal', [900], extraBoldEOT, extraBoldWOFF, extraBoldTTF),
  ];

  return fonts.join('\n');
}
