import { startActivity } from 'store/modules/activity';

import { DietT } from './fetchers';

export const ADD_DIET = 'ADD_DIET';
export const EDIT_DIET = 'EDIT_DIET';
export const REMOVE_DIET = 'REMOVE_DIET';

export const CREATE_DIET_FAILURE = 'CREATE_DIET_FAILURE';
export const UPDATE_DIET_FAILURE = 'UPDATE_DIET_FAILURE';

export const startAddDietActivity = () => startActivity(ADD_DIET);

export const startEditDietActivity = (diet: DietT) =>
  startActivity(EDIT_DIET, { diet });

export const startRemoveDietActivity = (diet: DietT) =>
  startActivity(REMOVE_DIET, { diet });
