import { DefaultRootState } from 'react-redux';

export default function getSearchResults(
  state: DefaultRootState,
  resource,
  searchIdentifier?
) {
  const collection = state.entities.getIn(
    Array.isArray(resource) ? resource : [resource]
  );

  if (!collection) return [];

  const lastSearch = state.search[searchIdentifier || resource]?.lastSearchTerm;

  const resultIds = lastSearch
    ? state.search[searchIdentifier || resource].results[lastSearch]
    : [];

  return resultIds
    .map((id) => collection.get(id))
    .filter((result) => result !== undefined)
    .map((item) => item.toJS());
}
