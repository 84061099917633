import { startActivity } from 'store/modules/activity';

import { TransplantEligibilityStatusT } from './fetchers';

export const ADD_TRANSPLANT_ELIGIBILITY_STATUS =
  'ADD_TRANSPLANT_ELIGIBILITY_STATUS';
export const EDIT_TRANSPLANT_ELIGIBILITY_STATUS =
  'EDIT_TRANSPLANT_ELIGIBILITY_STATUS';
export const REMOVE_TRANSPLANT_ELIGIBILITY_STATUS =
  'REMOVE_TRANSPLANT_ELIGIBILITY_STATUS';

export const CREATE_TRANSPLANT_ELIGIBILITY_STATUS_FAILURE =
  'CREATE_TRANSPLANT_ELIGIBILITY_STATUS_FAILURE';
export const UPDATE_TRANSPLANT_ELIGIBILITY_STATUS_FAILURE =
  'UPDATE_TRANSPLANT_ELIGIBILITY_STATUS_FAILURE';

export const addTransplantEligibilityStatusActivity = () =>
  startActivity(ADD_TRANSPLANT_ELIGIBILITY_STATUS);

export const editTransplantEligibilityStatusActivity = (
  status: TransplantEligibilityStatusT
) => startActivity(EDIT_TRANSPLANT_ELIGIBILITY_STATUS, { status });

export const removeTransplantEligibilityStatusActivity = (
  transplantEligibilityStatus: TransplantEligibilityStatusT
) =>
  startActivity(REMOVE_TRANSPLANT_ELIGIBILITY_STATUS, {
    transplantEligibilityStatus,
  });
