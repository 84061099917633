import { ReactNode, Suspense } from 'react';
import { IntlProvider } from 'react-intl';

import Loading from 'components/Loading';

import { locale } from 'locale';

interface Props {
  children: ReactNode;
}

export default function LocaleProvider({ children }: Props) {
  return (
    <Suspense fallback={<Loading />}>
      <LocaleLoader>{children}</LocaleLoader>
    </Suspense>
  );
}

function LocaleLoader({ children }: Props) {
  const messages = getMessages(locale);

  const onError = (error: any) => {
    if (
      process.env.NODE_ENV === 'development' &&
      error.code !== 'MISSING_TRANSLATION'
    ) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <IntlProvider locale={locale} messages={messages} onError={onError}>
      {children}
    </IntlProvider>
  );
}

const messagesCache: Record<string, Record<string, string>> = {};

function getMessages(locale: string) {
  const language = locale.split('-')[0];
  const supportedLanguage = ['en', 'es', 'fr', 'it', 'nl'].includes(language)
    ? language
    : 'en';

  if (
    process.env.NODE_ENV === 'development' &&
    language !== supportedLanguage
  ) {
    // eslint-disable-next-line no-console
    console.error(
      'Non supported language set as env variable falling back to English'
    );
  }

  if (messagesCache[supportedLanguage]) return messagesCache[supportedLanguage];

  // Throw a `Promise<Messages>` for React Suspense to catch;
  // `fallback` component will render until this promise resolves.
  throw loadMessages(supportedLanguage);
}

async function loadMessages(language: string) {
  const messages: Record<string, string> = await import(
    `translations/${language}.json`
  );
  messagesCache[language] = messages;

  return messages;
}
