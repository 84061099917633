import { fromJS } from 'immutable';

import {
  CREATE_JOURNAL_COMMENT,
  CREATE_JOURNAL_ENTRY,
  DELETE_JOURNAL_ENTRY,
  GET_JOURNAL_ENTRIES,
  UPDATE_JOURNAL_COMMENT,
  UPDATE_JOURNAL_ENTRY,
} from 'store/modules/entities/actions/journals';
import { mergePatientConditions } from 'store/modules/entities/reducers/conditions/patient';

import sortInline from 'utils/sort';

const JOURNAL_ENTRIES_BY_ID = ['journalEntries', 'byId'];
const JOURNAL_ENTRIES_BY_PATIENT_ID = ['journalEntries', 'byPatientId'];
const JOURNAL_ENTRY_COMMENTS = ['journalEntryComments'];

export default function journalEntriesReducer(state: any, action: any) {
  switch (action.type) {
    case GET_JOURNAL_ENTRIES.SUCCESS: {
      const {
        patientId,
        loadMore,
        response: {
          entities: {
            comments = {},
            journalEntries = {},
            patientConditions = {},
          },
          result: { data },
        },
      } = action.payload;

      return mergePatientConditions(state, patientId, patientConditions)
        .mergeDeepIn(JOURNAL_ENTRIES_BY_ID, fromJS(journalEntries))
        .mergeDeepIn(JOURNAL_ENTRY_COMMENTS, fromJS(comments))
        .updateIn([...JOURNAL_ENTRIES_BY_PATIENT_ID, patientId], (list) =>
          loadMore
            ? fromJS([...list.toJS(), ...(data || [])])
            : fromJS(data || [])
        );
    }

    case CREATE_JOURNAL_ENTRY.SUCCESS: {
      const {
        patientId,
        response: {
          entities: { comments = {}, journalEntries = {} },
          result: { 'journal/entry': id },
        },
      } = action.payload;

      return state
        .mergeIn([...JOURNAL_ENTRIES_BY_ID, id], fromJS(journalEntries[id]))
        .mergeDeepIn(JOURNAL_ENTRY_COMMENTS, fromJS(comments))
        .updateIn([...JOURNAL_ENTRIES_BY_PATIENT_ID, patientId], (list) =>
          list.find((x) => x === id) ? list : list.unshift(id)
        );
    }

    case UPDATE_JOURNAL_ENTRY.SUCCESS: {
      const {
        response: {
          entities: { comments = {}, journalEntries = {} },
          result: { 'journal/entry': id },
        },
      } = action.payload;

      return state
        .mergeIn([...JOURNAL_ENTRIES_BY_ID, id], fromJS(journalEntries[id]))
        .mergeDeepIn(JOURNAL_ENTRY_COMMENTS, fromJS(comments));
    }

    case CREATE_JOURNAL_COMMENT.SUCCESS: {
      const {
        request: { journalEntryId },
        response: {
          entities: { comments = {} },
          result,
        },
      } = action.payload;

      const commentId = result['journal/comment'];
      const currentCommentIds =
        state.getIn([...JOURNAL_ENTRIES_BY_ID, journalEntryId, 'comments']) ||
        [];
      const newCommentIds = currentCommentIds.push(commentId);

      return state
        .mergeDeepIn(
          [...JOURNAL_ENTRIES_BY_ID, journalEntryId],
          fromJS({ comments: newCommentIds })
        )
        .mergeDeepIn(JOURNAL_ENTRY_COMMENTS, fromJS(comments));
    }

    case UPDATE_JOURNAL_COMMENT.SUCCESS: {
      const {
        response: {
          entities: { comments = {} },
        },
      } = action.payload;

      return state.mergeDeepIn(JOURNAL_ENTRY_COMMENTS, fromJS(comments));
    }

    case DELETE_JOURNAL_ENTRY.SUCCESS: {
      const {
        request: { id },
      } = action.payload;

      return state.deleteIn([...JOURNAL_ENTRIES_BY_ID, id]);
    }

    default: {
      return state;
    }
  }
}

export const journalEntriesSelector = (
  state: any,
  patientId: string
): JournalEntryT[] | undefined => {
  const {
    journalEntries: { byId, byPatientId },
    journalEntryComments: comments,
    patientConditions: { byId: patientConditionsById },
    tags,
  } = state.entities.toJS();

  const journalEntries = byPatientId[patientId];

  if (!journalEntries || !comments || !tags) return undefined;

  return journalEntries
    .filter((id: string) => byId[id])
    .map((id: string) => {
      const journalEntry = byId[id];

      return {
        ...journalEntry,
        comments: journalEntry.comments
          .map((commentId: string) => comments[commentId])
          .sort(sortInline('createdAt')),
        tags: journalEntry.tags.map((tagId) => tags[tagId]).filter(Boolean),
        conditions: journalEntry.conditions.map(
          (id: string) => patientConditionsById[id]
        ),
      };
    });
};
