import { fromJS, Set } from 'immutable';
import { NormalizedSchema } from 'normalizr';
import { DefaultRootState } from 'react-redux';

import {
  CREATE_PRODUCT_CONSUMPTION_SET,
  DELETE_PRODUCT_CONSUMPTION_SET,
  GET_PRODUCT_CONSUMPTION_SETS,
  UPDATE_PRODUCT_CONSUMPTION_SET,
} from 'store/modules/entities/actions/medicationManagement/consumptionSets';
import { GET_MEDICINAL_PRODUCT } from 'store/modules/entities/actions/medicationManagement/products';

const CONSUMPTION_SETS_BY_ID_PATH = ['medicinalConsumptionSets', 'byId'];
const CONSUMPTION_SETS_BY_PRODUCT_ID_PATH = ['medicinalConsumptionSets', 'byProductId']; // prettier-ignore
const CONSUMPTION_SET_ITEMS_BY_ID_PATH = ['medicinalConsumptionSetItems', 'byId']; // prettier-ignore
const PRODUCT_PARENT_CONSUMPTION_SET_ITEMS_BY_ID_PATH = ['medicinalProductParentConsumptionSetItems', 'byId']; // prettier-ignore
const MEDICINAL_PRODUCT_ITEMS_BY_ID_PATH = ['medicinalProductItems', 'byId'];

type SerializedConsumptionSet = Omit<ConsumptionSetT, 'consumptionSetItems'> & {
  consumptionSetItems: string[];
};
type SerializedConsumptionSets = Record<string, SerializedConsumptionSet>;
type SerializedConsumptionSetItems = Record<string, ConsumptionSetItemT>;
type SerializedProductParentConsumptionSetItems = Record<
  string,
  ProductParentConsumptionSetItemT
>;

type GetProductConsumptionSetSuccessResponse = ApiSuccessResponseT<
  NormalizedSchema<
    {
      consumptionSets?: SerializedConsumptionSets;
      consumptionSetItems?: SerializedConsumptionSetItems;
    },
    { consumptionSets: string[] }
  >
>;
type CreateProductonsumptionSetSuccessResponse = ApiSuccessResponseT<
  NormalizedSchema<
    {
      consumptionSets?: SerializedConsumptionSets;
      consumptionSetItems?: SerializedConsumptionSetItems;
    },
    { consumptionSet: string }
  >
>;
type UpdateProductConsumptionSetSuccessResponse = ApiSuccessResponseT<
  NormalizedSchema<
    {
      consumptionSets?: SerializedConsumptionSets;
      consumptionSetItems?: SerializedConsumptionSetItems;
    },
    { consumptionSet: string }
  >
>;
type GetMedicalProductSuccessResponse = ApiSuccessResponseT<
  NormalizedSchema<
    {
      consumptionSets?: SerializedConsumptionSets;
      consumptionSetItems?: SerializedConsumptionSetItems;
      parentConsumptionSetItems?: SerializedProductParentConsumptionSetItems;
    },
    string
  >
>;

function setConsumptionSets(
  productId: string,
  ids: string[],
  consumptionSets: Record<string, {}>,
  consumptionSetItems: Record<string, {}>
) {
  return (
    state: DefaultRootState['entities']
  ): DefaultRootState['entities'] => {
    const byProductIdPath = [...CONSUMPTION_SETS_BY_PRODUCT_ID_PATH, productId];

    return state
      .mergeIn(CONSUMPTION_SETS_BY_ID_PATH, fromJS(consumptionSets))
      .mergeDeepIn(
        CONSUMPTION_SET_ITEMS_BY_ID_PATH,
        fromJS(consumptionSetItems)
      )
      .setIn(
        byProductIdPath,
        state.getIn(byProductIdPath)?.concat(Set(ids)) || Set(ids)
      );
  };
}

export default function productConsumptionSetsReducer(
  state: DefaultRootState['entities'],
  action: ApiResponseT
) {
  switch (action.type) {
    case GET_PRODUCT_CONSUMPTION_SETS.SUCCESS: {
      const { payload } = action as GetProductConsumptionSetSuccessResponse;
      const { response, productId } = payload;
      const { entities, result } = response;
      const { consumptionSets = {}, consumptionSetItems = {} } = entities;
      const { consumptionSets: consumptionSetIds } = result;

      return setConsumptionSets(
        productId,
        consumptionSetIds,
        consumptionSets,
        consumptionSetItems
      )(state);
    }

    case CREATE_PRODUCT_CONSUMPTION_SET.SUCCESS: {
      const { payload } = action as CreateProductonsumptionSetSuccessResponse;
      const { response, productId } = payload;
      const { entities, result } = response;
      const { consumptionSets = {}, consumptionSetItems = {} } = entities;
      const { consumptionSet: consumptionSetId } = result;

      return setConsumptionSets(
        productId,
        [consumptionSetId],
        consumptionSets,
        consumptionSetItems
      )(state);
    }

    case UPDATE_PRODUCT_CONSUMPTION_SET.SUCCESS: {
      const { payload } = action as UpdateProductConsumptionSetSuccessResponse;
      const { response } = payload;
      const { entities } = response;
      const { consumptionSets = {}, consumptionSetItems = {} } = entities;

      return state
        .mergeIn(CONSUMPTION_SETS_BY_ID_PATH, fromJS(consumptionSets))
        .mergeDeepIn(
          CONSUMPTION_SET_ITEMS_BY_ID_PATH,
          fromJS(consumptionSetItems)
        );
    }

    case DELETE_PRODUCT_CONSUMPTION_SET.SUCCESS: {
      const { payload } = action as ApiSuccessResponseT;
      const { productId, consumptionSetId } = payload;

      const consumptionSetPath = [
        ...CONSUMPTION_SETS_BY_ID_PATH,
        consumptionSetId,
      ];

      const { consumptionSetItems }: SerializedConsumptionSet = state
        .getIn(consumptionSetPath)
        .toJS();

      return state
        .updateIn(CONSUMPTION_SET_ITEMS_BY_ID_PATH, (map) =>
          map.filter((_, id) => !consumptionSetItems.includes(id))
        )
        .updateIn([...CONSUMPTION_SETS_BY_PRODUCT_ID_PATH, productId], (set) =>
          set.delete(consumptionSetId)
        )
        .deleteIn(consumptionSetPath);
    }

    case GET_MEDICINAL_PRODUCT.SUCCESS: {
      const { payload } = action as GetMedicalProductSuccessResponse;
      const { response } = payload;
      const { entities, result: productId } = response;
      const {
        consumptionSets = {},
        consumptionSetItems = {},
        parentConsumptionSetItems = {},
      } = entities;

      const consumptionSetIds = Object.keys(consumptionSets);

      const nextState = setConsumptionSets(
        productId,
        consumptionSetIds,
        consumptionSets,
        consumptionSetItems
      )(state);

      return nextState.mergeDeepIn(
        PRODUCT_PARENT_CONSUMPTION_SET_ITEMS_BY_ID_PATH,
        fromJS(parentConsumptionSetItems)
      );
    }

    default: {
      return state;
    }
  }
}

export const productConsumptionSetsSelector = (
  state: DefaultRootState,
  productId: string
) => {
  const consumptionSetsById: SerializedConsumptionSets = state.entities
    .getIn(CONSUMPTION_SETS_BY_ID_PATH)
    .toJS();

  const consumptionSetItemsById: SerializedConsumptionSetItems = state.entities
    .getIn(CONSUMPTION_SET_ITEMS_BY_ID_PATH)
    .toJS();

  const byProductId: string[] =
    state.entities
      .getIn([...CONSUMPTION_SETS_BY_PRODUCT_ID_PATH, productId])
      ?.toJS() || [];

  return byProductId.map<ConsumptionSetT>((id) => {
    const set = consumptionSetsById[id];
    const consumptionSetItems = set.consumptionSetItems
      .map((itemId) => consumptionSetItemsById[itemId])
      .filter(Boolean);

    return { ...set, consumptionSetItems };
  });
};

export const medicinalProductParentConsumptionSetItemsSelector = (
  state: DefaultRootState,
  productId: string
) => {
  const consumptionSetItemsById: SerializedProductParentConsumptionSetItems =
    state.entities
      .getIn(PRODUCT_PARENT_CONSUMPTION_SET_ITEMS_BY_ID_PATH)
      .toJS();

  const product: { parentConsumptionSetItems?: string[] } | undefined =
    state.entities
      .getIn([...MEDICINAL_PRODUCT_ITEMS_BY_ID_PATH, productId])
      ?.toJS();

  if (!product) return undefined;

  const { parentConsumptionSetItems: consumptionSetItemIds = [] } = product;

  return consumptionSetItemIds
    .map((id) => consumptionSetItemsById[id])
    .filter(Boolean);
};
