import appIconsLoader from './app-icons/appIconsLoader';
import materialIconsLoader from './material-icons/materialIconsLoader';
import openSansLoader from './open-sans/openSansLoader';

const createStyleTag = () => {
  const style = document.createElement('style');
  style.rel = 'stylesheet';
  style.type = 'text/css';
  return style;
};

export default function fontLoader() {
  const styleTag = createStyleTag();
  const fonts = [
    openSansLoader(),
    materialIconsLoader(),
    appIconsLoader(),
  ].join('\n');
  const allFonts = document.createTextNode(fonts);
  styleTag.appendChild(allFonts);

  document.head.appendChild(styleTag);
}
