import { ClinicalCategoryT } from 'api/fetchers/types';

import { startActivity } from 'store/modules/activity';

export const ADD_CLINICAL_CATEGORY = 'ADD_CLINICAL_CATEGORY';
export const EDIT_CLINICAL_CATEGORY = 'EDIT_CLINICAL_CATEGORY';
export const REMOVE_CLINICAL_CATEGORY = 'REMOVE_CLINICAL_CATEGORY';

export const CREATE_CLINICAL_CATEGORY_FAILURE =
  'CREATE_CLINICAL_CATEGORY_FAILURE';
export const UPDATE_CLINICAL_CATEGORY_FAILURE =
  'UPDATE_CLINICAL_CATEGORY_FAILURE';

export const startAddClinicalCategoryActivity = () =>
  startActivity(ADD_CLINICAL_CATEGORY);

export const startEditClinicalCategoryActivity = (
  category: ClinicalCategoryT
) => startActivity(EDIT_CLINICAL_CATEGORY, { category });

export const startRemoveClinicalCategoryActivity = (
  clinicalCategory: ClinicalCategoryT
) => startActivity(REMOVE_CLINICAL_CATEGORY, { clinicalCategory });
