import { lazy } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';

import { Perspective, useCurrentUser } from 'providers';

import FeatureFlag from 'components/FeatureFlag';
import Icon from 'components/Icon';

import RequireAuth from 'sharedComponents/RequireAuth';

import { RightClaimT, RIGHTS } from 'rights';

const t = defineMessages({
  admin: {
    id: 'navigation_perspective_admin_title',
    defaultMessage: 'System Settings',
  },
  iam: {
    id: 'settings_navigation_iam',
    defaultMessage: 'Identity & Access',
  },
  infrastructure: {
    id: 'settings_navigation_infrastructure',
    defaultMessage: 'Infrastructure',
  },
  devices: {
    id: 'settings_navigation_devices',
    defaultMessage: 'Device Management',
  },
  imports: {
    id: 'settings_navigation_imports',
    defaultMessage: 'Imports',
  },
  reports: {
    id: 'settings_navigation_reports',
    defaultMessage: 'Report Templates',
  },
  system: {
    id: 'settings_navigation_system',
    defaultMessage: 'System Configuration',
  },
});

const IamSettings = lazy(() => import('./iam'));
const InfraSettings = lazy(() => import('./infrastructure'));
const DeviceSettings = lazy(() => import('./devices'));
const ImportSettings = lazy(() => import('./imports'));
const ReportSettings = lazy(() => import('./reports'));
const SystemSettings = lazy(() => import('./system'));
const Profile = lazy(() => import('./profile'));

function MyRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <FeatureFlag rights={[RIGHTS.MANAGE_SYSTEM]}>
              <Navigate to="devices" />
            </FeatureFlag>

            <FeatureFlag rights={[RIGHTS.MANAGE_CENTERS]}>
              <Navigate to="infrastructure" />
            </FeatureFlag>

            <FeatureFlag
              rights={[RIGHTS.MANAGE_USERS, RIGHTS.MANAGE_APPLICATION_DATA]}
            >
              <Navigate to="iam" />
            </FeatureFlag>
          </>
        }
      />

      <Route
        path="iam/*"
        element={
          <RequireAuth
            rights={[RIGHTS.MANAGE_USERS, RIGHTS.MANAGE_APPLICATION_DATA]}
          >
            <IamSettings />
          </RequireAuth>
        }
      />

      <Route
        path="infrastructure/*"
        element={
          <RequireAuth rights={[RIGHTS.MANAGE_CENTERS]}>
            <InfraSettings />
          </RequireAuth>
        }
      />

      <Route
        path="devices/*"
        element={
          <RequireAuth
            rights={[RIGHTS.MANAGE_SYSTEM, RIGHTS.MANAGE_APPLICATION_DATA]}
          >
            <DeviceSettings />
          </RequireAuth>
        }
      />

      <Route
        path="imports"
        element={
          <RequireAuth rights={[RIGHTS.MANAGE_APPLICATION_DATA]}>
            <ImportSettings />
          </RequireAuth>
        }
      />

      <Route
        path="reports"
        element={
          <RequireAuth rights={[RIGHTS.MANAGE_APPLICATION_DATA]}>
            <ReportSettings />
          </RequireAuth>
        }
      />

      <Route
        path="system"
        element={
          <RequireAuth
            rights={[RIGHTS.MANAGE_SYSTEM, RIGHTS.MANAGE_APPLICATION_DATA]}
          >
            <SystemSettings />
          </RequireAuth>
        }
      />

      <Route path="profile" element={<Profile />} />
      <Route path="*" element={<Navigate to="/not_found" />} />
    </Routes>
  );
}

function Links() {
  const { formatMessage } = useIntl();

  const cx = ({ isActive }) =>
    'sidebar__item' + (isActive ? ' -is-active' : '');

  return (
    <>
      <li role="presentation">
        <FeatureFlag
          rights={[RIGHTS.MANAGE_USERS, RIGHTS.MANAGE_APPLICATION_DATA]}
        >
          <NavLink to="admin/iam" title={formatMessage(t.iam)} className={cx}>
            <Icon>account_box</Icon>
          </NavLink>
        </FeatureFlag>

        <FeatureFlag rights={[RIGHTS.MANAGE_CENTERS]}>
          <NavLink
            to="admin/infrastructure"
            title={formatMessage(t.infrastructure)}
            className={cx}
          >
            <Icon>business</Icon>
          </NavLink>
        </FeatureFlag>

        <FeatureFlag
          rights={[RIGHTS.MANAGE_SYSTEM, RIGHTS.MANAGE_APPLICATION_DATA]}
        >
          <NavLink
            to="admin/devices"
            title={formatMessage(t.devices)}
            className={cx}
          >
            <Icon>devices</Icon>
          </NavLink>
        </FeatureFlag>

        <FeatureFlag rights={[RIGHTS.MANAGE_APPLICATION_DATA]}>
          <NavLink
            to="admin/imports"
            title={formatMessage(t.imports)}
            className={cx}
          >
            <Icon>system_update_alt</Icon>
          </NavLink>
        </FeatureFlag>

        <FeatureFlag rights={[RIGHTS.MANAGE_APPLICATION_DATA]}>
          <NavLink
            to="admin/reports"
            title={formatMessage(t.reports)}
            className={cx}
          >
            <Icon>description</Icon>
          </NavLink>
        </FeatureFlag>

        <FeatureFlag
          rights={[RIGHTS.MANAGE_SYSTEM, RIGHTS.MANAGE_APPLICATION_DATA]}
        >
          <NavLink
            to="admin/system"
            title={formatMessage(t.system)}
            className={cx}
          >
            <Icon>settings</Icon>
          </NavLink>
        </FeatureFlag>
      </li>
    </>
  );
}

const adminPerspective: Perspective = {
  name: 'admin',
  path: 'admin',
  title: (intl: IntlShape) => intl.formatMessage(t.admin),

  rights: [
    RIGHTS.MANAGE_SYSTEM,
    RIGHTS.MANAGE_APPLICATION_DATA,
    RIGHTS.MANAGE_USERS,
    RIGHTS.MANAGE_CENTERS,
  ],
  searchEnabled: false,

  Icon: () => <Icon>settings_applications</Icon>,
  Routes: MyRoutes,
  Links,
};

export default adminPerspective;

export function useAdminEnabled(rights: RightClaimT[]) {
  const currentUser = useCurrentUser();
  const userRights = currentUser.rights.map((right) => right.name);

  return rights.some((right) => userRights.includes(right));
}
