import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

export const tagSchema = new schema.Entity(
  'journal/tags',
  {},
  {
    idAttribute: 'name',
    processStrategy: schemaDeserializers({
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

export const GET_TAGS = networkActionTypes('GET_TAGS');

export const getTags = (): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_TAGS,
    url: 'tags',
    method: 'GET',
    normalizeSchema: { 'logbook/tags': [tagSchema] },
  },
});

export const CREATE_TAG = networkActionTypes('CREATE_TAG');

type CreateTagArgsT = {
  name: string;
};

export const createTag = ({ name }: CreateTagArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_TAG,
    url: 'tags',
    method: 'POST',
    payload: {
      tag: { name },
    },
    normalizeSchema: { 'logbook/tag': tagSchema },
  },
});

export const DELETE_TAG = networkActionTypes('DELETE_TAG');

type DeleteTagArgsT = {
  name: string;
};

export const deleteTag = ({ name }: DeleteTagArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_TAG,
    url: `tags/${name}`,
    method: 'DELETE',
    actionPayload: { name },
  },
});
