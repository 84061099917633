import { startActivity } from 'store/modules/activity';

import { UnitAlias } from './fetchers';

export const CREATE_UNIT_ALIAS_FAILURE = 'CREATE_UNIT_ALIAS_FAILURE';
export const UPDATE_UNIT_ALIAS_FAILURE = 'UPDATE_UNIT_ALIAS_FAILURE';
export const DELETE_UNIT_ALIAS_FAILURE = 'DELETE_UNIT_ALIAS_FAILURE';

export const ADD_UNIT_ALIAS = 'ADD_UNIT_ALIAS';
export const EDIT_UNIT_ALIAS = 'EDIT_UNIT_ALIAS';
export const REMOVE_UNIT_ALIAS = 'REMOVE_UNIT_ALIAS';

export const startAddUnitAliasActivity = () => startActivity(ADD_UNIT_ALIAS);

export const startEditUnitAliasActivity = (unitAlias: UnitAlias) =>
  startActivity(EDIT_UNIT_ALIAS, { unitAlias });

export const startRemoveUnitAliasActivity = (unitAlias: UnitAlias) =>
  startActivity(REMOVE_UNIT_ALIAS, { unitAlias });
