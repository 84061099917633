import { NormalizedSchema, schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

import { patientSchema } from '../patients';

const medicationCategorySchema = new schema.Entity(
  'medicinalCategories',
  {},
  {
    processStrategy: schemaDeserializers({
      updatedAt: parseDateTime,
    }),
  }
);

const administrationDefaultSchema = new schema.Entity(
  'administrationDefaults',
  {},
  {
    processStrategy: schemaDeserializers({
      updatedAt: parseDateTime,
    }),
  }
);

const therapyPlanTypeSchema = new schema.Entity(
  'therapyPlanTypes',
  {
    clinicalCategories: [medicationCategorySchema],
    productAdministrationDefaults: [administrationDefaultSchema],
  },
  {
    processStrategy: schemaDeserializers({
      updatedAt: parseDateTime,
    }),
  }
);

export const GET_MEDICINAL_THERAPY_PLAN_TYPES = networkActionTypes(
  'GET_MEDICINAL_THERAPY_PLAN_TYPES'
);

export type GetMedicinalTherapyPlanTypesResponse = ApiSuccessResponseT<
  NormalizedSchema<
    {
      therapyPlanTypes: Record<string, TherapyPlanTypeT>;
      medicinalCategories: Record<string, TherapyPlanTypeCategoryT>;
      administrationDefaults: Record<
        string,
        TherapyPlanTypeProductAdministrationDefaultT
      >;
    },
    { therapyPlanTypes: string[] }
  >
>;

export const getMedicinalTherapyPlanTypes = (): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_MEDICINAL_THERAPY_PLAN_TYPES,
    url: 'medications/therapy_plans/types',
    method: 'GET',
    normalizeSchema: { therapyPlanTypes: [therapyPlanTypeSchema] },
  },
});

export type GetMedicinalTherapyPlanTypeResponse = ApiSuccessResponseT<
  NormalizedSchema<
    {
      therapyPlanTypes: Record<string, TherapyPlanTypeT>;
      medicinalCategories: Record<string, TherapyPlanTypeCategoryT>;
      administrationDefaults: Record<
        string,
        TherapyPlanTypeProductAdministrationDefaultT
      >;
    },
    { therapyPlanType: string }
  >
>;

export const GET_MEDICINAL_THERAPY_PLAN_TYPE = networkActionTypes(
  'GET_MEDICINAL_THERAPY_PLAN_TYPE'
);

export const getMedicinalTherapyPlanType = (id: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_MEDICINAL_THERAPY_PLAN_TYPE,
    url: `medications/therapy_plans/types/${id}`,
    method: 'GET',
    normalizeSchema: { therapyPlanType: therapyPlanTypeSchema },
  },
});

export const GET_MEDICINAL_THERAPY_PLAN_TYPE_PATIENTS = networkActionTypes(
  'GET_MEDICINAL_THERAPY_PLAN_TYPE_PATIENTS'
);

export const getMedicinalTherapyPlanTypePatients = (
  id: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_MEDICINAL_THERAPY_PLAN_TYPE_PATIENTS,
    url: `medications/therapy_plans/types/${id}/patients`,
    method: 'GET',
    normalizeSchema: { therapyPlanTypePatients: [patientSchema] },
  },
});

export type CreateMedicinalTherapyPlanTypeResponse = ApiSuccessResponseT<
  NormalizedSchema<
    {
      therapyPlanTypes: Record<string, TherapyPlanTypeT>;
      medicinalCategories: Record<string, TherapyPlanTypeCategoryT>;
      administrationDefaults: Record<
        string,
        TherapyPlanTypeProductAdministrationDefaultT
      >;
    },
    { therapyPlanType: string }
  >
>;

export const CREATE_MEDICINAL_THERAPY_PLAN_TYPE = networkActionTypes(
  'CREATE_MEDICINAL_THERAPY_PLAN_TYPE'
);

export const createMedicinalTherapyPlanType = ({
  name,
  categories,
}: {
  name: string;
  categories: string[];
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_MEDICINAL_THERAPY_PLAN_TYPE,
    url: 'medications/therapy_plans/types',
    method: 'POST',
    payload: { therapyPlanType: { name, categories } },
    normalizeSchema: { therapyPlanType: therapyPlanTypeSchema },
  },
});

export type UpdateMedicinalTherapyPlanTypeResponse = ApiSuccessResponseT<
  NormalizedSchema<
    {
      therapyPlanTypes: Record<string, TherapyPlanTypeT>;
      medicinalCategories: Record<string, TherapyPlanTypeCategoryT>;
      administrationDefaults: Record<
        string,
        TherapyPlanTypeProductAdministrationDefaultT
      >;
    },
    { therapyPlanType: string }
  >
>;

export const UPDATE_MEDICINAL_THERAPY_PLAN_TYPE = networkActionTypes(
  'UPDATE_MEDICINAL_THERAPY_PLAN_TYPE'
);

export const updateMedicinalTherapyPlanType = ({
  id,
  name,
  categories,
}: {
  id: string;
  name: string;
  categories: string[];
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_MEDICINAL_THERAPY_PLAN_TYPE,
    url: `medications/therapy_plans/types/${id}`,
    method: 'PUT',
    payload: { therapyPlanType: { name, categories } },
    normalizeSchema: { therapyPlanType: therapyPlanTypeSchema },
  },
});

export type DeleteMedicinalTherapyPlanTypeResponse = ApiSuccessResponseT<
  {},
  { id: string }
>;

export const DELETE_MEDICINAL_THERAPY_PLAN_TYPE = networkActionTypes(
  'DELETE_MEDICINAL_THERAPY_PLAN_TYPE'
);

export const deleteMedicinalTherapyPlanType = (id: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_MEDICINAL_THERAPY_PLAN_TYPE,
    url: `medications/therapy_plans/types/${id}`,
    method: 'DELETE',
    actionPayload: { id },
  },
});

export type UpdateMedicinalTherapyPlanTypeAdministrationDefaultsResponse =
  ApiSuccessResponseT<
    NormalizedSchema<
      {
        administrationDefaults: Record<
          string,
          TherapyPlanTypeProductAdministrationDefaultT
        >;
      },
      { therapyPlanTypeProductAdministrationDefault: string }
    >
  >;

export const UPDATE_MEDICINAL_THERAPY_PLAN_TYPE_ADMINISTRATION_DEFAULTS =
  networkActionTypes(
    'UPDATE_MEDICINAL_THERAPY_PLAN_TYPE_ADMINISTRATION_DEFAULTS'
  );

export interface UpdateMedicinalTherapyPlanTypeAdministrationDefaultArgs {
  id: string;
  quantity: number | null;
  prescribableUnitId: string | null;
  administrationRouteId: string | null;
  moment: string | null;
  beforeMomentMinutes: number | null;
}

export const updateMedicinalTherapyPlanTypeAdministrationDefaults = ({
  id,
  ...values
}: UpdateMedicinalTherapyPlanTypeAdministrationDefaultArgs): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_MEDICINAL_THERAPY_PLAN_TYPE_ADMINISTRATION_DEFAULTS,
    url: `medications/therapy_plans/product_administration_defaults/${id}`,
    method: 'PUT',
    payload: {
      therapyPlanTypeProductAdministrationDefaults: {
        ...values,
      },
    },
    normalizeSchema: {
      therapyPlanTypeProductAdministrationDefaults: administrationDefaultSchema,
    },
  },
});
