import { lazy } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';

import {
  usePatientCardsEnabled,
  useTrackTreatmentsEnabled,
} from 'hooks/useConfig';

import { Perspective } from 'providers';

import FeatureFlag from 'components/FeatureFlag';
import Icon from 'components/Icon';

const t = defineMessages({
  track: {
    id: 'navigation_perspective_track_title',
    defaultMessage: 'Track',
  },
  ongoing: {
    id: 'navigation_track_ongoing_sessions',
    defaultMessage: 'Ongoing sessions',
  },
  historic: {
    id: 'navigation_track_historic_sessions',
    defaultMessage: 'Historic sessions',
  },
  cards: {
    id: 'navigation_track_patient_cards',
    defaultMessage: 'Patient cards',
  },
});

const Sessions = lazy(() => import('./sessions'));
const Cards = lazy(() => import('./cards'));
const Profile = lazy(() => import('./profile'));

function MyRoutes() {
  const showTreatments = useTrackTreatmentsEnabled();
  const showCards = usePatientCardsEnabled();

  return (
    <Routes>
      {showTreatments && (
        <Route path="/" element={<Navigate to="sessions" />} />
      )}
      {showTreatments && <Route path="sessions/*" element={<Sessions />} />}

      {showCards && <Route path="/" element={<Navigate to="cards" />} />}
      {showCards && <Route path="cards" element={<Cards />} />}

      <Route path="profile" element={<Profile />} />
      <Route path="*" element={<Navigate to="/not_found" />} />
    </Routes>
  );
}

function Links() {
  const { formatMessage } = useIntl();

  const showTreatments = useTrackTreatmentsEnabled();
  const showCards = usePatientCardsEnabled();

  const cx = ({ isActive }) =>
    'sidebar__item' + (isActive ? ' -is-active' : '');

  return (
    <>
      <FeatureFlag booleanMatch={showTreatments}>
        <li role="presentation">
          <NavLink
            to="track/sessions/ongoing"
            title={formatMessage(t.ongoing)}
            className={cx}
          >
            <Icon>dashboard</Icon>
          </NavLink>
        </li>

        <li role="presentation">
          <NavLink
            to="track/sessions/historic"
            title={formatMessage(t.historic)}
            className={cx}
          >
            <Icon>app:session</Icon>
          </NavLink>
        </li>
      </FeatureFlag>

      <FeatureFlag booleanMatch={showCards}>
        <li role="presentation">
          <NavLink
            to="track/cards"
            title={formatMessage(t.cards)}
            className={cx}
          >
            <Icon>badge</Icon>
          </NavLink>
        </li>
      </FeatureFlag>
    </>
  );
}

const trackPerspective: Perspective = {
  name: 'track',
  path: 'track',
  title: (intl: IntlShape) => intl.formatMessage(t.track),

  rights: undefined,
  searchEnabled: false,

  Icon: () => <Icon>app:track</Icon>,
  Routes: MyRoutes,
  Links,
};

export default trackPerspective;
