import { serialize as objectToFormData } from 'object-to-formdata';

import { networkActionTypes } from 'store/utils';

export const IMPORT_USERS = networkActionTypes('IMPORT_USERS');

export const importUsers = (file: File): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: IMPORT_USERS,
    url: 'users/import',
    method: 'PUT',
    payload: objectToFormData({ file }),
  },
});

export const IMPORT_USERS_STATUS_REPORT = networkActionTypes(
  'IMPORT_USERS_STATUS_REPORT'
);

export const importUsersStatusReport = (): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: IMPORT_USERS_STATUS_REPORT,
    url: 'users/import/feedback_report_available',
    method: 'GET',
  },
});

export const IMPORT_USERS_STATUS_UPLOAD = networkActionTypes(
  'IMPORT_USERS_STATUS_UPLOAD'
);

export const importUsersStatusUpload = (): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: IMPORT_USERS_STATUS_UPLOAD,
    url: 'users/import/processing_status',
    method: 'GET',
  },
});
