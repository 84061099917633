import { fromJS } from 'immutable';
import { DefaultRootState } from 'react-redux';

import deindex from 'utils/deindex';

import {
  CREATE_MEDICINAL_THERAPY_PLAN_TYPE,
  CreateMedicinalTherapyPlanTypeResponse,
  DELETE_MEDICINAL_THERAPY_PLAN_TYPE,
  DeleteMedicinalTherapyPlanTypeResponse,
  GET_MEDICINAL_THERAPY_PLAN_TYPE,
  GET_MEDICINAL_THERAPY_PLAN_TYPES,
  GetMedicinalTherapyPlanTypeResponse,
  GetMedicinalTherapyPlanTypesResponse,
  UPDATE_MEDICINAL_THERAPY_PLAN_TYPE,
  UPDATE_MEDICINAL_THERAPY_PLAN_TYPE_ADMINISTRATION_DEFAULTS,
  UpdateMedicinalTherapyPlanTypeAdministrationDefaultsResponse,
  UpdateMedicinalTherapyPlanTypeResponse,
} from '../../actions/medication/therapyPlanTypes';

const THERAPY_PLAN_TYPES = ['medicinalTherapyPlanTypes'];
const THERAPY_PLAN_TYPE_CATEGORIES = ['medicinalTherapyPlanCategories'];
const THERAPY_PLAN_TYPE_ADMINISTRATION_DEFAULTS = [
  'medicinalTherapyPlanAdministrationDefaults',
];

function mergeTherapyPlanTypeData(
  state: DefaultRootState['entities'],
  {
    therapyPlanTypes = {},
    medicinalCategories = {},
    administrationDefaults = {},
  }: {
    therapyPlanTypes?: Record<string, TherapyPlanTypeT>;
    medicinalCategories?: Record<string, TherapyPlanTypeCategoryT>;
    administrationDefaults?: Record<
      string,
      TherapyPlanTypeProductAdministrationDefaultT
    >;
  }
): DefaultRootState['entities'] {
  return state
    .mergeIn(THERAPY_PLAN_TYPES, fromJS(therapyPlanTypes))
    .mergeIn(THERAPY_PLAN_TYPE_CATEGORIES, fromJS(medicinalCategories))
    .mergeDeepIn(
      THERAPY_PLAN_TYPE_ADMINISTRATION_DEFAULTS,
      fromJS(administrationDefaults)
    );
}

export default function therapyPlanTypesReducer(
  state: DefaultRootState['entities'],
  action: ApiResponseT
) {
  switch (action.type) {
    case GET_MEDICINAL_THERAPY_PLAN_TYPES.SUCCESS: {
      const {
        payload: {
          response: { entities },
        },
      } = action as GetMedicinalTherapyPlanTypesResponse;

      return mergeTherapyPlanTypeData(state, entities);
    }

    case GET_MEDICINAL_THERAPY_PLAN_TYPE.SUCCESS: {
      const {
        payload: {
          response: { entities },
        },
      } = action as GetMedicinalTherapyPlanTypeResponse;

      return mergeTherapyPlanTypeData(state, entities);
    }

    case CREATE_MEDICINAL_THERAPY_PLAN_TYPE.SUCCESS: {
      const {
        payload: {
          response: { entities },
        },
      } = action as UpdateMedicinalTherapyPlanTypeResponse;

      return mergeTherapyPlanTypeData(state, entities);
    }

    case UPDATE_MEDICINAL_THERAPY_PLAN_TYPE.SUCCESS: {
      const {
        payload: {
          response: { entities },
        },
      } = action as CreateMedicinalTherapyPlanTypeResponse;

      return mergeTherapyPlanTypeData(state, entities);
    }

    case DELETE_MEDICINAL_THERAPY_PLAN_TYPE.SUCCESS: {
      const {
        payload: { id },
      } = action as DeleteMedicinalTherapyPlanTypeResponse;

      return state.removeIn([...THERAPY_PLAN_TYPES, id]);
    }

    case UPDATE_MEDICINAL_THERAPY_PLAN_TYPE_ADMINISTRATION_DEFAULTS.SUCCESS: {
      const {
        payload: {
          response: { entities },
        },
      } =
        action as UpdateMedicinalTherapyPlanTypeAdministrationDefaultsResponse;

      return mergeTherapyPlanTypeData(state, entities);
    }

    default: {
      return state;
    }
  }
}

type RawTherapyPlanType = Omit<
  TherapyPlanTypeT,
  'clinicalCategories' | 'productAdministrationDefaults'
> & {
  clinicalCategories?: string[];
  productAdministrationDefaults?: string[];
};

const denormalizeTherapyPlanType =
  (
    clinicalCategories: Record<string, TherapyPlanTypeCategoryT> | undefined,
    administrationDefaults:
      | Record<string, TherapyPlanTypeProductAdministrationDefaultT>
      | undefined
  ) =>
  (therapyPlanType: RawTherapyPlanType): TherapyPlanTypeT => {
    return {
      ...therapyPlanType,
      clinicalCategories: clinicalCategories
        ? therapyPlanType.clinicalCategories
            ?.map((id) => clinicalCategories[id])
            .filter(Boolean) || []
        : [],
      productAdministrationDefaults: administrationDefaults
        ? therapyPlanType.productAdministrationDefaults
            ?.map((id) => administrationDefaults[id])
            .filter(Boolean) || []
        : [],
    };
  };

export function medicinalTherapyPlanTypesSelector(state: DefaultRootState) {
  const isFetching = !!state.network.GET_MEDICINAL_THERAPY_PLAN_TYPES;

  if (isFetching) return undefined;

  const therapyPlanTypes: Record<string, RawTherapyPlanType> =
    state.entities.getIn(THERAPY_PLAN_TYPES).toJS() || {};

  const therapyPlanTypeCategories = state.entities
    .getIn(THERAPY_PLAN_TYPE_CATEGORIES)
    ?.toJS() as Record<string, TherapyPlanTypeCategoryT> | undefined;

  const therapyPlanTypeAdministrationDefaults = state.entities
    .getIn(THERAPY_PLAN_TYPE_ADMINISTRATION_DEFAULTS)
    ?.toJS() as
    | Record<string, TherapyPlanTypeProductAdministrationDefaultT>
    | undefined;

  return deindex<RawTherapyPlanType>(therapyPlanTypes).map(
    denormalizeTherapyPlanType(
      therapyPlanTypeCategories,
      therapyPlanTypeAdministrationDefaults
    )
  );
}

export function medicinalTherapyPlanTypeSelector(
  state: DefaultRootState,
  id: string
) {
  const isFetching = !!state.network.GET_MEDICINAL_THERAPY_PLAN_TYPE;

  if (isFetching) return undefined;

  const therapyPlanType = state.entities
    .getIn([...THERAPY_PLAN_TYPES, id])
    ?.toJS() as RawTherapyPlanType | undefined;

  if (!therapyPlanType) return undefined;

  const therapyPlanTypeCategories = state.entities
    .getIn(THERAPY_PLAN_TYPE_CATEGORIES)
    ?.toJS() as Record<string, TherapyPlanTypeCategoryT> | undefined;

  const therapyPlanTypeAdministrationDefaults = state.entities
    .getIn(THERAPY_PLAN_TYPE_ADMINISTRATION_DEFAULTS)
    ?.toJS() as
    | Record<string, TherapyPlanTypeProductAdministrationDefaultT>
    | undefined;

  return denormalizeTherapyPlanType(
    therapyPlanTypeCategories,
    therapyPlanTypeAdministrationDefaults
  )(therapyPlanType);
}
