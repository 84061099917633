import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { patientSchema } from 'store/modules/entities/actions/patients';
import { networkActionTypes } from 'store/utils';

export const billingItemSchema = new schema.Entity(
  'billingItems',
  {
    patient: patientSchema,
  },
  {
    processStrategy: (billingItem) => ({
      ...billingItem,
      ...schemaDeserializers({
        administeredAt: parseDateTime,
        createdAt: parseDateTime,
        updatedAt: parseDateTime,
        syncedAt: parseDateTime,
      })(billingItem),
      billedItem: billingItem.billedItem
        ? {
            ...billingItem.billedItem,
            stocks: billingItem.billedItem.stocks
              ? schemaDeserializers({
                  createdAt: parseDateTime,
                  updatedAt: parseDateTime,
                  syncedAt: parseDateTime,
                })(billingItem.billedItem.stocks)
              : null,
          }
        : null,
      administrator: billingItem.administrator
        ? {
            ...billingItem.administrator,
            ...schemaDeserializers({
              createdAt: parseDateTime,
              updatedAt: parseDateTime,
            })(billingItem.administrator),
          }
        : null,
    }),
  }
);

export const SYNC_BILLING_ITEM = networkActionTypes('SYNC_BILLING_ITEM');

export const syncBillingItem = (id: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: SYNC_BILLING_ITEM,
    url: `billing_items/${id}/sync`,
    method: 'PUT',
    normalizeSchema: { billingItem: billingItemSchema },
  },
});

export const CREATE_BILLING_ITEM = networkActionTypes('CREATE_BILLING_ITEM');

export const createBillingItem = (
  exportableId: string,
  billingItem: {
    patientId: string;
    billingCodeId?: string;
    billingCodeNumber?: string;
    administeredById: string;
    administeredAt: Date;
    episodeId: string;
    billingCodeType: NonNullable<BaseExportableItemT['billingCodeType']>;
    premium: BaseExportableItemT['premium'];
    centerId?: string;
    serviceCode?: string;
  }
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_BILLING_ITEM,
    url: 'billing_items',
    method: 'POST',
    payload: { billingItem: { ...billingItem, exportableId } },
    actionPayload: { exportableId },
    normalizeSchema: { billingItem: billingItemSchema },
  },
});

export const UPDATE_BILLING_ITEM = networkActionTypes('UPDATE_BILLING_ITEM');

export const updateBillingItem = (
  id: string,
  billingItem: any
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_BILLING_ITEM,
    url: `billing_items/${id}`,
    payload: { billingItem },
    method: 'PUT',
    normalizeSchema: {
      billingItem: billingItemSchema,
      billingItems: [billingItemSchema],
    },
  },
});

export const DELETE_BILLING_ITEM = networkActionTypes('DELETE_BILLING_ITEM');

export const deleteBillingItem = (
  exportableId: string,
  itemId: string
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_BILLING_ITEM,
    url: `billing_items/${itemId}`,
    method: 'DELETE',
    payload: { billingItem: { exportableId } },
    actionPayload: { exportableId, itemId },
  },
});
