import { lazy } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';

import { Perspective } from 'providers';

import Icon from 'components/Icon';

const t = defineMessages({
  workbench: {
    id: 'navigation_perspective_workbench_title',
    defaultMessage: 'Workbench',
  },
  dashboard: {
    id: 'navigation_perspective_workbench_dashboard_title',
    defaultMessage: 'Dashboard',
  },
});

const Dashboard = lazy(() => import('./components/Dashboard'));
const Device = lazy(() => import('./devices/[deviceId]'));
const Profile = lazy(() => import('./profile'));

function MyRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="devices/:deviceId/*" element={<Device />} />
      <Route path="profile" element={<Profile />} />
      <Route path="*" element={<Navigate to="/not_found" />} />
    </Routes>
  );
}

function Links() {
  const { formatMessage } = useIntl();

  const cx = ({ isActive }) =>
    'sidebar__item' + (isActive ? ' -is-active' : '');

  return (
    <>
      <li role="presentation">
        <NavLink
          to="/workbench"
          title={formatMessage(t.dashboard)}
          className={cx}
        >
          <Icon>dashboard</Icon>
        </NavLink>
      </li>
    </>
  );
}

const workbenchPerspective: Perspective = {
  name: 'workbench',
  path: 'workbench',
  title: (intl: IntlShape) => intl.formatMessage(t.workbench),

  rights: undefined,
  searchEnabled: false,

  Icon: () => <Icon>app:workbench</Icon>,
  Routes: MyRoutes,
  Links,
};

export default workbenchPerspective;
