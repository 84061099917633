import { fromJS, Set } from 'immutable';

import {
  CREATE_MEDICATION_INTERRUPTION,
  DELETE_MEDICATION_INTERRUPTION,
  GET_MEDICATION_INTERRUPTION_HISTORY,
  GET_MEDICATION_INTERRUPTIONS,
  UPDATE_MEDICATION_INTERRUPTION,
} from 'store/modules/entities/actions/medication/interruptions';

import sortInline from 'utils/sort';

const BY_ID = ['medicationInterruptions', 'byId'];
const BY_PATIENT_ID = ['medicationInterruptions', 'byPatientId'];
const HISTORY_BY_ID = ['medicationInterruptionsHistory', 'byId'];
const HISTORY_BY_INTERRUPTION_ID = [
  'medicationInterruptionsHistory',
  'byInterruptionId',
];

export default function interruptionsReducer(state: any, action: any) {
  switch (action.type) {
    case GET_MEDICATION_INTERRUPTIONS.SUCCESS: {
      const {
        response: {
          result: { medicationScheduleInterruptions },
          entities: { medicationInterruptions },
        },
        patientId,
      } = action.payload;

      const byPatientId = Set(
        state.getIn([...BY_PATIENT_ID, patientId]) || []
      ).union(Set(medicationScheduleInterruptions || []));

      return state
        .setIn(BY_ID, fromJS(medicationInterruptions || {}))
        .setIn([...BY_PATIENT_ID, patientId], byPatientId);
    }

    case CREATE_MEDICATION_INTERRUPTION.SUCCESS: {
      const {
        response: {
          entities: { medicationInterruptions },
          result: { medicationScheduleInterruption: id },
        },
        patientId,
      } = action.payload;

      const byPatientId =
        state.getIn([...BY_PATIENT_ID, patientId])?.add(id) || Set([id]);

      return state
        .mergeIn(BY_ID, fromJS(medicationInterruptions))
        .setIn([...BY_PATIENT_ID, patientId], byPatientId);
    }

    case UPDATE_MEDICATION_INTERRUPTION.SUCCESS: {
      const {
        response: {
          entities: { medicationInterruptions },
        },
      } = action.payload;

      return state.mergeIn(BY_ID, fromJS(medicationInterruptions));
    }

    case DELETE_MEDICATION_INTERRUPTION.SUCCESS: {
      const { id, patientId } = action.payload;

      const filteredIds = state
        .getIn([...BY_PATIENT_ID, patientId])
        .filter((interruptionId: string) => interruptionId !== id);

      return state
        .deleteIn([...BY_ID, id])
        .setIn([...BY_PATIENT_ID, patientId], filteredIds);
    }

    case GET_MEDICATION_INTERRUPTION_HISTORY.SUCCESS: {
      const {
        response: {
          result: { medicationScheduleInterruptions },
          entities: { medicationInterruptions },
        },
        interruptionId,
      } = action.payload;

      return state
        .mergeIn(HISTORY_BY_ID, fromJS(medicationInterruptions || {}))
        .setIn(
          [...HISTORY_BY_INTERRUPTION_ID, interruptionId],
          Set(medicationScheduleInterruptions)
        );
    }

    default: {
      return state;
    }
  }
}

export const medicationInterruptionsSelector = (
  state: any,
  patientId: string,
  skipFetching: boolean = false
): MedicationInterruptionT[] | undefined => {
  const {
    medicationInterruptions: { byId, byPatientId },
  } = state.entities.toJS();

  const isFetching = skipFetching
    ? false
    : !!state.network.GET_MEDICATION_INTERRUPTIONS;

  const ids = byPatientId?.[patientId];

  if (isFetching) return;

  return (
    ids
      ?.map((id: string) => byId[id])
      .filter(Boolean)
      .sort(sortInline('startDate', 'desc')) || []
  );
};

export const activeInterruptionsSelector = (
  state: any,
  patientId: string
): MedicationInterruptionT[] | undefined => {
  return medicationInterruptionsSelector(state, patientId, true)?.filter(
    (i) => i.status === 'active'
  );
};

export const medicationInterruptionHistorySelector = (
  state: any,
  id: string
): MedicationInterruptionT[] | undefined => {
  const {
    medicationInterruptionsHistory: { byId, byInterruptionId },
  } = state.entities.toJS();

  const isFetching = !!state.network.GET_MEDICATION_INTERRUPTION_HISTORY;

  const ids = byInterruptionId[id];

  if (!byId || !ids || isFetching) return undefined;

  return ids
    .map((id: string) => byId[id])
    .filter(Boolean)
    .sort(sortInline('updatedAt', 'desc'));
};
