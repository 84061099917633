import { startActivity } from 'store/modules/activity';

import { AccessSiteTechniqueT } from 'views/record/patients/patient/treatmentAccess/fetchers';
import { InterventionTypeT } from 'views/record/patients/patient/treatmentAccess/site/interventions/fetchers';

export const ADD_ACCESS_SITE_TECHNIQUE = 'ADD_ACCESS_SITE_TECHNIQUE';
export const EDIT_ACCESS_SITE_TECHNIQUE = 'EDIT_ACCESS_SITE_TECHNIQUE';
export const REMOVE_ACCESS_SITE_TECHNIQUE = 'REMOVE_ACCESS_SITE_TECHNIQUE';

export const CREATE_TREATMENT_ACCESS_SITE_TECHNIQUE_FAILURE =
  'CREATE_TREATMENT_ACCESS_SITE_TECHNIQUE_FAILURE';
export const UPDATE_TREATMENT_ACCESS_SITE_TECHNIQUE_FAILURE =
  'UPDATE_TREATMENT_ACCESS_SITE_TECHNIQUE_FAILURE';

export const ADD_INTERVENTION_TYPE = 'ADD_INTERVENTION_TYPE';
export const EDIT_INTERVENTION_TYPE = 'EDIT_INTERVENTION_TYPE';
export const REMOVE_INTERVENTION_TYPE = 'REMOVE_INTERVENTION_TYPE';

export const CREATE_INTERVENTION_TYPE_FAILURE =
  'CREATE_INTERVENTION_TYPE_FAILURE';
export const UPDATE_INTERVENTION_TYPE_FAILURE =
  'UPDATE_INTERVENTION_TYPE_FAILURE';

export const startAddSiteTechniqueActivity = () =>
  startActivity(ADD_ACCESS_SITE_TECHNIQUE);

export const startEditSiteTechniqueActivity = (
  siteTechnique: AccessSiteTechniqueT
) => startActivity(EDIT_ACCESS_SITE_TECHNIQUE, { siteTechnique });

export const startRemoveSiteTechniqueActivity = (
  accessSiteTechnique: AccessSiteTechniqueT
) => startActivity(REMOVE_ACCESS_SITE_TECHNIQUE, { accessSiteTechnique });

export const startAddInterventionTypeActivity = () =>
  startActivity(ADD_INTERVENTION_TYPE);

export const startEditInterventionTypeActivity = (
  interventionType: InterventionTypeT
) => startActivity(EDIT_INTERVENTION_TYPE, { interventionType });

export const startRemoveInterventionTypeActivity = (
  interventionType: InterventionTypeT
) => startActivity(REMOVE_INTERVENTION_TYPE, { interventionType });
