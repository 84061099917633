import { defineMessages, IntlShape } from 'react-intl';

export function isSameDay(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export function formatRelative(intl: IntlShape, date: Date): string {
  const t = defineMessages({
    today: {
      id: 'date_relative_today',
      defaultMessage: 'Today',
    },
    yesterday: {
      id: 'date_relative_yesterday',
      defaultMessage: 'Yesterday',
    },
  });

  const today = new Date();
  if (isSameDay(today, date)) return intl.formatMessage(t.today);

  const yesterday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  );
  if (isSameDay(yesterday, date)) return intl.formatMessage(t.yesterday);

  return intl.formatDate(date, {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
  });
}

// https://www.w3.org/TR/NOTE-datetime
export function isIsoDate(str: string): boolean {
  const isoDateRegExp =
    /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d(:[0-5]\d)?(\.\d+)?([+-][0-2]\d:[0-5]\d|Z)$/;

  return isoDateRegExp.test(str);
}
