import { startActivity } from 'store/modules/activity';

import { PatientCardSetting } from './fetchers';

export const ADD_PATIENT_CARD_SETTING = 'ADD_PATIENT_CARD_SETTING';
export const EDIT_PATIENT_CARD_SETTING = 'EDIT_PATIENT_CARD_SETTING';
export const REMOVE_PATIENT_CARD_SETTING = 'REMOVE_PATIENT_CARD_SETTING';

export const CREATE_PATIENT_CARD_SETTING_FAILURE =
  'CREATE_PATIENT_CARD_SETTING_FAILURE';
export const UPDATE_PATIENT_CARD_SETTING_FAILURE =
  'UPDATE_PATIENT_CARD_SETTING_FAILURE';
export const DELETE_PATIENT_CARD_SETTING_FAILURE =
  'DELETE_PATIENT_CARD_SETTING_FAILURE';

export const startAddPatientCardSettingActivity = () =>
  startActivity(ADD_PATIENT_CARD_SETTING, {});

export const startEditPatientCardSettingActivity = (
  setting: PatientCardSetting
) => startActivity(EDIT_PATIENT_CARD_SETTING, { setting });

export const startRemovePatientCardSettingActivity = (
  patientCardSetting: PatientCardSetting
) => startActivity(REMOVE_PATIENT_CARD_SETTING, { patientCardSetting });

export const addPatientCardSettingActivity = () =>
  startActivity(ADD_PATIENT_CARD_SETTING);

export const editPatientCardSettingActivity = (setting: PatientCardSetting) =>
  startActivity(EDIT_PATIENT_CARD_SETTING, { setting });

export const removePatientCardSettingActivity = (
  patientCardSetting: PatientCardSetting
) => startActivity(REMOVE_PATIENT_CARD_SETTING, { patientCardSetting });
