import { NormalizedSchema, schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const labResultSchema = new schema.Entity(
  'labResults',
  {},
  {
    processStrategy: schemaDeserializers({
      effectiveDateTime: parseDateTime,
    }),
  }
);

const observationTypeSchema = new schema.Entity('observationTypes');

const therapyPlanTypeSchema = new schema.Entity(
  'therapyPlanTypes',
  {},
  {
    processStrategy: schemaDeserializers({
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

const therapyPlanSchema = new schema.Entity(
  'therapyPlans',
  {
    therapyPlanType: therapyPlanTypeSchema,
  },
  {
    processStrategy: schemaDeserializers({
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

const therapyPlanCalendarDateEntrySchema = new schema.Entity(
  'therapyPlanCalendarDateEntries'
);

const therapyPlanCalendarDateSchema = new schema.Entity(
  'therapyPlanCalendarDates',
  {
    entries: [therapyPlanCalendarDateEntrySchema],
  },
  {
    idAttribute(value, parent) {
      return `${parent.therapyPlanId}-${value.date}`;
    },
  }
);

const therapyPlanCalendarSchema = new schema.Entity(
  'therapyPlanCalendars',
  {
    dates: [therapyPlanCalendarDateSchema],
  },
  {
    idAttribute: 'therapyPlanId',
  }
);

export const GET_MEDICINAL_THERAPY_PLANS = networkActionTypes(
  'GET_MEDICINAL_THERAPY_PLANS'
);

export type GetMedicinalTherapyPlansResponse = ApiSuccessResponseT<
  NormalizedSchema<
    {
      therapyPlans: Record<string, TherapyPlanT>;
      therapyPlanTypes: Record<string, TherapyPlanTypeT>;
    },
    { therapyPlans: string[] }
  >,
  { patientId: string }
>;

export function getMedicinalTherapyPlans(
  patientId: string,
  params?: { therapyPlanType?: string }
): NetworkActionT {
  return {
    type: 'CALL_API',
    payload: {
      types: GET_MEDICINAL_THERAPY_PLANS,
      method: 'GET',
      url: `patients/${patientId}/therapy_plans`,
      params,
      actionPayload: { patientId },
      normalizeSchema: { therapyPlans: [therapyPlanSchema] },
    },
  };
}

export const GET_MEDICINAL_THERAPY_PLAN = networkActionTypes(
  'GET_MEDICINAL_THERAPY_PLAN'
);

export type GetMedicinalTherapyPlanResponse = ApiSuccessResponseT<
  NormalizedSchema<
    {
      therapyPlans: Record<string, TherapyPlanT>;
      therapyPlanTypes: Record<string, TherapyPlanTypeT>;
    },
    { therapyPlan: string }
  >,
  { patientId: string }
>;

export function getMedicinalTherapyPlan(
  patientId: string,
  therapyPlanId: string
): NetworkActionT {
  return {
    type: 'CALL_API',
    payload: {
      types: GET_MEDICINAL_THERAPY_PLAN,
      method: 'GET',
      url: `therapy_plans/${therapyPlanId}`,
      actionPayload: { patientId },
      normalizeSchema: { therapyPlan: therapyPlanSchema },
    },
  };
}

export const CREATE_MEDICINAL_THERAPY_PLAN = networkActionTypes(
  'CREATE_MEDICINAL_THERAPY_PLAN'
);

export type CreateMedicinalTherapyPlanResponse = ApiSuccessResponseT<
  NormalizedSchema<
    { therapyPlans: Record<string, TherapyPlanT> },
    { therapyPlan: string }
  >,
  { patientId: string }
>;

export function createMedicinalTherapyPlan(
  patientId: string,
  therapyPlanTypeId: string
): NetworkActionT {
  return {
    type: 'CALL_API',
    payload: {
      types: CREATE_MEDICINAL_THERAPY_PLAN,
      method: 'POST',
      url: `patients/${patientId}/therapy_plans`,
      payload: { therapyPlan: { therapyPlanTypeId } },
      actionPayload: { patientId },
      normalizeSchema: { therapyPlan: therapyPlanSchema },
    },
  };
}

export type StopMedicinalTherapyPlanResponse = ApiSuccessResponseT<
  undefined,
  { patientId: string; therapyPlanId: string }
>;

export const STOP_MEDICINAL_THERAPY_PLAN = networkActionTypes(
  'STOP_MEDICINAL_THERAPY_PLAN'
);

export function stopMedicinalTherapyPlan(
  patientId: string,
  therapyPlanId: string,
  options?: { stopIntradialyticPrescriptions: boolean }
): NetworkActionT {
  return {
    type: 'CALL_API',
    payload: {
      types: STOP_MEDICINAL_THERAPY_PLAN,
      method: 'POST',
      url: `therapy_plans/${therapyPlanId}/stop`,
      payload: {
        stopIntradialyticPrescriptions:
          options?.stopIntradialyticPrescriptions || false,
      },
      actionPayload: { patientId, therapyPlanId },
    },
  };
}

export const DELETE_MEDICINAL_THERAPY_PLAN = networkActionTypes(
  'DELETE_MEDICINAL_THERAPY_PLAN'
);

export function deleteMedicinalTherapyPlan(
  patientId: string,
  therapyPlanId: string
): NetworkActionT {
  return {
    type: 'CALL_API',
    payload: {
      types: DELETE_MEDICINAL_THERAPY_PLAN,
      method: 'DELETE',
      url: `patients/${patientId}/therapy_plans/${therapyPlanId}`,
    },
  };
}

export const GET_MEDICINAL_THERAPY_PLAN_CALENDAR = networkActionTypes(
  'GET_MEDICINAL_THERAPY_PLAN_CALENDAR'
);

export type GetMedicinalTherapyPlanCalendarResponse = ApiSuccessResponseT<
  NormalizedSchema<
    {
      therapyPlanCalendars: Record<string, TherapyPlanCalendarT>;
      therapyPlanCalendarDates: Record<string, TherapyPlanCalendarDateT>;
      therapyPlanCalendarDateEntries: Record<
        string,
        TherapyPlanCalendarDateEntryT
      >;
    },
    { therapyPlanCalendar: string }
  >
>;

export function getMedicinalTherapyPlanCalendar(
  patientId: string,
  therapyPlanId: string,
  startDate: Date,
  endDate: Date
): NetworkActionT {
  return {
    type: 'CALL_API',
    payload: {
      types: GET_MEDICINAL_THERAPY_PLAN_CALENDAR,
      method: 'GET',
      url: `therapy_plans/${therapyPlanId}/calendar`,
      params: { startDate, endDate },
      normalizeSchema: { therapyPlanCalendar: therapyPlanCalendarSchema },
    },
  };
}

export const GET_MEDICINAL_THERAPY_PLAN_LAB_RESULTS = networkActionTypes(
  'GET_MEDICINAL_THERAPY_PLAN_LAB_RESULTS'
);

export type GetMedicinalTherapyPlanLabResultsResponse = ApiSuccessResponseT<
  NormalizedSchema<
    {
      labResults: Record<string, LabResultT>;
      observationTypes: Record<string, LabObservationTypeT>;
    },
    { results: string[]; observationTypes: string[] }
  >,
  { patientId: string; therapyPlanId: string }
>;

export function getMedicinalTherapyPlanLabResults(
  patientId: string,
  therapyPlanId: string
): NetworkActionT {
  return {
    type: 'CALL_API',
    payload: {
      types: GET_MEDICINAL_THERAPY_PLAN_LAB_RESULTS,
      method: 'GET',
      url: `therapy_plans/${therapyPlanId}/lab_results?period=3_months`,
      actionPayload: { patientId, therapyPlanId },
      normalizeSchema: {
        results: [labResultSchema],
        observationTypes: [observationTypeSchema],
      },
    },
  };
}

export const CLEAR_MEDICINAL_THERAPY_PLANS = 'CLEAR_MEDICINAL_THERAPY_PLANS';

export function clearMedicinalTherapyPlans() {
  return {
    type: CLEAR_MEDICINAL_THERAPY_PLANS,
  };
}
