import { fromJS } from 'immutable';

import {
  CHANGE_AMBULATORY_MEDICATION_SCHEDULE_QUANTITY,
  DISCARD_PENDING_INTAKE_MOMENTS,
  GET_AMBULATORY_MEDICATION_SCHEDULE,
  UPDATE_AMBULATORY_MEDICATION_SCHEDULE,
} from 'store/modules/entities/actions/medication';

const storePersistentScheduleItems = (state: any, action: any) => {
  const {
    patientId,
    response: { result, entities },
  } = action.payload;
  const keyPath = ['ambulatoryMedicationSchedule', patientId, 'persistent'];

  const prescriptions = result['medications/homeMedicationSchedule'];
  const schedule = {};

  prescriptions.forEach((key) => {
    const prescription = entities.ambulatoryMedicationSchedule[key];

    schedule[key] = {
      ...prescription,
      intakeMoments: prescription.intakeMoments.reduce(
        (prevIntakeMoment, nextIntakeMoment) => ({
          ...prevIntakeMoment,
          [nextIntakeMoment.moment]: nextIntakeMoment.quantity,
        }),
        {}
      ),
    };
  });

  return state.setIn(keyPath, fromJS(schedule));
};

const clearPendingScheduleItems = (state: any, action: any) => {
  const keyPath = [
    'ambulatoryMedicationSchedule',
    action.payload.patientId,
    'pending',
  ];

  return state.removeIn(keyPath);
};

export default function medicationScheduleReducer(state: any, action: any) {
  switch (action.type) {
    case GET_AMBULATORY_MEDICATION_SCHEDULE.SUCCESS: {
      return storePersistentScheduleItems(state, action);
    }

    case UPDATE_AMBULATORY_MEDICATION_SCHEDULE.SUCCESS: {
      return clearPendingScheduleItems(
        storePersistentScheduleItems(state, action),
        action
      );
    }

    case DISCARD_PENDING_INTAKE_MOMENTS: {
      return clearPendingScheduleItems(state, action);
    }

    case CHANGE_AMBULATORY_MEDICATION_SCHEDULE_QUANTITY: {
      const { patientId, prescriptionId, moment, quantity } = action.payload;
      const keyPath = [
        'ambulatoryMedicationSchedule',
        patientId,
        'pending',
        prescriptionId,
        'intakeMoments',
        moment,
      ];

      return state.setIn(keyPath, quantity);
    }

    default: {
      return state;
    }
  }
}

export const ambulatoryMedicationScheduleSelector = (
  state: any,
  patientId: string
): AmbulatoryMedicationScheduleItemT[] | undefined => {
  const { ambulatoryMedicationSchedule } = state.entities.toJS();
  const schedule = ambulatoryMedicationSchedule[patientId];

  if (!schedule || !schedule.persistent) return;

  const mergedSchedule = Object.keys(schedule.persistent).map((id) => ({
    ...schedule.persistent[id],
    ...schedule.pending?.[id],
    intakeMoments: {
      ...schedule.persistent[id]?.intakeMoments,
      ...schedule.pending?.[id]?.intakeMoments,
    },
  }));

  const sortedMergedSchedule = mergedSchedule.sort((a: any, b: any) => {
    const nameA = a.productName
      ? a.productName.toUpperCase()
      : a.formula.toUpperCase();

    const nameB = b.productName
      ? b.productName.toUpperCase()
      : b.formula.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  return sortedMergedSchedule;
};

export const pendingAmbulatoryMedicationScheduleSelector = (
  state: any,
  patientId: string
): any => {
  const { ambulatoryMedicationSchedule } = state.entities.toJS();
  const schedule = ambulatoryMedicationSchedule[patientId];

  if (!schedule || !schedule.pending) return undefined;

  return Object.keys(schedule.pending).reduce((acc, key) => {
    const pendingItem = schedule.pending[key];
    const intakeMoments = Object.entries(pendingItem.intakeMoments || []).map(
      (intakeMoment) => ({
        moment: intakeMoment[0],
        quantity: intakeMoment[1],
      })
    );

    return {
      ...acc,
      [key]: {
        intakeMoments,
        patientInstructions: pendingItem.patientInstructions,
      },
    };
  }, {});
};
