import { startActivity } from 'store/modules/activity';

import { PatientTreatmentT } from './fetchers';

export const ADD_PATIENT_TREATMENT = 'ADD_PATIENT_TREATMENT';
export const EDIT_PATIENT_TREATMENT = 'EDIT_PATIENT_TREATMENT';
export const STOP_PATIENT_TREATMENT = 'STOP_PRESCRIPTION';
export const REMOVE_PATIENT_TREATMENT = 'REMOVE_PATIENT_TREATMENT';
export const CLONE_PATIENT_TREATMENT = 'CLONE_PATIENT_TREATMENT';
export const EDIT_PRESCRIPTION = 'EDIT_PRESCRIPTION';

export const UPDATE_PATIENT_TREATMENT_PRESCRIPTION_FAILURE =
  'UPDATE_PATIENT_TREATMENT_PRESCRIPTION_FAILURE';
export const CREATE_PATIENT_TREATMENT_FAILURE =
  'CREATE_PATIENT_TREATMENT_FAILURE';
export const UPDATE_PATIENT_TREATMENT_FAILURE =
  'UPDATE_PATIENT_TREATMENT_FAILURE';
export const STOP_PATIENT_TREATMENT_FAILURE = 'STOP_PATIENT_TREATMENT_FAILURE';
export const DELETE_PATIENT_TREATMENT_FAILURE =
  'DELETE_PATIENT_TREATMENT_FAILURE';
export const CLONE_PATIENT_TREATMENT_FAILURE =
  'CLONE_PATIENT_TREATMENT_FAILURE';

export function startAddTreatmentActivity() {
  return startActivity(ADD_PATIENT_TREATMENT);
}

export function startEditTreatmentActivity(
  patientTreatment: PatientTreatmentT
) {
  return startActivity(EDIT_PATIENT_TREATMENT, { patientTreatment });
}

export function startStopTreatmentActivity(
  patientTreatment: PatientTreatmentT
) {
  return startActivity(STOP_PATIENT_TREATMENT, { patientTreatment });
}

export function startRemoveTreatmentActivity(
  patientTreatment: PatientTreatmentT
) {
  return startActivity(REMOVE_PATIENT_TREATMENT, { patientTreatment });
}

export function startCloneTreatmentActivity(
  patientTreatment: PatientTreatmentT
) {
  return startActivity(CLONE_PATIENT_TREATMENT, { patientTreatment });
}

export function startEditPrescriptionActivity(sectionId: string) {
  return startActivity(EDIT_PRESCRIPTION, { sectionId });
}
