import { Children, cloneElement, ReactChild, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { patientSelector } from 'store/modules/entities/reducers/patients/patients';

import { useCurrentUser } from 'providers';

import { RightClaimT } from 'rights';

export function featureCheck({
  currentUser,
  patient,
  rights,
  patientStatus,
  booleanMatch = true,
}: {
  currentUser?: UserT | null;
  patient?: PatientT | null;
  rights?: RightClaimT[];
  patientStatus?: ('enrolled' | 'not_enrolled')[];
  booleanMatch?: boolean;
}) {
  if (!currentUser && Array.isArray(rights)) {
    return false;
  }

  if (!patient && Array.isArray(patientStatus)) {
    return false;
  }

  const userRights = (currentUser?.rights || []).map((right) => right.name);

  const patientStatuses = patient
    ? {
        enrolled: patient.enrollmentStatus === 'enrolled',
        not_enrolled: patient.enrollmentStatus === 'not_enrolled',
      }
    : {};

  const someRightsMatch = Array.isArray(rights)
    ? rights.some((right) => userRights.includes(right))
    : true;

  const patientStatusMatch = Array.isArray(patientStatus)
    ? patientStatus.every((status) => patientStatuses[status] === true)
    : true;

  return booleanMatch && someRightsMatch && patientStatusMatch;
}

function FeatureFlag({
  patientId,
  patientStatus,
  rights,
  booleanMatch = true,
  children,
  ...extra
}: {
  patientId?: string;
  patientStatus?: ('enrolled' | 'not_enrolled')[];
  rights?: RightClaimT[];
  booleanMatch?: boolean;
  children?: ReactChild[] | ReactChild | ((arg0: boolean) => ReactNode);
}) {
  const patient = useSelector((state) => patientSelector(state, patientId));

  const currentUser = useCurrentUser();

  const shouldRender = featureCheck({
    currentUser,
    patient,
    rights,
    patientStatus,
    booleanMatch,
  });

  let nodes: any = [];

  if (typeof children === 'function') {
    nodes = children(shouldRender);
  } else if (shouldRender) {
    nodes = children;
  }

  return Children.map(nodes, (child) => cloneElement(child, extra));
}

export default FeatureFlag;
