import { fromJS, Map, Set } from 'immutable';

import {
  ARCHIVE_WOUND,
  CREATE_WOUND,
  GET_WOUND,
  GET_WOUND_TYPES,
  GET_WOUNDS,
  UPDATE_WOUND,
} from 'store/modules/entities/actions/woundcare';
import {
  CREATE_WOUND_EVALUATION,
  GET_WOUND_EVALUATION_OPTIONS,
  GET_WOUND_EVALUATIONS,
} from 'store/modules/entities/actions/woundcare/evaluation';

import sortInline from 'utils/sort';

const BY_ID = ['wounds', 'byId'];
const BY_PATIENT_ID = ['wounds', 'byPatientId'];

export default function woundCareReducer(state: any, action: any) {
  switch (action.type) {
    case GET_WOUND_TYPES.SUCCESS: {
      const {
        response: { entities },
      } = action.payload;

      const woundTypes = fromJS(entities.woundTypes);

      return state.setIn(['woundTypes'], woundTypes);
    }

    case GET_WOUND_EVALUATION_OPTIONS.SUCCESS: {
      const {
        response: { entities },
      } = action.payload;

      const woundBedOptions = fromJS(entities.woundBedOptions);
      const woundEdgeOptions = fromJS(entities.woundEdgeOptions);
      const woundMoistureOptions = fromJS(entities.woundMoistureOptions);

      return state
        .setIn(['woundBedOptions'], woundBedOptions)
        .setIn(['woundEdgeOptions'], woundEdgeOptions)
        .setIn(['woundMoistureOptions'], woundMoistureOptions);
    }

    case GET_WOUNDS.SUCCESS: {
      const {
        request: { patientId },
        response: { entities, result },
      } = action.payload;

      const byId = fromJS(entities.wounds);
      const byPatientId = Set(result.data);

      return state
        .mergeIn(BY_ID, byId)
        .setIn([...BY_PATIENT_ID, patientId], byPatientId);
    }

    case GET_WOUND.SUCCESS: {
      const {
        response: { entities },
      } = action.payload;

      const byId = fromJS(entities.wounds);

      return state.mergeIn(BY_ID, byId);
    }

    case CREATE_WOUND.SUCCESS: {
      const {
        patientId,
        response: { entities, result },
      } = action.payload;

      const byId = fromJS(entities.wounds);
      const woundId = result['wounds/wound'];
      const byPatientId = state
        .getIn([...BY_PATIENT_ID, patientId])
        .add(woundId);

      return state
        .mergeIn(BY_ID, byId)
        .setIn([...BY_PATIENT_ID, patientId], byPatientId);
    }

    case UPDATE_WOUND.SUCCESS: {
      const {
        response: { entities },
      } = action.payload;

      return state.mergeIn(BY_ID, fromJS(entities.wounds));
    }

    case ARCHIVE_WOUND.SUCCESS: {
      const { patientId, woundId } = action.payload;

      const byPatientId = state
        .getIn([...BY_PATIENT_ID, patientId])
        .filter((id) => id !== woundId);

      return state
        .removeIn([...BY_ID, woundId])
        .setIn([...BY_PATIENT_ID, patientId], byPatientId);
    }

    case GET_WOUND_EVALUATIONS.SUCCESS: {
      const {
        request: { woundId },
        response: { entities, result },
      } = action.payload;

      const byId = fromJS(entities.woundEvaluations);
      const byWoundId = Set(result.data);

      return state
        .mergeIn(['woundEvaluations', 'byId'], byId)
        .setIn(['woundEvaluations', 'byWoundId', woundId], byWoundId);
    }

    case CREATE_WOUND_EVALUATION.SUCCESS: {
      const {
        woundId,
        response: { entities, result },
      } = action.payload;

      const byId = fromJS(entities.woundEvaluations);
      const woundEvaluationId = result['wounds/woundEvaluation'];
      const byWoundId = state
        .getIn(['woundEvaluations', 'byWoundId', woundId])
        .add(woundEvaluationId);

      return state
        .mergeIn(['woundEvaluations', 'byId'], byId)
        .setIn(['woundEvaluations', 'byWoundId', woundId], byWoundId);
    }

    default: {
      return state;
    }
  }
}

export const woundTypesSelector = (state) => {
  const woundTypes = state.entities.getIn(['woundTypes'], Map()).toJS();

  return Object.keys(woundTypes).map((e) => woundTypes[e]);
};

const denormalizeWound = (woundTypes) => (wound) => {
  const woundType = woundTypes[wound.type];

  return {
    ...wound,
    type: woundType,
  };
};

export const woundsSelector = (state, patientId): WoundT[] | undefined => {
  const { wounds, woundTypes } = state.entities.toJS();
  const { byId, byPatientId } = wounds;

  if (!woundTypes || !wounds) {
    return;
  }

  const f = denormalizeWound(woundTypes);

  const denormalize = (id: string) => {
    const wound = byId[id];
    return f(wound);
  };

  const woundsByPatient = byPatientId[patientId];

  return woundsByPatient?.map(denormalize);
};

export const woundSelector = (state, woundId) => {
  const { wounds, woundTypes } = state.entities.toJS();
  const { byId } = wounds;

  const wound = byId[woundId];

  if (woundTypes === undefined || wound === undefined) {
    return undefined;
  }

  return wound ? denormalizeWound(woundTypes)(wound) : undefined;
};

export const woundEvaluationsSelector = (
  state,
  woundId
): WoundEvaluation[] | undefined => {
  const {
    woundEvaluations,
    woundBedOptions,
    woundEdgeOptions,
    woundMoistureOptions,
  } = state.entities.toJS();

  const { byId, byWoundId } = woundEvaluations;

  const denormalize = (id: string) => {
    const evaluation = byId[id];
    const bed = woundBedOptions[evaluation.bed];
    const edge = woundEdgeOptions[evaluation.edge];
    const moisture = woundMoistureOptions[evaluation.moisture];

    return {
      ...evaluation,
      bed,
      edge,
      moisture,
    };
  };

  const evaluationsForWound = byWoundId[woundId];

  return evaluationsForWound
    ?.map(denormalize)
    .sort(sortInline('createdAt', 'desc'));
};

export const woundEvaluationOptionsSelector = (state) => {
  const { woundBedOptions, woundEdgeOptions, woundMoistureOptions } =
    state.entities.toJS();

  const denormalize = (options) =>
    Object.keys(options || {}).map((e) => options[e]);

  return {
    woundBedOptions: denormalize(woundBedOptions),
    woundEdgeOptions: denormalize(woundEdgeOptions),
    woundMoistureOptions: denormalize(woundMoistureOptions),
  };
};
