import { fromJS } from 'immutable';

import {
  CREATE_PATIENT_INSURANCE,
  DELETE_PATIENT_INSURANCE,
  GET_PATIENT_INSURANCES,
  UPDATE_PATIENT_INSURANCE,
} from 'store/modules/entities/actions/patients/insurances';

import sortInline from 'utils/sort';

const BY_ID = ['patientInsurances', 'byId'];
const BY_PATIENT_ID = ['patientInsurances', 'byPatientId'];

export default function patientInsurancesReducer(state: any, action: any) {
  switch (action.type) {
    case GET_PATIENT_INSURANCES.SUCCESS: {
      const {
        patientId,
        response: { entities, result },
      } = action.payload;

      return state
        .mergeIn(BY_ID, fromJS(entities.patientInsurances || {}))
        .setIn([...BY_PATIENT_ID, patientId], fromJS(result.patientInsurances));
    }

    case CREATE_PATIENT_INSURANCE.SUCCESS: {
      const {
        patientId,
        response: { entities, result },
      } = action.payload;

      const byPatientId = state
        .getIn([...BY_PATIENT_ID, patientId])
        .push(result.patientInsurance);

      return state
        .mergeIn(BY_ID, fromJS(entities.patientInsurances))
        .setIn([...BY_PATIENT_ID, patientId], byPatientId);
    }

    case UPDATE_PATIENT_INSURANCE.SUCCESS: {
      const {
        response: { entities },
      } = action.payload;

      return state.mergeIn(BY_ID, fromJS(entities.patientInsurances));
    }

    case DELETE_PATIENT_INSURANCE.SUCCESS: {
      const {
        request: { id },
        patientId,
      } = action.payload;

      const byPatientId = state
        .getIn([...BY_PATIENT_ID, patientId])
        .filter((patientInsuranceId) => patientInsuranceId !== id);

      return state
        .removeIn([...BY_ID, id])
        .setIn([...BY_PATIENT_ID, patientId], byPatientId);
    }

    default: {
      return state;
    }
  }
}

export const patientInsurancesSelector = (
  state: any,
  patientId: string
): PatientInsuranceT[] | null | undefined => {
  const {
    patientInsurances: { byId, byPatientId },
  } = state.entities.toJS();

  const patientInsurances = byPatientId[patientId];

  if (!patientInsurances) return undefined;

  return patientInsurances
    .map((id) => byId[id])
    .sort(sortInline('createdAt', 'desc'));
};
