import { schema } from 'normalizr';

import { parseDate, parseDateTime, schemaDeserializers } from 'api/deserialize';

import ambulatory from 'store/modules/entities/reducers/medication/ambulatory';
import { networkActionTypes } from 'store/utils';

export const medicinalProductProviderSchema = new schema.Entity(
  'medicinalProductProviders',
  {},
  {
    processStrategy: schemaDeserializers({
      publishedAt: parseDate,
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

export const GET_MEDICINAL_PRODUCT_PROVIDERS = networkActionTypes(
  'GET_MEDICINAL_PRODUCT_PROVIDERS'
);

export const getMedicinalProductProviders = (
  enabled?: boolean
): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_MEDICINAL_PRODUCT_PROVIDERS,
    url: 'medications/sources',
    method: 'GET',
    params: { enabled },
    normalizeSchema: { sources: [medicinalProductProviderSchema] },
  },
});

export const CREATE_MEDICINAL_PRODUCT_PROVIDER = networkActionTypes(
  'CREATE_MEDICINAL_PRODUCT_PROVIDER'
);

export const createMedicinalProductProvider = (source: {
  name: string;
  description: string;
  enabled: boolean;
  syncable: boolean;
  intradialytic: boolean;
  ambulatory: boolean;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_MEDICINAL_PRODUCT_PROVIDER,
    url: 'medications/sources',
    method: 'POST',
    payload: { source: { ...source, home: ambulatory } },
    normalizeSchema: { source: medicinalProductProviderSchema },
  },
});

export const UPDATE_MEDICINAL_PRODUCT_PROVIDER = networkActionTypes(
  'UPDATE_MEDICINAL_PRODUCT_PROVIDER'
);

export const updateMedicinalProductProvider = (source: {
  id: string;
  description: string;
  enabled: boolean;
  syncable: boolean;
  intradialytic: boolean;
  ambulatory: boolean;
}): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_MEDICINAL_PRODUCT_PROVIDER,
    url: `medications/sources/${source.id}`,
    method: 'PUT',
    payload: { source: { ...source, home: ambulatory } },
    normalizeSchema: { source: medicinalProductProviderSchema },
  },
});

export const DELETE_MEDICINAL_PRODUCT_PROVIDER = networkActionTypes(
  'DELETE_MEDICINAL_PRODUCT_PROVIDER'
);

export const deleteMedicinalProductProvider = (id: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_MEDICINAL_PRODUCT_PROVIDER,
    url: `medications/sources/${id}`,
    method: 'DELETE',
    actionPayload: { id },
  },
});
