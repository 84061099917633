import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import locationChange from 'store/modules/router';

export default function RouteWatcher() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(locationChange(pathname));
  }, [dispatch, pathname]);

  return null;
}
