import {
  UPDATE_CARE_TEAM_SUCCESS,
  UPDATE_PATIENT_ALLERGIES_KNOWN,
  UPDATE_PATIENT_AVATAR,
  UPDATE_PATIENT_CONTACT_INFO,
  UPDATE_PATIENT_DISORDER,
  UPDATE_PATIENT_HOME_NURSING,
  UPDATE_PATIENT_MEDICAL_INFO,
  UPDATE_PATIENT_RESIDENCE_TYPE,
  UPDATE_PATIENT_TRANSPORT_INFO,
} from 'store/modules/entities/actions/patients';
import { UPDATE_PATIENT_TRANSPLANT_ELIGIBILITY_STATUS } from 'store/modules/entities/actions/patients/transplantStatus';
import addressReducers from 'store/modules/entities/reducers/patients/addresses';
import patientDeactivationReducers from 'store/modules/entities/reducers/patients/deactivations';
import identifierReducers from 'store/modules/entities/reducers/patients/identifiers';
import patientInsurances from 'store/modules/entities/reducers/patients/insurances';
import patientReducers, {
  mergePatientCareTeamHandler,
  mergePatientHandler,
} from 'store/modules/entities/reducers/patients/patients';
import updatePatientResidenceType from 'store/modules/entities/reducers/patients/updatePatientResidenceType';
import updatePatientTransplantStatus from 'store/modules/entities/reducers/patients/updatePatientTransplantStatus';
import updatePatientTransport from 'store/modules/entities/reducers/patients/updatePatientTransport';
import { combineReducers } from 'store/utils';

export default combineReducers(
  indexReducer,
  addressReducers,
  identifierReducers,
  patientReducers,
  patientDeactivationReducers,
  patientInsurances
);

function indexReducer(state: any, action: any) {
  switch (action.type) {
    case UPDATE_PATIENT_TRANSPORT_INFO.SUCCESS: {
      return updatePatientTransport(state, action);
    }

    case UPDATE_PATIENT_TRANSPLANT_ELIGIBILITY_STATUS.SUCCESS: {
      return updatePatientTransplantStatus(state, action);
    }

    case UPDATE_PATIENT_RESIDENCE_TYPE.SUCCESS: {
      return updatePatientResidenceType(state, action);
    }

    case UPDATE_PATIENT_AVATAR.SUCCESS: {
      return mergePatientHandler(state, action);
    }

    case UPDATE_PATIENT_DISORDER.SUCCESS: {
      return mergePatientHandler(state, action);
    }

    case UPDATE_PATIENT_CONTACT_INFO.SUCCESS: {
      return mergePatientHandler(state, action);
    }

    case UPDATE_PATIENT_MEDICAL_INFO.SUCCESS: {
      return mergePatientHandler(state, action);
    }

    case UPDATE_PATIENT_ALLERGIES_KNOWN.SUCCESS: {
      return mergePatientHandler(state, action);
    }

    case UPDATE_PATIENT_HOME_NURSING.SUCCESS: {
      return mergePatientHandler(state, action);
    }

    case UPDATE_CARE_TEAM_SUCCESS: {
      return mergePatientCareTeamHandler(state, action);
    }

    default: {
      return state;
    }
  }
}
