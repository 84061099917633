import { schema } from 'normalizr';

import {
  parseDateTime,
  parseFloat,
  schemaDeserializers,
} from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const PAGE_SIZE = 30;

const medicationValidationSchema = new schema.Entity(
  'medicationValidations',
  {},
  {
    processStrategy: (validation) => ({
      ...validation,
      ...schemaDeserializers({
        performedAt: parseDateTime,
        suggestedAt: parseDateTime,
        updatedAt: parseDateTime,
      })(validation),
      performedBy: validation.performedBy
        ? {
            ...validation.performedBy,
            ...schemaDeserializers({
              createdBy: parseDateTime,
              updatedBy: parseDateTime,
            })(validation.performedBy),
          }
        : null,
      suggestedBy: validation.suggestedBy
        ? {
            ...validation.suggestedBy,
            ...schemaDeserializers({
              createdBy: parseDateTime,
              updatedBy: parseDateTime,
            })(validation.suggestedBy),
          }
        : null,
    }),
  }
);

export const medicationAdministrationSchema = new schema.Entity(
  'administrations',
  {
    medicationValidation: medicationValidationSchema,
  },
  {
    processStrategy: (administration) => ({
      ...administration,
      ...schemaDeserializers({
        createdAt: parseDateTime,
        updatedAt: parseDateTime,
        administeredAt: parseDateTime,
        quantity: parseFloat,
      })(administration),
      createdBy: {
        ...administration.createdBy,
        ...schemaDeserializers({
          createdAt: parseDateTime,
          updatedAt: parseDateTime,
        })(administration.createdBy),
      },
      administeredBy: administration.administeredBy
        ? {
            ...administration.administeredBy,
            ...schemaDeserializers({
              createdAt: parseDateTime,
              updatedAt: parseDateTime,
            })(administration.administeredBy),
          }
        : null,
    }),
  }
);

export const GET_MEDICATION_ADMINISTRATIONS = networkActionTypes(
  'GET_MEDICATION_ADMINISTRATIONS'
);

type GetMedicationAdministrationsArgsT = {
  page: number;
  patientId: string;
  createdAt: { from: Date | null | undefined; to: Date | null | undefined };
  query: string;
  status?: string;
  validationStatuses?: string[];
};

export const getMedicationAdministrations = ({
  patientId,
  createdAt,
  query,
  status,
  validationStatuses,
  page = 0,
}: GetMedicationAdministrationsArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_MEDICATION_ADMINISTRATIONS,
    url: `patients/${patientId}/medication_administrations`,
    method: 'GET',
    params: {
      page,
      pageSize: PAGE_SIZE,
      query: query || undefined,
      status: status || 'all',
      validationStatuses,
      to: createdAt.to,
      from: createdAt.from,
    },
    actionPayload: { patientId },
    normalizeSchema: {
      data: [medicationAdministrationSchema],
    },
  },
});

export const CREATE_MEDICATION_ADMINISTRATION = networkActionTypes(
  'CREATE_MEDICATION_ADMINISTRATION'
);

export type CreateMedicationAdministrationT = {
  patientId: string;
  prescriptionId: string;
  quantity: number;
  administeredById: string;
  administeredAt: Date;
};

export const createMedicationAdministration = ({
  patientId,
  ...medicationAdministration
}: CreateMedicationAdministrationT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_MEDICATION_ADMINISTRATION,
    url: `patients/${patientId}/medication_administrations`,
    method: 'POST',
    payload: {
      medicationAdministration,
    },
    actionPayload: { patientId },
    normalizeSchema: {
      medicationAdministration: medicationAdministrationSchema,
    },
  },
});

export const UPDATE_MEDICATION_ADMINISTRATION = networkActionTypes(
  'UPDATE_MEDICATION_ADMINISTRATION'
);

export type UpdateMedicationAdministrationArgsT = {
  id: string;
  administeredAt: Date;
  administeredById: string;
  prescribableUnitId: string;
  quantity: number;
  quantityToBill: number;
  sessionId: string;
  status: string;
  notGivenReasonId: string;
};

export const updateMedicationAdministration = ({
  id,
  ...medicationAdministration
}: UpdateMedicationAdministrationArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_MEDICATION_ADMINISTRATION,
    url: `medications/medication_administrations/${id}`,
    method: 'PUT',
    payload: {
      medicationAdministration,
    },
    normalizeSchema: {
      medicationAdministration: medicationAdministrationSchema,
    },
  },
});

export const DELETE_MEDICATION_ADMINISTRATION = networkActionTypes(
  'DELETE_MEDICATION_ADMINISTRATION'
);

export type DeleteMedicationAdministrationArgsT = {
  patientId: string;
  administrationId: string;
  validationId?: string;
};

export const deleteMedicationAdministration = ({
  patientId,
  administrationId,
  validationId,
}: DeleteMedicationAdministrationArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_MEDICATION_ADMINISTRATION,
    url: `medications/medication_administrations/${administrationId}`,
    method: 'DELETE',
    actionPayload: { patientId, administrationId, validationId },
  },
});

export const VALIDATE_MEDICATION_ADMINISTRATION = networkActionTypes(
  'VALIDATE_MEDICATION_ADMINISTRATION'
);

export type ValidateMedicationAdministrationArgsT = {
  administrationId: string;
  patientId: string;
  status: string;
  rejectionReason?: string;
};

export const validateMedicationAdministration = ({
  administrationId,
  patientId,
  status,
  rejectionReason,
}: ValidateMedicationAdministrationArgsT): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: VALIDATE_MEDICATION_ADMINISTRATION,
    url: `patients/${patientId}/medication_administrations/${administrationId}/validate`,
    method: 'PATCH',
    payload: {
      validation: {
        status,
        rejectionReason,
      },
    },
    normalizeSchema: {
      medicationValidation: medicationValidationSchema,
    },
  },
});
