import { startActivity } from 'store/modules/activity';

import { ReportT } from './fetchers';

export const ADD_REPORT = 'ADD_REPORT';
export const REMOVE_REPORT = 'REMOVE_REPORT';

export const CREATE_REPORT_FAILURE = 'CREATE_REPORT_FAILURE';
export const DELETE_REPORT_FAILURE = 'DELETE_REPORT_FAILURE';

export const startAddReportActivity = () => startActivity(ADD_REPORT);
export const startRemoveReportActivity = (report: ReportT) =>
  startActivity(REMOVE_REPORT, { report });
