import { ComplicationT } from 'api/fetchers/types';

import { startActivity } from 'store/modules/activity';

import { AccessSiteT } from './fetchers';

export const EDIT_CATHETER_CARE_PRESCRIPTION =
  'EDIT_CATHETER_CARE_PRESCRIPTION';

export const ADD_TREATMENT_ACCESS_SITE = 'ADD_TREATMENT_ACCESS_SITE';
export const EDIT_TREATMENT_ACCESS_SITE = 'EDIT_TREATMENT_ACCESS_SITE';
export const REMOVE_TREATMENT_ACCESS_SITE = 'REMOVE_TREATMENT_ACCESS_SITE';

export const REPLACE_CATHETER_SITE = 'REPLACE_CATHETER_SITE';

export const ADD_ACCESS_SITE_COMPLICATION = 'ADD_ACCESS_SITE_COMPLICATION';
export const EDIT_ACCESS_SITE_COMPLICATION = 'EDIT_ACCESS_SITE_COMPLICATION';
export const REMOVE_COMPLICATION = 'REMOVE_COMPLICATION';

export const UPDATE_CATHETER_CARE_PRESCRIPTION_FAILURE =
  'UPDATE_CATHETER_CARE_PRESCRIPTION_FAILURE';

export const CREATE_TREATMENT_ACCESS_SITE_FAILURE =
  'CREATE_TREATMENT_ACCESS_SITE_FAILURE';
export const UPDATE_TREATMENT_ACCESS_SITE_FAILURE =
  'UPDATE_TREATMENT_ACCESS_SITE_FAILURE';

export const REPLACE_CATHETER_FAILURE = 'REPLACE_CATHETER_FAILURE';

export const CREATE_ACCESS_SITE_COMPLICATION_FAILURE =
  'CREATE_ACCESS_SITE_COMPLICATION_FAILURE';
export const UPDATE_ACCESS_SITE_COMPLICATION_FAILURE =
  'UPDATE_ACCESS_SITE_COMPLICATION_FAILURE';

export const startEditCatheterCarePrescriptionActivity = () =>
  startActivity(EDIT_CATHETER_CARE_PRESCRIPTION);

export const startAddAccessSiteActivity = () =>
  startActivity(ADD_TREATMENT_ACCESS_SITE);

export const startEditAccessSiteActivity = (site: AccessSiteT) =>
  startActivity(EDIT_TREATMENT_ACCESS_SITE, { site });

export const startRemoveAccessSiteActivity = (
  treatmentAccessSite: AccessSiteT
) => startActivity(REMOVE_TREATMENT_ACCESS_SITE, { treatmentAccessSite });

export const startReplaceCatheterActivity = (site: AccessSiteT) =>
  startActivity(REPLACE_CATHETER_SITE, { site });

export const startAddAccessSiteComplicationActivity = () =>
  startActivity(ADD_ACCESS_SITE_COMPLICATION);

export const startEditAccessSiteComplicationActivity = (
  complication: ComplicationT
) => startActivity(EDIT_ACCESS_SITE_COMPLICATION, { complication });

export const startRemoveAccessSiteComplicationActivity = (
  complication: ComplicationT
) => startActivity(REMOVE_COMPLICATION, { complication });
