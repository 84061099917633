import {
  ComponentType,
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IntlShape } from 'react-intl';
import { useLocation } from 'react-router-dom';

import {
  useRecordEnabled,
  useTrackEnabled,
  useWorkbenchEnabled,
} from 'hooks/useConfig';

import admin, { useAdminEnabled } from 'views/admin';
import record from 'views/record';
import track from 'views/track';
import workbench from 'views/workbench';

import { RightClaimT } from 'rights';

export interface Perspective {
  name: string;
  title: (intl: IntlShape) => string;
  path: string;
  rights?: RightClaimT[];
  isEnabled?: boolean;
  searchEnabled: boolean;
  Icon: ComponentType<{}>;
  Routes: ComponentType<{}>;
  Links: ComponentType<{}>;
}

interface PerspectiveContextState {
  perspectives: Perspective[];
  currentPerspective?: Perspective;
  redirectPath: string;
}

interface Props {
  children: ReactNode;
}

// Do not provide a default value to the context.
// This fallback is used for components rendered outside of the context provider
// but this is something we don't do and our context hook throws an error in
// this case anyway.
const PerspectiveContext = createContext<PerspectiveContextState | undefined>(
  undefined
);

const perspectives = [record, workbench, track, admin];

const FALLBACK_PATH = '/not_found';

export default function PerspectiveProvider({ children }: Props) {
  const { pathname } = useLocation();

  const workbenchEnabled = useWorkbenchEnabled();
  const recordEnabled = useRecordEnabled();
  const trackEnabled = useTrackEnabled();
  const adminEnabled = useAdminEnabled(admin.rights as RightClaimT[]);

  const [enabledPerspectives, setEnabledPerspectives] = useState(perspectives);
  const [currentPerspective, setCurrentPerspective] = useState<Perspective>();

  useEffect(() => {
    const enabled = {
      record: recordEnabled,
      workbench: workbenchEnabled,
      track: trackEnabled,
      admin: adminEnabled,
    };

    setEnabledPerspectives(perspectives.filter(({ name }) => enabled[name]));
  }, [recordEnabled, workbenchEnabled, trackEnabled, adminEnabled]);

  useEffect(() => {
    setCurrentPerspective(
      enabledPerspectives.find(({ name }) => name === pathname.split('/')[1])
    );
  }, [pathname, enabledPerspectives]);

  return (
    <PerspectiveContext.Provider
      value={{
        perspectives: enabledPerspectives,
        redirectPath: enabledPerspectives[0]?.path || FALLBACK_PATH,
        currentPerspective,
      }}
    >
      {children}
    </PerspectiveContext.Provider>
  );
}

export function usePerspectives() {
  const context = useContext(PerspectiveContext);

  if (context === undefined) {
    throw new Error(
      'The hook `usePerspectives` must be used within a `PerspectiveProvider`.'
    );
  }

  return context;
}
