import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useAuthenticator } from 'auth/authenticator';
import { localStorageKey, retrieveToken } from 'auth/authLocalStorage';
import { AuthCredentials, UserToken } from 'auth/types';
import { authenticated, getJwtUserId } from 'auth/utils';

import { httpInterceptor } from 'api/client';

interface AuthContextState {
  login: (credentials: AuthCredentials) => Promise<void>;
  logout: () => void;
  currentUserId: string | undefined;
  accessToken: string | null;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextState | undefined>(undefined);

AuthContext.displayName = 'AuthContext';

export default function AuthProvider({ children }: { children: ReactNode }) {
  const { userToken, isReady, login, logout } = useAuthSetup();

  const authValues = {
    login,
    logout,
    currentUserId: getJwtUserId(userToken),
    accessToken: userToken?.access || null,
    isAuthenticated: authenticated(userToken),
  };

  if (!isReady) {
    return null;
  }

  return (
    <AuthContext.Provider value={authValues}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(
      'The hook `useAuth` must be used within an `AuthProvider`.'
    );
  }

  return context;
}

function useAuthSetup() {
  const authenticator = useAuthenticator();

  //Add this check, because interceptor was not yet set when fetching current user
  const [isReady, setIsReady] = useState(false);
  const [userToken, setUserToken] = useState<UserToken>(retrieveToken());

  const refreshSuccess = useCallback((token: UserToken) => {
    setUserToken((prevToken) => ({
      ...prevToken,
      ...token,
    }));
  }, []);

  const login = useCallback(
    async (credentials: AuthCredentials) => {
      const token = await authenticator().login(credentials);
      setUserToken(token);
    },
    [authenticator]
  );

  const logout = useCallback(async () => {
    await authenticator().logout();
    sessionStorage.clear();
    goToLogin();
  }, [authenticator]);

  useEffect(() => {
    function handleCrossTab(event: StorageEvent) {
      if (event.isTrusted && event.key === localStorageKey) {
        if (event.newValue) {
          setUserToken(JSON.parse(event.newValue));
        } else {
          goToLogin();
        }
      }
    }

    window.addEventListener('storage', handleCrossTab);

    return () => {
      window.removeEventListener('storage', handleCrossTab);
    };
  }, []);

  useEffect(() => {
    httpInterceptor(refreshSuccess, goToLogin);
    setIsReady(true);
  }, [refreshSuccess]);

  return {
    isReady,
    userToken,
    login,
    logout,
  };
}

function goToLogin() {
  window.location.assign('/login');
}
