import { EncounterTypeT } from 'api/fetchers/types';

import { startActivity } from 'store/modules/activity';

export const ADD_ENCOUNTER_TYPE = 'ADD_ENCOUNTER_TYPE';
export const EDIT_ENCOUNTER_TYPE = 'EDIT_ENCOUNTER_TYPE';
export const REMOVE_ENCOUNTER_TYPE = 'REMOVE_ENCOUNTER_TYPE';

export const CREATE_ENCOUNTER_TYPE_FAILURE = 'CREATE_ENCOUNTER_TYPE_FAILURE';
export const UPDATE_ENCOUNTER_TYPE_FAILURE = 'UPDATE_ENCOUNTER_TYPE_FAILURE';
export const DELETE_ENCOUNTER_TYPE_FAILURE = 'DELETE_ENCOUNTER_TYPE_FAILURE';

export const startAddEncounterTypeActivity = () =>
  startActivity(ADD_ENCOUNTER_TYPE);

export const startEditEncounterTypeActivity = (encounterType: EncounterTypeT) =>
  startActivity(EDIT_ENCOUNTER_TYPE, { encounterType });

export const startRemoveEncounterTypeActivity = (
  encounterType: EncounterTypeT
) => startActivity(REMOVE_ENCOUNTER_TYPE, { encounterType });
