import { EncounterT } from 'api/fetchers/types';

import { startActivity } from 'store/modules/activity';

export const ADD_ENCOUNTER = 'ADD_ENCOUNTER';
export const EDIT_ENCOUNTER = 'EDIT_ENCOUNTER';
export const REMOVE_ENCOUNTER = 'REMOVE_ENCOUNTER';

export const CREATE_ENCOUNTER_FAILURE = 'CREATE_ENCOUNTER_FAILURE';
export const UPDATE_ENCOUNTER_FAILURE = 'UPDATE_ENCOUNTER_FAILURE';
export const DELETE_ENCOUNTER_FAILURE = 'DELETE_ENCOUNTER_FAILURE';

export const ADD_ENCOUNTER_DOCUMENT = 'ADD_ENCOUNTER_DOCUMENT';
export const REMOVE_ENCOUNTER_DOCUMENT = 'REMOVE_ENCOUNTER_DOCUMENT';

export const CREATE_ENCOUNTER_DOCUMENT_FAILURE =
  'CREATE_ENCOUNTER_DOCUMENT_FAILURE';
export const DELETE_ENCOUNTER_DOCUMENT_FAILURE =
  'DELETE_ENCOUNTER_DOCUMENT_FAILURE';

export const startAddEncounterActivity = () => startActivity(ADD_ENCOUNTER);

export const startEditEncounterActivity = (encounter: EncounterT) =>
  startActivity(EDIT_ENCOUNTER, { encounter });

export const startRemoveEncounterActivity = (encounter: EncounterT) =>
  startActivity(REMOVE_ENCOUNTER, { encounter });

export const startAddEncounterDocumentActivity = () =>
  startActivity(ADD_ENCOUNTER_DOCUMENT);

export const startRemoveEncounterDocumentActivity = (encounterDocument: {
  id: string;
}) => startActivity(REMOVE_ENCOUNTER_DOCUMENT, { encounterDocument });
