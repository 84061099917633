import { fromJS, Set } from 'immutable';
import { DefaultRootState } from 'react-redux';

import {
  CREATE_EXPORTABLE,
  DROP_BILLING_ITEM,
  GET_EXPORTABLE,
  GET_EXPORTABLE_BILLING_ITEMS,
  GET_EXPORTABLES,
  READY_EXPORTABLE,
  SYNC_EXPORTABLE,
} from 'store/modules/entities/actions/exportables';
import {
  CREATE_BILLING_ITEM,
  DELETE_BILLING_ITEM,
  SYNC_BILLING_ITEM,
  UPDATE_BILLING_ITEM,
} from 'store/modules/entities/actions/exportables/billingItems';

import omitNullable from 'utils/omitNullable';
import sortInline from 'utils/sort';

import { EntitiesState } from '.';

const PATH = ['exportCenter'];
const PATIENTS_PATH = [...PATH, 'patients'];
const EXPORTABLES_PATH = [...PATH, 'exportables'];
const BILLING_ITEMS_PATH = [...PATH, 'billingItems'];

type RawExportableT = Omit<ExportableT, 'billingItems'> & {
  billingItems: string[];
};

export default function exportCenterReducer(state: EntitiesState, action: any) {
  switch (action.type) {
    case GET_EXPORTABLES.SUCCESS: {
      const {
        response: {
          entities: { exportables = {} },
        },
      } = action.payload;

      const exportableIds = Object.keys(exportables);

      return state
        .mergeDeepIn([...EXPORTABLES_PATH, 'byId'], fromJS(exportables))
        .setIn([...EXPORTABLES_PATH, 'byResult'], Set(exportableIds));
    }

    case CREATE_EXPORTABLE.SUCCESS: {
      const {
        response: {
          entities: { exportables = {} },
          result: { exportable: exportableId },
        },
      } = action.payload;

      const exportable = exportables[exportableId];

      const exportableMatchesFilter = action.payload
        .exportableMatchesFilter as (exportable: ExportableT) => boolean;

      return state
        .mergeDeepIn([...EXPORTABLES_PATH, 'byId'], fromJS(exportables))
        .updateIn(
          [...EXPORTABLES_PATH, 'byResult'],
          (set: Set<string> = Set()) =>
            exportableMatchesFilter(exportable) ? set.add(exportableId) : set
        );
    }

    case GET_EXPORTABLE.SUCCESS: {
      const {
        response: {
          entities: { exportables = {} },
          result: { exportable: id },
        },
      } = action.payload;

      const exportable = exportables[id];

      return state
        .mergeIn([...EXPORTABLES_PATH, 'byId', id], fromJS(exportable))
        .updateIn([...EXPORTABLES_PATH, 'byResult'], (set: Set<string>) =>
          Set([id]).concat(set)
        );
    }

    case GET_EXPORTABLE_BILLING_ITEMS.SUCCESS: {
      const {
        response: {
          entities: { billingItems = {}, patients = {} },
          result: { data: billingItemIds = [] },
        },
        exportableId,
      } = action.payload;

      return state
        .updateIn(
          [...EXPORTABLES_PATH, 'byId', exportableId, 'billingItems'],
          () => Set(billingItemIds)
        )
        .mergeIn(BILLING_ITEMS_PATH, fromJS(billingItems))
        .mergeDeepIn(PATIENTS_PATH, fromJS(patients));
    }

    case CREATE_BILLING_ITEM.SUCCESS: {
      const {
        response: {
          entities: { billingItems = {}, patients = {} },
          result: { billingItem: id },
        },
        exportableId,
      } = action.payload;

      return state
        .updateIn(
          [...EXPORTABLES_PATH, 'byId', exportableId, 'billingItems'],
          (set: Set<string> = Set()) => set.add(id)
        )
        .mergeIn(BILLING_ITEMS_PATH, fromJS(billingItems))
        .mergeDeepIn(PATIENTS_PATH, fromJS(patients));
    }

    case DELETE_BILLING_ITEM.SUCCESS: {
      const { itemId, exportableId } = action.payload;

      return state.updateIn(
        [...EXPORTABLES_PATH, 'byId', exportableId, 'billingItems'],
        (set: Set<string> = Set()) => set.remove(itemId)
      );
    }

    case READY_EXPORTABLE.SUCCESS: {
      const {
        response: {
          entities: { exportables = {} },
        },
      } = action.payload;

      return state.mergeDeepIn(
        [...EXPORTABLES_PATH, 'byId'],
        fromJS(exportables)
      );
    }

    case DROP_BILLING_ITEM.SUCCESS: {
      const { exportableId, itemId } = action.payload as {
        exportableId: string;
        itemId: string;
      };

      return state
        .updateIn(
          [...EXPORTABLES_PATH, 'byId', exportableId, 'billingItems'],
          (set: Set<string> = Set()) => set.remove(itemId)
        )
        .removeIn([...BILLING_ITEMS_PATH, itemId]);
    }

    case SYNC_EXPORTABLE.SUCCESS: {
      const {
        response: {
          entities: { exportables = {} },
        },
      } = action.payload;

      return state.mergeIn([...EXPORTABLES_PATH, 'byId'], fromJS(exportables));
    }

    case SYNC_BILLING_ITEM.SUCCESS:
    case UPDATE_BILLING_ITEM.SUCCESS: {
      const {
        response: {
          entities: { billingItems = {} },
        },
      } = action.payload;

      let nextState = state;

      nextState = nextState.mergeDeepIn(
        BILLING_ITEMS_PATH,
        fromJS(billingItems)
      );

      Object.keys(billingItems).forEach((id) => {
        nextState = nextState.setIn(
          [...BILLING_ITEMS_PATH, id, 'errors'],
          fromJS(billingItems[id].errors)
        );
      });

      return nextState;
    }

    default: {
      return state;
    }
  }
}

export const exportableSelector = (
  state: DefaultRootState,
  exportableId: string | null | undefined
) => {
  if (!exportableId) return undefined;

  const {
    exportCenter: {
      exportables: { byId },
    },
  } = state.entities.toJS();

  if (!byId || !byId[exportableId]) return undefined;

  const { billingItems: _discard, ...exportable } = byId[exportableId];

  if (Object.keys(exportable).length === 0) return undefined;

  return exportable as ExportableT;
};

export const exportableBillingItemsSelector = (
  state: DefaultRootState,
  exportableId: string | null | undefined
) => {
  if (!exportableId) return undefined;

  const {
    exportables: { byId },
    billingItems,
    patients,
  } = state.entities.getIn(PATH).toJS();

  if (!byId || !byId[exportableId]) return undefined;

  const exportable = byId[exportableId] as RawExportableT;

  const result: ExportableItemT[] = exportable.billingItems
    ? exportable.billingItems
        .map((id) => billingItems[id])
        .filter(Boolean)
        .map((item) => ({
          ...item,
          patient: item.patient ? patients[item.patient] : null,
        }))
        .sort(sortInline('administeredAt'))
    : [];

  return result;
};

export const exportablesSelector = (state: DefaultRootState) => {
  const { byId, byResult } = state.entities.getIn(EXPORTABLES_PATH).toJS() as {
    byId: Record<string, RawExportableT>;
    byResult: string[];
  };

  if (!byId || !byResult) return undefined;

  return omitNullable(byResult.map((id) => byId[id]))
    .map<ExportableT>((e) => ({
      ...e,
      exportType: e.exportType as any,
      billingItems: [],
    }))
    .sort(sortInline('createdAt', 'desc'));
};
