import {
  IMPORT_PATIENTS,
  IMPORT_PATIENTS_STATUS_REPORT,
  IMPORT_PATIENTS_STATUS_UPLOAD,
} from 'store/modules/entities/actions/imports/patients';
import {
  IMPORT_USERS,
  IMPORT_USERS_STATUS_REPORT,
  IMPORT_USERS_STATUS_UPLOAD,
} from 'store/modules/entities/actions/imports/users';

export type ImportStatusesT = {
  statusUpload: 'available' | 'processing' | null | undefined;
  statusReport: 'available' | 'not_available' | null | undefined;
};

export default function importsReducer(state: any, action: any) {
  switch (action.type) {
    case IMPORT_PATIENTS.SUCCESS: {
      return state.setIn(
        ['imports', 'patients', 'statusUpload'],
        action.payload.response.message
      );
    }

    case IMPORT_PATIENTS_STATUS_UPLOAD.SUCCESS: {
      return state.setIn(
        ['imports', 'patients', 'statusUpload'],
        action.payload.response.message
      );
    }

    case IMPORT_PATIENTS_STATUS_REPORT.SUCCESS: {
      return state.setIn(
        ['imports', 'patients', 'statusReport'],
        action.payload.response.status
      );
    }

    case IMPORT_USERS.SUCCESS: {
      return state.setIn(
        ['imports', 'users', 'statusUpload'],
        action.payload.response.message
      );
    }

    case IMPORT_USERS_STATUS_UPLOAD.SUCCESS: {
      return state.setIn(
        ['imports', 'users', 'statusUpload'],
        action.payload.response.message
      );
    }

    case IMPORT_USERS_STATUS_REPORT.SUCCESS: {
      return state.setIn(
        ['imports', 'users', 'statusReport'],
        action.payload.response.status
      );
    }

    default: {
      return state;
    }
  }
}

export const importPatientsStatuses = (state: any): ImportStatusesT => {
  const {
    imports: { patients: patientsStatuses },
  } = state.entities.toJS();
  return patientsStatuses;
};

export const importUsersStatuses = (state: any): ImportStatusesT => {
  const {
    imports: { users: usersStatuses },
  } = state.entities.toJS();
  return usersStatuses;
};
