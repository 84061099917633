import { lazy } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';

import { Perspective } from 'providers';

import FeatureFlag from 'components/FeatureFlag';
import Icon from 'components/Icon';

import RequireAuth from 'sharedComponents/RequireAuth';

import { RIGHTS } from 'rights';

const t = defineMessages({
  record: {
    id: 'navigation_perspective_record_title',
    defaultMessage: 'Record',
  },
  dashboard: {
    id: 'navigation_ongoing_sessions',
    defaultMessage: 'Ongoing sessions',
  },
  recentSessions: {
    id: 'navigation_recent_sessions',
    defaultMessage: 'Recent sessions',
  },
  patients: {
    id: 'navigation_patients',
    defaultMessage: 'Patients',
  },
  planning: {
    id: 'navigation_planning',
    defaultMessage: 'Planning',
  },
  briefings: {
    id: 'navigation_briefings',
    defaultMessage: 'Briefings',
  },
  reports: {
    id: 'navigation_reports',
    defaultMessage: 'Reports',
  },
  settings: {
    id: 'navigation_settings',
    defaultMessage: 'Settings',
  },
  prescriptions: {
    id: 'navigation_prescriptions',
    defaultMessage: 'Prescriptions',
  },
  exportCenter: {
    id: 'navigation_export_center',
    defaultMessage: 'Export center',
  },
});

const Dashboard = lazy(() => import('./components/Dashboard'));
const RecentSessions = lazy(() => import('./recentSessions'));
const Patients = lazy(() => import('./patients'));
const Briefings = lazy(() => import('./briefings'));
const Planning = lazy(() => import('./planning'));
const Reports = lazy(() => import('./reports'));
const ExportCenter = lazy(() => import('./exportCenter'));
const Settings = lazy(() => import('./settings'));
const Prescriptions = lazy(() => import('./prescriptions'));
const Profile = lazy(() => import('./profile'));

function MyRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="recent_sessions" element={<RecentSessions />} />
      <Route path="patients/*" element={<Patients />} />

      <Route
        path="planning"
        element={
          <RequireAuth rights={[RIGHTS.VIEW_PLANNING]}>
            <Planning />
          </RequireAuth>
        }
      />

      <Route
        path="briefings/*"
        element={
          <RequireAuth rights={[RIGHTS.VIEW_BRIEFING]}>
            <Briefings />
          </RequireAuth>
        }
      />

      <Route path="reports" element={<Reports />} />

      <Route
        path="export_center/*"
        element={
          <RequireAuth rights={[RIGHTS.MANAGE_EXPORTS]}>
            <ExportCenter />
          </RequireAuth>
        }
      />

      <Route
        path="prescriptions/*"
        element={
          <RequireAuth
            rights={[
              RIGHTS.MANAGE_MEDICATION_PRESCRIPTION,
              RIGHTS.VALIDATE_MEDICATION,
              RIGHTS.PRESCRIBE_HOME_MEDICATION_PRESCRIPTION,
            ]}
          >
            <Prescriptions />
          </RequireAuth>
        }
      />

      <Route
        path="settings/*"
        element={
          <RequireAuth
            rights={[
              RIGHTS.MANAGE_APPLICATION_DATA,
              RIGHTS.EDIT_ADMINISTRATIVE_RECORD,
              RIGHTS.MANAGE_SYSTEM,
            ]}
          >
            <Settings />
          </RequireAuth>
        }
      />

      <Route path="profile" element={<Profile />} />
      <Route path="*" element={<Navigate to="/not_found" />} />
    </Routes>
  );
}

function Links() {
  const { formatMessage } = useIntl();

  const cx = ({ isActive }) =>
    'sidebar__item' + (isActive ? ' -is-active' : '');

  return (
    <>
      <li role="presentation">
        <NavLink
          end
          to="/record"
          title={formatMessage(t.dashboard)}
          className={cx}
          data-testid="sidebar__item-dashboard"
        >
          <Icon>dashboard</Icon>
        </NavLink>
      </li>

      <li role="presentation">
        <NavLink
          to="/record/recent_sessions"
          title={formatMessage(t.recentSessions)}
          className={cx}
          data-testid="sidebar__item-shedule"
        >
          <Icon>schedule</Icon>
        </NavLink>
      </li>

      <li role="presentation">
        <NavLink
          to="/record/patients"
          title={formatMessage(t.patients)}
          className={cx}
          data-testid="sidebar__item-patients"
        >
          <Icon>people</Icon>
        </NavLink>
      </li>

      <FeatureFlag rights={[RIGHTS.VIEW_PLANNING]}>
        <li role="presentation">
          <NavLink
            to="/record/planning"
            title={formatMessage(t.planning)}
            className={cx}
            data-testid="sidebar__item-planning"
          >
            <Icon>event</Icon>
          </NavLink>
        </li>
      </FeatureFlag>

      <FeatureFlag rights={[RIGHTS.VIEW_BRIEFING]}>
        <li role="presentation">
          <NavLink
            to="/record/briefings"
            title={formatMessage(t.briefings)}
            className={cx}
            data-testid="sidebar__item-briefing"
          >
            <Icon>question_answer</Icon>
          </NavLink>
        </li>
      </FeatureFlag>

      <li role="presentation">
        <NavLink
          to="/record/reports"
          title={formatMessage(t.reports)}
          className={cx}
          data-testid="sidebar__item-reports"
        >
          <Icon>description</Icon>
        </NavLink>
      </li>

      <FeatureFlag rights={[RIGHTS.MANAGE_EXPORTS]}>
        <li role="presentation">
          <NavLink
            to="/record/export_center"
            title={formatMessage(t.exportCenter)}
            className={cx}
            data-testid="sidebar__item-exports"
          >
            <Icon>save_alt</Icon>
          </NavLink>
        </li>
      </FeatureFlag>

      <FeatureFlag
        rights={[
          RIGHTS.MANAGE_MEDICATION_PRESCRIPTION,
          RIGHTS.PRESCRIBE_HOME_MEDICATION_PRESCRIPTION,
          RIGHTS.VALIDATE_MEDICATION,
        ]}
      >
        <li role="presentation">
          <NavLink
            to="/record/prescriptions"
            title={formatMessage(t.prescriptions)}
            className={cx}
            data-testid="sidebar__item-prescriptions"
          >
            <Icon>local_pharmacy</Icon>
          </NavLink>
        </li>
      </FeatureFlag>

      <FeatureFlag
        rights={[
          RIGHTS.MANAGE_APPLICATION_DATA,
          RIGHTS.EDIT_ADMINISTRATIVE_RECORD,
          RIGHTS.MANAGE_SYSTEM,
        ]}
      >
        <li role="presentation">
          <NavLink
            to="/record/settings"
            title={formatMessage(t.settings)}
            className={cx}
            data-testid="sidebar__item-settings"
          >
            <Icon>settings</Icon>
          </NavLink>
        </li>
      </FeatureFlag>
    </>
  );
}

const recordPerspective: Perspective = {
  name: 'record',
  path: 'record',
  title: (intl: IntlShape) => intl.formatMessage(t.record),

  rights: undefined,
  searchEnabled: true,

  Icon: () => <Icon>app:record</Icon>,
  Routes: MyRoutes,
  Links,
};

export default recordPerspective;
