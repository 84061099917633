import { startActivity } from 'store/modules/activity';

import { OrderT } from './fetchers';

export const ADD_ORDER = 'ADD_ORDER';
export const EDIT_ORDER = 'EDIT_ORDER';
export const REMOVE_ORDER = 'REMOVE_ORDER';
export const STOP_ORDER = 'STOP_ORDER';
export const ARCHIVE_ORDER = 'ARCHIVE_ORDER';

export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export const addOrderActivity = () => startActivity(ADD_ORDER);

export const editOrderActivity = (order: OrderT) =>
  startActivity(EDIT_ORDER, { order });

export const stopOrderActivity = (order: OrderT) =>
  startActivity(STOP_ORDER, { order });

export const removeOrderActivity = (order: OrderT) =>
  startActivity(REMOVE_ORDER, { order });

export const archiveOrderActivity = (order: OrderT) =>
  startActivity(ARCHIVE_ORDER, { order });
