import { fromJS } from 'immutable';

import { GET_DEACTIVATIONS } from 'store/modules/entities/actions/deactivations';

import sortInline from 'utils/sort';

const BY_ID = ['deactivations', 'byId'];
const BY_PATIENT_ID = ['deactivations', 'byPatientId'];

export default function deactivationsReducer(state: any, action: any) {
  switch (action.type) {
    case GET_DEACTIVATIONS.SUCCESS: {
      const {
        patientId,
        response: { entities, result },
      } = action.payload;

      return state
        .mergeIn(BY_ID, fromJS(entities.deactivations || {}))
        .setIn([...BY_PATIENT_ID, patientId], fromJS(result || []));
    }

    default: {
      return state;
    }
  }
}

export const patientDeactivationsSelector = (
  state: any,
  patientId: string
): PatientDeactivationT[] | undefined => {
  const {
    deactivations: { byId, byPatientId },
  } = state.entities.toJS();
  const patientDeactivations = byPatientId[patientId];

  if (patientDeactivations === undefined) return patientDeactivations;

  return patientDeactivations
    .map((id) => byId[id])
    .sort(sortInline('createdAt', 'desc'));
};
