import { startActivity } from 'store/modules/activity';

import { ContactT } from './fetchers';

export const ADD_PATIENT_CONTACT = 'ADD_PATIENT_CONTACT';
export const EDIT_PATIENT_CONTACT = 'EDIT_PATIENT_CONTACT';
export const REMOVE_PATIENT_CONTACT = 'REMOVE_PATIENT_CONTACT';

export const EDIT_IN_CENTER_CARE_TEAM = 'EDIT_IN_CENTER_CARE_TEAM';
export const EDIT_GENERAL_PRACTITIONER_CARE_TEAM =
  'EDIT_GENERAL_PRACTITIONER_CARE_TEAM';

export const CREATE_PATIENT_CONTACT_FAILURE = 'CREATE_PATIENT_CONTACT_FAILURE';
export const UPDATE_PATIENT_CONTACT_FAILURE = 'UPDATE_PATIENT_CONTACT_FAILURE';
export const DELETE_PATIENT_CONTACT_FAILURE = 'DELETE_PATIENT_CONTACT_FAILURE';

export const UPDATE_CARE_TEAM_FAILURE = 'UPDATE_CARE_TEAM_FAILURE';

export const startAddContactActivity = (patientId: string) =>
  startActivity(ADD_PATIENT_CONTACT, { patientId });

export const startEditContactActivity = (contact: ContactT) =>
  startActivity(EDIT_PATIENT_CONTACT, { contact });

export const startRemovePatientContactActivity = (contact: ContactT) =>
  startActivity(REMOVE_PATIENT_CONTACT, { contact });

export const editInCenterCareTeamActivity = () =>
  startActivity(EDIT_IN_CENTER_CARE_TEAM);

export const editGeneralPractitionerCareTeamActivity = () =>
  startActivity(EDIT_GENERAL_PRACTITIONER_CARE_TEAM);
