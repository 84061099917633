import {
  GET_WOUND_PRESCRIPTION,
  UPDATE_PRESCRIPTION_FOR_WOUND,
} from 'store/modules/entities/actions/woundcare/prescription';

export default function woundCarePrescriptionReducer(state: any, action: any) {
  switch (action.type) {
    case GET_WOUND_PRESCRIPTION.SUCCESS: {
      const {
        payload: {
          response: {
            entities: {
              forms: { form },
              prescriptions: { prescription },
            },
          },
        },
      } = action;

      const { woundId } = prescription;

      return state.mergeIn(['woundPrescriptions'], { [woundId]: form });
    }

    case UPDATE_PRESCRIPTION_FOR_WOUND.SUCCESS: {
      const {
        response: {
          entities: {
            forms: { form },
            prescriptions: { prescription },
          },
        },
      } = action.payload;

      const { woundId } = prescription;

      return state.mergeIn(['woundPrescriptions', woundId], form);
    }

    default: {
      return state;
    }
  }
}

export const woundPrescriptionSelector = (state, woundId) => {
  const { woundPrescriptions } = state.entities.toJS();

  const prescription =
    woundPrescriptions && woundPrescriptions[woundId]
      ? woundPrescriptions[woundId]
      : undefined;

  return prescription && prescription.sections.length
    ? prescription
    : undefined;
};
