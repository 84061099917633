import { fromJS } from 'immutable';

import {
  GET_LOGBOOK_CATEGORIES,
  GET_LOGBOOK_ENTRIES,
  PROMOTE_LOGBOOK_ENTRY,
} from 'store/modules/entities/actions/logbook';

import deindex from 'utils/deindex';

const LOGBOOK_ENTRIES_BY_ID = ['logbookEntries', 'byId'];
const LOGBOOK_ENTRIES_BY_SESSION_ID = ['logbookEntries', 'bySessionId'];
const LOGBOOK_CATEGORIES = ['logbookCategories'];

export default function logbookEntriesReducer(state: any, action: any) {
  switch (action.type) {
    case GET_LOGBOOK_ENTRIES.SUCCESS: {
      const {
        request: { sessionId },
        response: {
          entities: { logbookEntries },
          result: { data },
        },
      } = action.payload;

      const currentLogbookEntryIds =
        state.getIn([...LOGBOOK_ENTRIES_BY_SESSION_ID, sessionId]) || [];

      const newLogbookEntryIds = [...currentLogbookEntryIds, ...data].filter(
        (id, index, self) => self.indexOf(id) === index
      );

      return state
        .mergeDeepIn(LOGBOOK_ENTRIES_BY_ID, fromJS(logbookEntries || {}))
        .setIn(
          [...LOGBOOK_ENTRIES_BY_SESSION_ID, sessionId],
          fromJS(newLogbookEntryIds)
        );
    }

    case GET_LOGBOOK_CATEGORIES.SUCCESS: {
      const {
        response: {
          entities: { logbookCategories },
        },
      } = action.payload;

      return state.mergeDeepIn(
        LOGBOOK_CATEGORIES,
        fromJS(logbookCategories || {})
      );
    }

    case PROMOTE_LOGBOOK_ENTRY.SUCCESS: {
      return state;
    }

    default: {
      return state;
    }
  }
}

export const logbookEntriesSelector = (
  state: any,
  sessionId: string
): LogbookEntryT[] | undefined => {
  const {
    logbookEntries: { byId, bySessionId },
    tags,
  } = state.entities.toJS();

  const logbookEntryIds = bySessionId[sessionId];

  if (!logbookEntryIds) return;

  return logbookEntryIds
    .filter((id: string) => byId[id])
    .map((id: string) => {
      const logbookEntry = byId[id];

      return {
        ...logbookEntry,
        tags: logbookEntry.tags.map((tagId) => tags?.[tagId]).filter(Boolean),
      };
    });
};

export const categoriesSelector = (
  state: any
): LogbookCategoryT[] | null | undefined => {
  const { logbookCategories } = state.entities.toJS();

  if (!logbookCategories) return undefined;

  return deindex(logbookCategories);
};

export const categoryOptionsSelector = (state: any): OptionT[] => {
  const categories = categoriesSelector(state);

  if (!categories) return [];

  return categories.sort((a, b) => a.label.localeCompare(b.label));
};
