import { startActivity } from 'store/modules/activity';

export const ADD_REMARK = 'ADD_REMARK';
export const EDIT_REMARK = 'EDIT_REMARK';
export const REMOVE_REMARK = 'REMOVE_REMARK';

export const CREATE_REMARK_FAILURE = 'CREATE_REMARK_FAILURE';
export const UPDATE_REMARK_FAILURE = 'UPDATE_REMARK_FAILURE';

export const addRemarkActivity = () => startActivity(ADD_REMARK);

export const editRemarkActivity = (remark: RemarkT) =>
  startActivity(EDIT_REMARK, { remark });

export const removeRemarkActivity = (remark: RemarkT) =>
  startActivity(REMOVE_REMARK, { remark });
