import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const deactivationSchema = new schema.Entity(
  'deactivations',
  {},
  {
    processStrategy: schemaDeserializers({
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

export const GET_DEACTIVATIONS = networkActionTypes('GET_DEACTIVATIONS');

export const getDeactivations = (patientId: string): NetworkActionT => ({
  type: 'CALL_API',
  payload: {
    types: GET_DEACTIVATIONS,
    url: `patients/${patientId}/unenrollments`,
    method: 'GET',
    actionPayload: { patientId },
    normalizeSchema: [deactivationSchema],
  },
});
