import { RoleT } from 'api/fetchers/types';

import { startActivity } from 'store/modules/activity';

export const ADD_ROLE = 'ADD_ROLE';
export const EDIT_ROLE = 'EDIT_ROLE';
export const REMOVE_ROLE = 'REMOVE_ROLE';

export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

export const startAddRoleActivity = () => startActivity(ADD_ROLE);

export const startEditRoleActivity = (role: RoleT) =>
  startActivity(EDIT_ROLE, { role });

export const startRemoveRoleActivity = (role: RoleT) =>
  startActivity(REMOVE_ROLE, { role });
