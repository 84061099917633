import { IdentifierTypeAssignmentT } from 'api/fetchers/types';

import { startActivity } from 'store/modules/activity';

import { IdentifierTypeT } from './fetchers';

export const ADD_IDENTIFIER_TYPE = 'ADD_IDENTIFIER_TYPE';
export const EDIT_IDENTIFIER_TYPE = 'EDIT_IDENTIFIER_TYPE';

export const CREATE_IDENTIFIER_TYPE_FAILURE = 'CREATE_IDENTIFIER_TYPE_FAILURE';
export const UPDATE_IDENTIFIER_TYPE_FAILURE = 'UPDATE_IDENTIFIER_TYPE_FAILURE';
export const DELETE_IDENTIFIER_TYPE_FAILURE = 'DELETE_IDENTIFIER_TYPE_FAILURE';

export const startAddIdentifierTypeActivity = () =>
  startActivity(ADD_IDENTIFIER_TYPE);

export const startEditIdentifierTypeActivity = (
  identifierType: IdentifierTypeT
) =>
  startActivity(EDIT_IDENTIFIER_TYPE, {
    identifierType,
  });

export const ADD_IDENTIFIER_TYPE_ASSIGNMENT = 'ADD_IDENTIFIER_TYPE_ASSIGNMENT';
export const EDIT_IDENTIFIER_TYPE_ASSIGNMENT =
  'EDIT_IDENTIFIER_TYPE_ASSIGNMENT';
export const REMOVE_IDENTIFIER_TYPE_ASSIGNMENT =
  'REMOVE_IDENTIFIER_TYPE_ASSIGNMENT';

export const CREATE_IDENTIFIER_TYPE_ASSIGNMENT_FAILURE =
  'CREATE_IDENTIFIER_TYPE_ASSIGNMENT_FAILURE';
export const UPDATE_IDENTIFIER_TYPE_ASSIGNMENT_FAILURE =
  'UPDATE_IDENTIFIER_TYPE_ASSIGNMENT_FAILURE';
export const DELETE_IDENTIFIER_TYPE_ASSIGNMENT_FAILURE =
  'DELETE_IDENTIFIER_TYPE_ASSIGNMENT_FAILURE';

export const startAddIdentifierTypeAssignmentActivity = (
  identifierTypeOptions: OptionT[]
) =>
  startActivity(ADD_IDENTIFIER_TYPE_ASSIGNMENT, {
    identifierTypeOptions,
  });

export const startEditIdentifierTypeAssignmentActivity = (
  assignment: IdentifierTypeAssignmentT
) => startActivity(EDIT_IDENTIFIER_TYPE_ASSIGNMENT, { assignment });

export const startRemoveIdentifierTypeAssignmentActivity = (
  identifierTypeAssignment: IdentifierTypeAssignmentT
) =>
  startActivity(REMOVE_IDENTIFIER_TYPE_ASSIGNMENT, {
    identifierTypeAssignment,
  });
