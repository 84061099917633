import { UserToken } from './types';

export const localStorageKey = '__user_token__';

export function retrieveToken(): UserToken {
  return JSON.parse(localStorage.getItem(localStorageKey) || '{}');
}

export function persistToken(token: UserToken, merge = false) {
  const data = merge ? { ...retrieveToken(), ...token } : token;
  localStorage.setItem(localStorageKey, JSON.stringify(data));
}

export function forgetToken() {
  localStorage.removeItem(localStorageKey);
}
