import { startActivity } from 'store/modules/activity';

import { DeviceAttachment } from './fetchers';

export const ADD_DEVICE_ATTACHMENT = 'ADD_DEVICE_ATTACHMENT';
export const EDIT_DEVICE_ATTACHMENT = 'EDIT_DEVICE_ATTACHMENT';
export const REMOVE_DEVICE_ATTACHMENT = 'REMOVE_DEVICE_ATTACHMENT';

export const CREATE_DEVICE_ATTACHMENT_FAILURE =
  'CREATE_DEVICE_ATTACHMENT_FAILURE';
export const UPDATE_DEVICE_ATTACHMENT_FAILURE =
  'UPDATE_DEVICE_ATTACHMENT_FAILURE';

export const addDeviceAttachmentActivity = () =>
  startActivity(ADD_DEVICE_ATTACHMENT);

export const editDeviceAttachmentActivity = (attachment: DeviceAttachment) =>
  startActivity(EDIT_DEVICE_ATTACHMENT, { attachment });

export const removeDeviceAttachmentActivity = (
  deviceAttachment: DeviceAttachment
) => startActivity(REMOVE_DEVICE_ATTACHMENT, { deviceAttachment });
