import { fromJS } from 'immutable';

export default function updatePatientResidenceType(
  state,
  { payload: { response } }
) {
  const patientId = response.result;
  const patient = response.entities.patients[patientId];

  return state.mergeIn(['patients', patientId], fromJS(patient));
}
