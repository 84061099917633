import { Iterable } from 'immutable';

function sortByKeys(object: Record<string, any>) {
  return Object.keys(object)
    .sort()
    .reduce((acc, key) => ({ ...acc, [key]: object[key] }), {});
}

/**
 * Transform immutable objects to plain JS and sort their top level keys.
 */

export function stateSerializer(state: Record<string, any>) {
  return Object.entries(state).reduce(
    (transformed, [key, value]) => ({
      ...transformed,
      [key]: Iterable.isIterable(value) ? sortByKeys(value.toJS()) : value,
    }),
    {}
  );
}

type AnyReducer = (state: any, action: any) => any;

/**
 * This combines default (using switch) reducers into one reducer.
 * The difference with redux' `combineReducers` is that they will prefix the reducers.
 * This is used for our top level state: `{ auth: {}, network: {}, entities: {} }` but
 * especially in the entities reducer we want to use smaller reducers and combine them into
 * one reducer without the added namespaces.
 */

export function combineReducers(...reducers: AnyReducer[]): AnyReducer {
  return function reducer(state: any, action: any): any {
    return reducers.reduce(
      (nextState, reducer) => reducer(nextState, action),
      state
    );
  };
}

export function networkActionTypes(type: string) {
  return {
    REQUEST: `${type}_REQUEST`,
    SUCCESS: `${type}_SUCCESS`,
    FAILURE: `${type}_FAILURE`,
    type,
  };
}
