import { startActivity } from 'store/modules/activity';

import { BillingTreatmentSetting } from 'views/record/settings/billing/fetchers';

import { BillingCenterOptions } from './fetchers';

export const EDIT_BILLING_OPTIONS = 'EDIT_BILLING_OPTIONS';

export const UPDATE_BILLING_OPTIONS_FAILURE = 'UPDATE_BILLING_OPTIONS_FAILURE';

export const editBillingOptionsActivity = ({
  options,
  treatmentSettings,
}: {
  options: BillingCenterOptions | undefined;
  treatmentSettings: BillingTreatmentSetting[] | undefined;
}) => startActivity(EDIT_BILLING_OPTIONS, { options, treatmentSettings });
